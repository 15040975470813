import { FunctionComponent, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { contextNotificationAtom } from "../../state/atoms/notification";
import { AdminSection, DashboardNavbarTypes } from "../../types";
import DynamicBubblesContainer from "../DynamicBubblesContainer";
import Notification from "../Notification";
import AdminUsersPanel from "./AdminUsersPanel";
import CoursesLeads from "./CoursesLeads";
import DashboardNavbar from "./DashboardNavbar";
import JoinUsLeads from "./JoinUsLeads";
import TeacherChats from "./TeacherChats";

const AdminDashboard: FunctionComponent<{
  adminSection?: AdminSection;
}> = ({ adminSection }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const notification = useRecoilValue(contextNotificationAtom);
  useEffect(() => {
    if (pathname === "/admin-panel") navigate("/admin-panel/users");
  }, [pathname, navigate]);

  const adminDashboardNavbarData = [
    {
      sectionName: adminSection?.dashboardNavbarSection?.users || "Users",
      img: (
        <svg
          width="37"
          height="37"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            style={{ fill: "currentcolor" }}
            d="M30.6987 27.2663C30.0447 25.717 29.0955 24.3098 27.9042 23.123C26.7164 21.9327 25.3094 20.9838 23.7609 20.3285C23.747 20.3215 23.7332 20.3181 23.7193 20.3111C25.8793 18.7509 27.2835 16.2095 27.2835 13.3421C27.2835 8.59209 23.435 4.74353 18.685 4.74353C13.9349 4.74353 10.0864 8.59209 10.0864 13.3421C10.0864 16.2095 11.4906 18.7509 13.6506 20.3146C13.6368 20.3215 13.6229 20.325 13.609 20.3319C12.0557 20.9872 10.6619 21.9268 9.46576 23.1265C8.27551 24.3142 7.32653 25.7212 6.67122 27.2697C6.02744 28.7858 5.68024 30.4111 5.6484 32.0579C5.64748 32.0949 5.65397 32.1317 5.66749 32.1662C5.68102 32.2006 5.7013 32.232 5.72715 32.2585C5.753 32.285 5.78389 32.3061 5.818 32.3204C5.85212 32.3348 5.88876 32.3422 5.92578 32.3422H8.00608C8.15863 32.3422 8.27998 32.2209 8.28345 32.0718C8.35279 29.3951 9.42762 26.8883 11.3276 24.9883C13.2935 23.0224 15.9043 21.9407 18.685 21.9407C21.4656 21.9407 24.0764 23.0224 26.0423 24.9883C27.9423 26.8883 29.0171 29.3951 29.0865 32.0718C29.0899 32.2243 29.2113 32.3422 29.3638 32.3422H31.4441C31.4812 32.3422 31.5178 32.3348 31.5519 32.3204C31.586 32.3061 31.6169 32.285 31.6428 32.2585C31.6686 32.232 31.6889 32.2006 31.7024 32.1662C31.716 32.1317 31.7224 32.0949 31.7215 32.0579C31.6868 30.4006 31.3436 28.7884 30.6987 27.2663ZM18.685 19.3056C17.0935 19.3056 15.5957 18.685 14.4689 17.5582C13.3421 16.4314 12.7214 14.9335 12.7214 13.3421C12.7214 11.7507 13.3421 10.2529 14.4689 9.12603C15.5957 7.9992 17.0935 7.37858 18.685 7.37858C20.2764 7.37858 21.7742 7.9992 22.901 9.12603C24.0279 10.2529 24.6485 11.7507 24.6485 13.3421C24.6485 14.9335 24.0279 16.4314 22.901 17.5582C21.7742 18.685 20.2764 19.3056 18.685 19.3056Z"
            fill="white"
          />
        </svg>
      ),
      path: "/admin-panel/users",
    },
    {
      sectionName: adminSection?.dashboardNavbarSection?.chats || "Chats",
      img: (
        <svg
          width="43"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            style={{ stroke: "currentcolor" }}
            d="M20.8732 28.2296C26.4518 28.2296 29.2418 28.2296 30.974 26.4959C32.7078 24.7636 32.7078 21.9736 32.7078 16.395C32.7078 10.8165 32.7078 8.02648 30.974 6.29419C29.2418 4.56042 26.4518 4.56042 20.8732 4.56042H14.9559C9.37738 4.56042 6.58737 4.56042 4.85508 6.29419C3.12131 8.02648 3.12131 10.8165 3.12131 16.395C3.12131 21.9736 3.12131 24.7636 4.85508 26.4959C5.82108 27.4633 7.11549 27.8909 9.03861 28.0787"
            stroke="#655C97"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            style={{ stroke: "currentcolor" }}
            d="M20.8732 28.2296C19.0448 28.2296 17.0299 28.9693 15.1911 29.9234C12.2354 31.4575 10.7576 32.2253 10.0298 31.7356C9.30193 31.2474 9.4395 29.7311 9.71614 26.7L9.77827 26.0106"
            stroke="#655C97"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      ),
      path: "/admin-panel/chats",
    },
    {
      sectionName:
        adminSection?.dashboardNavbarSection?.coursesLeads || "Courses leads",
      img: (
        <svg
          id="Layer_1"
          version="1.1"
          width="37"
          height="37"
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="7" cy="6" r="3" style={{ fill: "currentcolor" }} />
          <line
            style={{ fill: "currentcolor" }}
            x1="16"
            x2="9"
            y1="12"
            y2="12"
          />
          <path
            style={{ fill: "currentcolor" }}
            d="M10,25.967C10,26.537,9.537,27,8.967,27h0c-0.544,0-0.995-0.422-1.031-0.965l-0.438-6.57C7.48,19.204,7.263,19,7,19h0  c-0.263,0-0.48,0.204-0.498,0.466l-0.438,6.57C6.028,26.578,5.577,27,5.033,27h0C4.463,27,4,26.537,4,25.967V13c0-1.105,0.895-2,2-2  h6v2h-1c-0.552,0-1,0.448-1,1V25.967z"
          />
          <polyline
            style={{ fill: "currentcolor" }}
            points="12,18 25,18 25,6   12,6 "
          />
          <line
            style={{ fill: "currentcolor" }}
            x1="17"
            x2="17"
            y1="6"
            y2="5"
          />
          <line
            style={{ fill: "currentcolor" }}
            x1="13"
            x2="15"
            y1="26"
            y2="18"
          />
          <line
            style={{ fill: "currentcolor" }}
            x1="21"
            x2="19"
            y1="26"
            y2="18"
          />
        </svg>
      ),
      path: "/admin-panel/courses-leads",
    },
    {
      sectionName:
        adminSection?.dashboardNavbarSection?.joinUsLeads || "Join us leads",
      img: (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="37px"
          height="37px"
          viewBox="0,0,256,256"
        >
          <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1">
            <g transform="scale(2,2)">
              <path
                d="M87.961,62.312c6.067,-5.798 9.859,-13.958 9.859,-22.993c-0.001,-17.545 -14.275,-31.819 -31.82,-31.819c-17.545,0 -31.819,14.274 -31.819,31.819c0,9.034 3.791,17.195 9.859,22.993c-12.644,1.753 -22.412,12.627 -22.412,25.747v33.441c0,1.654 1.346,3 3,3h82.746c1.654,0 3,-1.346 3,-3v-33.441c-0.001,-13.12 -9.769,-23.994 -22.413,-25.747z"
                fill="#ffffff"
              ></path>
              <path
                d="M81.618,59.09c-5.33,4.403 -12.165,7.049 -19.618,7.049c-7.453,0 -14.288,-2.646 -19.618,-7.049c-13.229,0.649 -23.755,11.579 -23.755,24.969v33.441c0,1.104 0.895,2 2,2h82.745c1.105,0 2,-0.896 2,-2v-33.442c0,-13.389 -10.526,-24.319 -23.754,-24.968z"
                fill="#ffffff"
              ></path>
              <path
                d="M103.373,116.5h-82.746c-1.105,0 -2,-0.896 -2,-2v3c0,1.104 0.895,2 2,2h82.745c1.105,0 2,-0.896 2,-2v-3c0.001,1.104 -0.895,2 -1.999,2z"
                fill="#ffffff"
              ></path>
              <path
                d="M62,65.94v0v0l-5.313,4.241l2.433,6.96h5.76l2.434,-6.959z"
                fill="#ffffff"
              ></path>
              <path
                d="M62,114.756l5.738,-4.37l-2.858,-33.245h-5.76l-2.858,33.245z"
                fill="#ffffff"
              ></path>
              <path
                d="M62.242,66.133l12.125,9.678l3.333,-13.972c-4.534,2.69 -9.815,4.25 -15.458,4.294z"
                fill="#ffffff"
              ></path>
              <path
                d="M46.301,61.839l3.333,13.972l12.125,-9.678c-5.644,-0.044 -10.925,-1.604 -15.458,-4.294z"
                fill="#ffffff"
              ></path>
              <g>
                <circle cx="62" cy="35.319" r="30.819" fill="#ffffff"></circle>
                <circle cx="62" cy="35.319" r="23.535" fill="#ffffff"></circle>
                <path
                  d="M83.96,58.313c6.068,-5.798 9.859,-13.959 9.859,-22.993c0,-17.546 -14.274,-31.82 -31.819,-31.82c-17.545,0 -31.819,14.274 -31.819,31.819c0,9.035 3.791,17.196 9.859,22.993c-12.645,1.753 -22.413,12.626 -22.413,25.746v33.442c0,1.654 1.346,3 3,3h82.745c1.654,0 3,-1.346 3,-3v-11.375c0,-0.553 -0.447,-1 -1,-1c-0.553,0 -1,0.447 -1,1v11.375c0,0.552 -0.448,1 -1,1h-17.372v-27.75c0,-0.553 -0.447,-1 -1,-1c-0.553,0 -1,0.447 -1,1v27.75h-44v-27.75c0,-0.553 -0.447,-1 -1,-1c-0.553,0 -1,0.447 -1,1v27.75h-17.373c-0.552,0 -1,-0.448 -1,-1v-33.441c0,-12.716 9.943,-23.149 22.462,-23.945c1.059,0.852 2.17,1.641 3.333,2.354l3.239,13.577c0.079,0.333 0.323,0.602 0.647,0.713c0.106,0.037 0.216,0.055 0.325,0.055c0.224,0 0.444,-0.075 0.624,-0.219l5.95,-4.75l1.898,5.427l-2.84,33.03c-0.029,0.342 0.118,0.674 0.391,0.882l5.738,4.37c0.179,0.136 0.392,0.204 0.605,0.204c0.213,0 0.427,-0.068 0.605,-0.204l5.738,-4.37c0.272,-0.208 0.42,-0.54 0.391,-0.882l-2.84,-33.03l1.898,-5.427l5.95,4.75c0.18,0.144 0.4,0.219 0.624,0.219c0.109,0 0.219,-0.018 0.325,-0.055c0.324,-0.111 0.568,-0.38 0.647,-0.713l3.239,-13.577c1.163,-0.713 2.274,-1.502 3.333,-2.354c12.519,0.797 22.461,11.229 22.461,23.945v6.691c0,0.553 0.447,1 1,1c0.553,0 1,-0.447 1,-1v-6.691c0.002,-13.12 -9.766,-23.993 -22.41,-25.746zM32.181,35.319c0,-16.442 13.377,-29.819 29.819,-29.819c16.442,0 29.819,13.377 29.819,29.819c0,16.442 -13.377,29.82 -29.819,29.82c-16.442,0 -29.819,-13.377 -29.819,-29.82zM50.241,74.047l-2.45,-10.269c3.452,1.731 7.258,2.852 11.281,3.221zM62,113.499l-4.695,-3.575l2.733,-31.783h3.924l2.733,31.783zM64.17,76.141h-4.34l-1.966,-5.62l4.136,-3.301l4.136,3.301zM73.759,74.047l-8.831,-7.048c4.023,-0.369 7.829,-1.49 11.281,-3.221z"
                  fill="#3a2c60"
                ></path>
                <path
                  d="M105.372,93.25c-0.553,0 -1,0.447 -1,1v8.5c0,0.553 0.447,1 1,1c0.553,0 1,-0.447 1,-1v-8.5c0,-0.553 -0.447,-1 -1,-1z"
                  fill="#3a2c60"
                ></path>
                <path
                  d="M65.608,44.208c-0.869,0.612 -2.12,0.917 -3.751,0.917c-1.222,0 -2.4,-0.17 -3.526,-0.51c-1.128,-0.34 -2.317,-0.876 -3.567,-1.61c-0.762,-0.435 -1.4,-0.653 -1.916,-0.653c-0.544,0 -1.026,0.259 -1.448,0.775c-0.421,0.516 -0.632,1.115 -0.632,1.794c0,0.516 0.089,0.944 0.265,1.283c0.177,0.341 0.482,0.646 0.917,0.917c1.06,0.762 2.289,1.387 3.69,1.877c1.4,0.488 2.847,0.8 4.342,0.937v2.202c0,0.734 0.231,1.324 0.693,1.773c0.462,0.448 1.046,0.673 1.753,0.673c0.734,0 1.326,-0.224 1.774,-0.673c0.447,-0.449 0.672,-1.039 0.672,-1.773v-2.324c2.555,-0.434 4.587,-1.407 6.096,-2.916c1.509,-1.508 2.263,-3.363 2.263,-5.565c0,-1.848 -0.455,-3.323 -1.366,-4.424c-0.912,-1.1 -1.997,-1.902 -3.262,-2.405c-1.263,-0.503 -2.888,-0.999 -4.872,-1.489c-1.413,-0.353 -2.514,-0.665 -3.302,-0.937c-0.788,-0.272 -1.441,-0.646 -1.958,-1.121c-0.517,-0.475 -0.774,-1.094 -0.774,-1.855c0,-1.087 0.441,-1.956 1.325,-2.609c0.883,-0.652 2.058,-0.978 3.526,-0.978c1.278,0 2.358,0.177 3.242,0.53c0.883,0.354 1.882,0.884 2.997,1.59c0.462,0.272 0.822,0.449 1.08,0.531c0.257,0.081 0.536,0.122 0.835,0.122c0.544,0 1.026,-0.259 1.448,-0.775c0.421,-0.516 0.632,-1.114 0.632,-1.794c0,-0.488 -0.089,-0.91 -0.265,-1.263c-0.176,-0.353 -0.469,-0.666 -0.876,-0.937c-2.147,-1.522 -4.445,-2.447 -6.891,-2.772v-2.243c0,-0.734 -0.224,-1.324 -0.673,-1.773c-0.449,-0.449 -1.039,-0.673 -1.773,-0.673c-0.707,0 -1.291,0.224 -1.753,0.673c-0.462,0.449 -0.693,1.039 -0.693,1.773v2.324c-2.528,0.435 -4.573,1.441 -6.137,3.017c-1.562,1.577 -2.344,3.465 -2.344,5.667c0,1.875 0.469,3.371 1.407,4.486c0.937,1.115 2.058,1.93 3.363,2.446c1.305,0.516 2.962,1.033 4.974,1.549c1.387,0.381 2.446,0.693 3.18,0.938c0.734,0.244 1.352,0.584 1.855,1.019c0.503,0.435 0.754,0.993 0.754,1.671c0.001,1.113 -0.433,1.976 -1.304,2.588z"
                  fill="#3a2c60"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      ),
      path: "/admin-panel/join-us-leads",
    },
  ];

  if (!adminSection) return <></>;

  return (
    <DynamicBubblesContainer>
      <div className="mt-50">
        <DashboardNavbar
          dashboardData={adminDashboardNavbarData}
          navbarType={DashboardNavbarTypes.adminDashboard}
        />
        <div className="p-4 rounded shadow-lg mb-3">
          <section className="admin-dashboard-container">
            <Routes>
              <Route
                path="/users"
                element={<AdminUsersPanel adminSection={adminSection} />}
              />
              <Route
                path="/chats"
                element={<TeacherChats adminSection={adminSection} />}
              />
              <Route
                path="/chats/:teacherId"
                element={<TeacherChats adminSection={adminSection} />}
              />
              <Route
                path="/chats/:teacherId/:studentId"
                element={<TeacherChats adminSection={adminSection} />}
              />
              <Route
                path="/courses-leads"
                element={<CoursesLeads adminSection={adminSection} />}
              />
              <Route
                path="/join-us-leads"
                element={<JoinUsLeads adminSection={adminSection} />}
              />
            </Routes>
            <Notification {...notification} />
          </section>
        </div>
      </div>
    </DynamicBubblesContainer>
  );
};

export default AdminDashboard;
