import { FunctionComponent } from "react";
import { InfoMessagePayload } from "../../../../types";
import { downloadFile } from "../../../../utils";
import { useWindowDimensions } from "../../../../utils/hooks";
import { DateAndTime } from "./DynamicMsgCmp";

const Info: FunctionComponent<{
  payload: InfoMessagePayload;
  messageTime: DateAndTime;
}> = ({ payload, messageTime }) => {
  const { isMobile } = useWindowDimensions();
  return (
    <div
      className={`d-flex flex-column gap-5 py-3 px-2 rounded m-auto bg-yellow ${
        isMobile ? "w-75" : "w-50"
      }`}
    >
      <div className="d-flex p0 gap-5 align-items-end gap-25 justify-content-between flex-wrap">
        <div className="d-flex align-items-center gap-5">
          <p className="p0 align-self-start">🔔</p>
          <pre
            className={`p0 font-md lh-1 text-start font-roboto overflow-hidden pre-wrap`}
          >
            {payload.text}
          </pre>
        </div>
        <span
          className="text-end font-sm lh-1"
          title={messageTime.date.toLocaleString()}
        >
          {messageTime.time}
        </span>
      </div>
      {payload.attached &&
        payload.attached.map(({ fileLink, fileName }) => {
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              rel="noopener noreferrer"
              className="attached-container d-flex gap-5 align-items-center justify-content-end cursor-pointer"
              onClick={() => {
                downloadFile(fileLink, fileName);
              }}
            >
              <img
                src="images/icon/attachIcon.svg"
                alt=""
                style={{ height: "10px" }}
              />
              <span className="font-sm lh-1">{fileName}</span>
            </a>
          );
        })}
    </div>
  );
};

export default Info;
