import { FunctionComponent, useState } from "react";
import { PortableText } from "@portabletext/react";
import { urlForSanityImage } from "../services/sanity";
import { JoinUsSection } from "../types";
import Notification from "./Notification";
import { useLocation } from "react-router";
import { registerTeacherApplicantLead } from "../services/firebase";

export const JoinUs: FunctionComponent<{ joinUsSection: JoinUsSection }> = ({
  joinUsSection,
}) => {
  const [email, setEmail] = useState("");
  const [lastRegisteredEmail, setLastRegisteredEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { pathname } = useLocation();

  if (!joinUsSection?.topSection) return <></>;

  const onSaveApplicant = async (ev: React.FormEvent) => {
    ev.preventDefault();

    try {
      setIsSubmitting(true);
      await registerTeacherApplicantLead({ email, pathname });
      setLastRegisteredEmail(email);
      setIsSubmitted(true);
    } catch (e) {
      setIsErrored(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const {
    title,
    description,
    cta,
    topImage,
    emailPlaceholder,
    successMessage,
    failureMessage,
  } = joinUsSection.topSection;

  const isSaveTeacherDisabled = isSubmitting || lastRegisteredEmail === email;

  return (
    <div className="hero-banner-seven lg-container pb-200">
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column align-items-center">
            <h1 className="hero-heading p-0 mb-2 font-xlg">
              {title && <span>{title}</span>}
            </h1>
            {description && <PortableText value={description} />}
            <form className="mt-4 w-100" onSubmit={onSaveApplicant}>
              <input
                dir="ltr"
                type="email"
                name="email"
                disabled={isSubmitting}
                placeholder={emailPlaceholder}
                value={email}
                onChange={(ev) => {
                  setEmail(ev.target.value);
                  setIsSubmitted(false);
                }}
              />
              <button disabled={isSaveTeacherDisabled} className="p-0 px-4">
                {cta}
              </button>
              <div className="mt-2">
                <Notification
                  isVisible={isSubmitted || isErrored}
                  message={isSubmitted ? successMessage : failureMessage}
                  type={isSubmitted ? "info" : "error"}
                />
              </div>
            </form>
          </div>
          <div className="d-none d-lg-block col-6 illustration-container position-static">
            {topImage && (
              <img
                src={urlForSanityImage(topImage).url()}
                alt="student-animation"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
