import { FunctionComponent, useState, useEffect } from "react";
import { TestimonialsSection, ScreenBreakPoints } from "../types";
import { PortableText } from "@portabletext/react";
import { urlForSanityImage } from "../services/sanity";
import { useWindowDimensions } from "../utils/hooks";
import leftChevron from "../assets/images/left_chevron_icon.svg";
import rightChevron from "../assets/images/right-chevron-icon.svg";
import { isRtlAtom } from "../state/atoms/global";
import { useRecoilValue } from "recoil";

const Testimonials: FunctionComponent<{
  testimonialsSection: TestimonialsSection;
}> = ({ testimonialsSection }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const { windowWidth } = useWindowDimensions();
  const testimonials = testimonialsSection?.testimonials ?? [];
  const [transitionClass, setTransitionClass] = useState("");
  const isRtl = useRecoilValue(isRtlAtom);

  useEffect(() => {
    if (windowWidth < ScreenBreakPoints.SM) {
      setItemsPerPage(1);
    } else if (windowWidth < ScreenBreakPoints.LG) {
      setItemsPerPage(2);
    } else {
      setItemsPerPage(3);
    }
  }, [windowWidth]);

  const totalPages = Math.ceil(testimonials.length / itemsPerPage);
  const pageNumbers = Array.from(Array(totalPages).keys());

  const handlePageChange = (page: number) => {
    const isMovingForward = page > currentPage;
    let exitClass: string, enterClass: string;

    if (isRtl) {
      exitClass = isMovingForward ? "slide-exit" : "slide-exit-reverse";
      enterClass = isMovingForward ? "slide-enter" : "slide-enter-reverse";
    } else {
      exitClass = isMovingForward ? "slide-exit-reverse" : "slide-exit";
      enterClass = isMovingForward ? "slide-enter-reverse" : "slide-enter";
    }

    setTransitionClass(exitClass);
    setTimeout(() => {
      setCurrentPage(page);
      setTransitionClass(enterClass);
    }, 100);
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      handlePageChange(currentPage + 1);
    }
  };

  const currentTestimonials = testimonials.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div className="client-feedback-slider-two mt-180 md-mt-100">
      <div className="container">
        <div className="title-style-five text-center mb-80 md-mb-50">
          <div className="row">
            <div className="col-lg-7 col-md-9 m-auto">
              <h2>{testimonialsSection.title}</h2>
            </div>
          </div>
        </div>
        <div className="slider-content">
          <img
            src={leftChevron}
            alt=""
            className={`chevron chevron-left ${
              (isRtl ? currentPage === totalPages - 1 : currentPage === 0)
                ? "disabled"
                : ""
            }`}
            onClick={isRtl ? handleNextPage : handlePrevPage}
          />
          <div className={`testimonial-slider-wrapper ${transitionClass}`}>
            {currentTestimonials?.map(
              ({ headerImage, quote, name, description }, index) => (
                <div className="item" key={`testimonial-item-${index}`}>
                  <div className="bg-wrapper">
                    {headerImage && (
                      <img src={urlForSanityImage(headerImage).url()} alt="" />
                    )}
                    {quote && <PortableText value={quote} />}
                    <h6 className="name">{name}</h6>
                    {description && (
                      <div className="desig">
                        <PortableText value={description} />
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
          <img
            src={rightChevron}
            alt=""
            className={`chevron chevron-right ${
              (isRtl ? currentPage === 0 : currentPage === totalPages - 1)
                ? "disabled"
                : ""
            }`}
            onClick={isRtl ? handlePrevPage : handleNextPage}
          />
        </div>
        <ul className="slick-dots">
          {pageNumbers.map((page) => (
            <li
              key={page}
              className={currentPage === page ? "slick-active" : ""}
              onClick={() => handlePageChange(page)}
            >
              <button>{page + 1}</button>
            </li>
          ))}
        </ul>
      </div>
      <img
        src="/images/unschool/stickers/200x200/1.png"
        alt=""
        width="100px"
        className="shapes shape-one"
      />
      <img
        src="/images/unschool/stickers/200x200/3.png"
        alt=""
        width="80px"
        className="shapes shape-two"
      />
      <img
        src="/images/unschool/stickers/200x200/5.png"
        alt=""
        width="100px"
        className="shapes shape-three"
      />
      <img
        src="/images/unschool/stickers/200x200/2.png"
        alt=""
        width="100px"
        className="shapes shape-four"
      />
      <img
        src="/images/unschool/stickers/200x200/4.png"
        alt=""
        width="100px"
        className="shapes shape-five"
      />
      <img
        src="/images/unschool/stickers/200x200/6.png"
        alt=""
        width="100px"
        className="shapes shape-six"
      />
    </div>
  );
};

export default Testimonials;
