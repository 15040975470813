import { FunctionComponent, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const OuterRedirect: FunctionComponent<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const url = queryParams.get("url");

  useEffect(() => {
    if (url) {
      navigate("/", { replace: true });
      window.location.replace(url);
    }
  }, [url, navigate, queryParams]);

  return null;
};

export default OuterRedirect;
