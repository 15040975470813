import { atom } from "recoil";
import { UnreadMessages } from "../../types";

export const isRtlAtom = atom<boolean>({
  key: "isRtlAtom",
  default: false,
});

export const isSubmitingMessageAtom = atom<boolean>({
  key: "isSubmitingMessageAtom",
  default: false,
});

export const isUploadingFileAtom = atom<boolean>({
  key: "isUploadingFileAtom",
  default: false,
});

export const unreadMessagesAtom = atom<UnreadMessages[]>({
  key: "unreadMessagesAtom",
  default: [],
});
