import { FunctionComponent } from "react";
import { ContextNotification } from "../types";

const Notification: FunctionComponent<ContextNotification> = ({
  isVisible,
  type,
  message,
}) => {
  return isVisible ? (
    <div
      className={`alert alert-${
        type === "info" ? "primary" : "danger"
      } text-center`}
      role="alert"
    >
      {message}
    </div>
  ) : (
    <></>
  );
};

export default Notification;
