import { FunctionComponent } from "react";
import {
  ChatMessage,
  LessonsBalanceMessagePayload,
  MessageType,
  PrivateMessagePayload,
  HomeworkMessagePayload,
  InfoMessagePayload,
} from "../../../../types";
import Balance from "./Balance";
import HomeWork from "./HomeWork";
import Info from "./Info";
import PrivateMessage from "./PrivateMessage";

export interface DateAndTime {
  date: Date;
  time: string;
}

const DynamicMsgCmp: FunctionComponent<{
  message: ChatMessage;
  userId: string;
  newestMessageRef: React.Ref<HTMLDivElement> | null;
  isLastMessage: boolean;
  lessonsLeftMessage?: string;
  direction?: string;
}> = ({
  message,
  userId,
  newestMessageRef,
  isLastMessage,
  lessonsLeftMessage,
  direction,
}) => {
  const getMessageTime = (timestamp: number): DateAndTime => {
    const date = new Date(timestamp);
    const hour = (date.getHours() + "").padStart(2, "0");
    const minutes = (date.getMinutes() + "").padStart(2, "0");
    return { date, time: `${hour}:${minutes}` };
  };

  const getMessageToRender = () => {
    switch (message.type) {
      case MessageType.PrivateMessage:
        return (
          <PrivateMessage
            payload={message.info as PrivateMessagePayload}
            userId={userId}
            from={message.from}
            messageTime={getMessageTime(message.timestamp)}
          />
        );
      case MessageType.HomeWork:
        return (
          <HomeWork
            messageTime={getMessageTime(message.timestamp)}
            payload={message.info as HomeworkMessagePayload}
          />
        );
      case MessageType.Balance:
        return (
          <Balance
            messageTime={getMessageTime(message.timestamp)}
            payload={message.info as LessonsBalanceMessagePayload}
            lessonsLeftMessage={lessonsLeftMessage}
          />
        );
      case MessageType.info:
        return (
          <Info
            messageTime={getMessageTime(message.timestamp)}
            payload={message.info as InfoMessagePayload}
          />
        );
    }
  };

  return (
    <div
      className="message-container mt-2"
      ref={isLastMessage ? newestMessageRef : null}
      dir={direction}
    >
      {getMessageToRender()}
    </div>
  );
};

export default DynamicMsgCmp;
