import { FunctionComponent, useState } from "react";
import { AskUsWidgetContent } from "../../types";
import "./style.css";

const AskUsWidget: FunctionComponent<{ content: AskUsWidgetContent }> = ({
  content,
}) => {
  const { whatsAppCTA, whatsAppCTADescription } = content;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleOnIconClick = () => {
    setIsTooltipVisible(!isTooltipVisible);
  };

  return (
    <div className="whatsapp-container">
      <img
        src="/images/icon/ask-us/whatsapp-icon.svg"
        alt=""
        onClick={handleOnIconClick}
      />
      {isTooltipVisible && (
        <div className="whatsapp-tooltip">
          <span className="whatsapp-tooltip-arrow" />
          <div className="whatsapp-tooltip-content">
            <a
              className="whatsapp-button"
              href={whatsAppCTA}
              target="_blank"
              rel="noreferrer"
            >
              {whatsAppCTADescription}
              <span className="whatsapp-button-arrow">&#10148;</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default AskUsWidget;
