/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent } from "react";
import { PrivateMessagePayload } from "../../../../types";
import { downloadFile } from "../../../../utils";
import { DateAndTime } from "./DynamicMsgCmp";

const PrivateMessage: FunctionComponent<{
  payload: PrivateMessagePayload;
  from: string;
  userId: string;
  messageTime: DateAndTime;
}> = ({ payload, userId, messageTime, from }) => {
  return (
    <div
      className={`d-flex flex-column gap-5 py-3 px-2 rounded w-max-content ${
        from === userId ? "ml-auto bg-lightgray " : "mr-auto bg-gray"
      }`}
    >
      <>
        <div className="d-flex p0 gap-5 align-items-end gap-25 justify-content-between">
          <div className="d-flex align-items-center gap-5">
            <pre
              className={`p0 font-md lh-md text-start font-roboto pre-wrap ${
                from !== userId ? "text-white" : "text-dark-gray"
              }`}
            >
              {payload.text}
            </pre>
          </div>
          <span
            className="text-end font-sm lh-1"
            title={messageTime.date.toLocaleString()}
          >
            {messageTime.time}
          </span>
        </div>
        {payload.attached &&
          payload.attached.map(({ fileLink, fileName }) => {
            return (
              <a
                rel="noopener noreferrer"
                className="attached-container d-flex gap-5 align-items-center justify-content-end cursor-pointer"
                onClick={() => {
                  downloadFile(fileLink, fileName);
                }}
              >
                <img
                  src="images/icon/attachIcon.svg"
                  alt=""
                  style={{ height: "10px" }}
                />
                <span className="font-sm lh-1">{fileName}</span>
              </a>
            );
          })}
      </>
    </div>
  );
};

export default PrivateMessage;
