import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  ChatHistory,
  Contact,
  LessonsBalanceMessagePayload,
  MessageWithText,
  MessageType,
  ChatMessage,
  AuthUser,
} from "../../../types";
import UserCircleIcon from "../../../assets/images/user-circle-icon.svg";
import { subToChatHistory } from "../../../services/firebase";
import { unreadMessagesAtom } from "../../../state/atoms/global";
import UserName from "../../UserName";
import { contactsAtom } from "../../../state/atoms/chat";
import UnreadMessageNotification from "./dynamic-message/UnreadMessageNotification";
import { contextSignInUserAtom } from "../../../state/atoms/user";
import { getUserById } from "../../../services/firebase";

const getMessageContent = (lastMessage: ChatMessage): string => {
  switch (lastMessage.type) {
    case MessageType.Balance:
      return `You have ${lastMessage.info.text} lessons left`;
    case MessageType.HomeWork:
    case MessageType.PrivateMessage:
    case MessageType.info:
    default:
      return lastMessage.info.text || "";
  }
};

const updateContactsList = (
  contactsList: Contact[],
  cid: string,
  lastMessage: ChatMessage
) => {
  return contactsList.map((currContact) => {
    return currContact.cid === cid
      ? {
          ...currContact,
          lastMessage: {
            from: lastMessage.from,
            sentAt: lastMessage.timestamp,
            content: getMessageContent(lastMessage),
          },
        }
      : currContact;
  });
};

const UserContactDisplay: FunctionComponent<{
  contact: Contact;
  chosenContactId: string | null;
  setIsContactsShown: (value: boolean) => void;
  setChosenContact: React.Dispatch<
    React.SetStateAction<{ uid: string; displayName: string } | null>
  >;
  userId: string;
}> = ({
  contact,
  chosenContactId,
  setIsContactsShown,
  setChosenContact,
  userId,
}) => {
  const setContactsList = useSetRecoilState(contactsAtom);
  const unreadMessages = useRecoilValue(unreadMessagesAtom);
  const navigate = useNavigate();
  const chosenContactRef = useRef<HTMLLIElement>(null);
  const signedInUser = useRecoilValue(contextSignInUserAtom);
  const [currentUser, setCurrentUser] = useState<AuthUser>();
  const isExternalViewer = signedInUser?.uid !== userId;

  const getLastUserWhoMessaged = () => {
    switch (contact.lastMessage.from) {
      case userId:
        return "👨";
      case "system":
        return "⚙";
      default:
        return contact.name;
    }
  };
  useEffect(() => {
    const getUser = async () => {
      const user = await getUserById(userId);
      if (!user) return;
      setCurrentUser(user);
    };

    isExternalViewer && getUser();
  }, [userId, isExternalViewer]);

  useEffect(() => {
    if (chosenContactRef.current && chosenContactId === contact.uid) {
      chosenContactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chosenContactId, contact.uid]);

  useEffect(() => {
    if (!contact.cid) return;
    const unsub = subToChatHistory(contact.cid, (doc) => {
      if (doc.exists()) {
        const updatedChatHistory = doc.data() as ChatHistory;
        const newLastMessage =
          updatedChatHistory.history[updatedChatHistory.history.length - 1];
        if (
          !newLastMessage?.from ||
          (!(newLastMessage as MessageWithText).info.text &&
            !(newLastMessage.info as LessonsBalanceMessagePayload).text)
        ) {
          return;
        }

        setContactsList((prevState) =>
          updateContactsList(prevState, contact.cid, newLastMessage)
        );
      }
    });
    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact.cid]);

  return (
    <>
      <li
        ref={chosenContactRef}
        className={`d-flex align-items-center text-start py-2 cursor-pointer px-2 gap-10 border-bottom border-light ${
          chosenContactId === contact.uid ? "bg-xlightpurple" : ""
        }`}
        key={contact.uid}
        onClick={() => {
          setChosenContact({
            uid: contact.uid,
            displayName: contact.name,
          });
          navigate(
            `${
              isExternalViewer
                ? `/admin-panel/chats/${userId}/${contact.uid}`
                : `/my-personal-area/chat/${contact.uid}`
            }`
          );
          setIsContactsShown(false);
        }}
      >
        <img src={UserCircleIcon} alt="" />
        <div className="w-75">
          <div
            className={`font-lg lh-1 p-0  ${
              contact.isTestUser ? "opacity-03" : "text-dark-gray"
            }`}
          >
            {isExternalViewer && currentUser && (
              <>
                <UserName
                  isAdmin={currentUser.isAdmin}
                  isTeacher={currentUser.isTeacher}
                  displayName={currentUser.displayName}
                />{" "}
                {"<>"}
              </>
            )}
            <UserName
              isAdmin={contact.isAdmin}
              isTeacher={contact.isTeacher}
              displayName={contact.name}
            />
            <UnreadMessageNotification
              isShow={
                !!unreadMessages.find(
                  ({ cid, numberOfUnreadMessages }) =>
                    cid === contact.cid && numberOfUnreadMessages > 0
                )
              }
            />
          </div>
          <div className="font-md p-0 text-light-gray text-truncate">{`${
            getLastUserWhoMessaged() && contact.lastMessage.content
              ? `${getLastUserWhoMessaged()}:`
              : "No messages"
          } ${contact.lastMessage.content || ""}`}</div>
        </div>
      </li>
    </>
  );
};

export default UserContactDisplay;
