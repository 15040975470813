import React, { useState, useEffect } from "react";
import "./search-bar.css";

interface SearchBarProps<T> {
  data: T[] | null;
  searchKey: keyof T;
  placeholder: string;
  disabled: boolean;
  setFilteredData: React.Dispatch<React.SetStateAction<T[] | null>>;
}

function SearchBar<T>({
  data,
  searchKey,
  placeholder,
  disabled,
  setFilteredData,
}: SearchBarProps<T>) {
  const [searchTerm, setSearchTerm] = useState<string | "">("");

  useEffect(() => {
    if (data?.length === 0) {
      setFilteredData(null);
    } else if (searchTerm.length > 0) {
      const filtered = data?.filter((item) =>
        String(item[searchKey]).toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered ?? []);
    } else {
      setFilteredData(data);
    }
  }, [searchTerm, data, searchKey, setFilteredData]);

  return (
    <div
      className={`search-bar-container d-inline-flex align-items-center position-relative w-50 ${
        disabled && "search-bar-container-disabled"
      }`}
    >
      <input
        className="search-bar-input"
        disabled={disabled}
        type="text"
        onInput={(ev) => {
          setSearchTerm(ev.currentTarget.value);
        }}
        placeholder={placeholder}
      />
      <i className="fa fa-search search-bar-svg" />
    </div>
  );
}

export default SearchBar;
