import { atom } from "recoil";
import { AuthUser } from "../../types";

export const contextStudentsAtom = atom<AuthUser[] | null>({
  key: "contextStudentsAtom",
  default: null,
});

export const paginatedFilteredStudentsAtom = atom<AuthUser[] | null>({
  key: "paginatedFilteredStudentsAtom",
  default: null,
});

export const studentsToDisplayAtom = atom<AuthUser[] | null>({
  key: "studentsToDisplayAtom",
  default: null,
});

export const studentsPerPageAtom = atom<number>({
  key: "studentsPerPageAtom",
  default: 2,
});
