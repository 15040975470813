import { useLayoutEffect, useState } from "react";
import debounce from "lodash.debounce";
import { ScreenBreakPoints } from "../types";

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.error("Error reading to the local storage", error);
      return initialValue;
    }
  });
  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      console.error("Error writing to the local storage", error);
    }
  };
  return [storedValue, setValue] as const;
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: window.innerWidth,
    isMobile: window.innerWidth < ScreenBreakPoints.MD,
  });
  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        windowWidth: window.innerWidth,
        isMobile: window.innerWidth < ScreenBreakPoints.MD,
      });
    };

    window.addEventListener("resize", debounce(handleResize, 250));
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowDimensions;
};
