import { FunctionComponent } from "react";

const Spinner: FunctionComponent<{
  isVisible: boolean;
  size: "sm" | "md";
}> = ({ isVisible, size }) => (
  <div
    className={`spinner-border spinner-border-${size}`}
    role="status"
    style={{
      visibility: isVisible ? "visible" : "hidden",
    }}
  >
    <span className="sr-only">Loading...</span>
  </div>
);

export default Spinner;
