import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { TypedObject } from "@portabletext/types";

export enum ScreenBreakPoints {
  XS = 576,
  SM = 768,
  MD = 992,
  LG = 1200,
  XL = 1400,
}

export interface HomePageContent {
  language?: AppLanguage;
  navigationSection?: NavigationSection;
  heroSection?: {
    cta?: string;
    subtitle?: string;
    subtitle2?: string;
    heroFigures?: {
      heroOne?: Pick<HeroFigure, "image">;
      figureOne?: HeroFigure;
      figureTwo?: HeroFigure;
      figureThree?: HeroFigure;
    };
    title?: string;
  };
  bigNumbersSection?: {
    bigNumbers?: {
      description?: string;
      icon?: string;
      number?: string;
    }[];
  };
  whoWeAreSection?: {
    coverImage?: string;
    description?: TypedObject;
    title?: string;
  };
  stepsToStartSection?: {
    description?: string;
    steps?: {
      icon?: string;
      title?: string;
      description?: TypedObject;
    }[];
    title?: string;
  };
  testimonialsSection?: TestimonialsSection;
  ctaSection?: CTASection;
  footerSection?: FooterSection;
  questionsAndAnswersSection?: QuestionsAndAnswersSection;
  topics?: Topic[];
  courses?: Course[];
  authenticationContent?: AuthenticationContent;
  personalAreaSection?: MyPersonalArea;
  adminSection?: AdminSection;
  joinUsSection: JoinUsSection;
  askUsWidget: AskUsWidgetContent;
}

export interface HeroFigure {
  image: SanityImageSource;
  link: string;
}

export interface JoinUsSection {
  topSection: {
    title?: string;
    description?: TypedObject;
    cta?: string;
    topImage?: SanityImageSource;
    emailPlaceholder?: string;
    successMessage?: string;
    failureMessage?: string;
  };
}

export interface AskUsWidgetContent {
  whatsAppCTA: string;
  whatsAppCTADescription: string;
}

export interface MyPersonalArea {
  greeting: string;
  teacherSection: TeacherSection;
  studentSection?: {
    teacherTableTitle?: string;
    tableHeadingFields?: string[];
    noTeachersMessage?: string;
    incognitoUser: string;
  };
  dashboardNavbarSection?: TeacherDashboardNavbarSection;
  chatComponent?: ChatComponent;
}

export interface ChatComponent {
  contacts?: ChatContactsComponent;
  messages?: ChatMessagesComponent;
  textEditor?: ChatTextEditorComponent;
}

export interface ChatContactsComponent {
  searchPlaceholder?: string;
}
export interface ChatMessagesComponent {
  lessonsLeftMessage?: string;
  messageNotSentError?: string;
  waitForMessageFromTeacher?: string;
}
export interface ChatTextEditorComponent {
  chooseFile?: string;
  messagePlaceholder?: string;
  cantUploadFileTypeMessage?: string;
  messageTypePicker?: MessageTypePicker;
}

export interface Topic {
  title?: string;
  url?: string;
  logo?: SanityImageSource;
  calendlyUrl?: string;
  cta?: string;
  teacher?: Teacher;
  testimonials?: Testimonial[];
  contacts?: Contacts;
  plansSection?: {
    title?: string;
    plans?: Plan[];
  };
}

export interface Teacher {
  about?: TypedObject;
  name?: string;
  image?: SanityImageSource;
}

export interface Contacts {
  heading: string;
  email?: {
    title: string;
    address: string;
  };
  phones?: {
    title: string;
    phoneNumbers: string[];
  };
  socialMedia: {
    title: string;
    subTitle: string;
    socialMediaUrls: {
      name: string;
      url: string;
    }[];
  };
}

export interface Course {
  courseLogo: SanityImageSource;
  title?: string;
  url?: string;
  topImage: SanityImageSource;
  cta?: string;
  description?: TypedObject;
  subtitle?: string;
  emailPlaceholder?: string;
  successMessage?: string;
  failureMessage?: string;
  previewSectionItems?: previewSectionItem[];
  featureOne?: Feature;
  featureTwo?: Feature;
  featuresBoard?: FeaturesBoardItem[];
  plansSection?: {
    title?: string;
    plans?: Plan[];
  };
  testimonialSection?: TestimonialSection;
  bottomCta?: {
    title?: string;
    subtitle?: string;
    cta?: string;
    ctaUrl?: string;
  };
  embeddedContent?: {
    title: string;
    opening: TypedObject;
    embeddedActivity: EmbeddedActivity[];
    closing: TypedObject;
  }[];
}

export interface EmbeddedActivity {
  children: {
    text?: string;
  }[];
}

export interface TestimonialSection {
  title?: string;
  subtitle?: string;
  testimonials?: Testimonial[];
  icon?: SanityImageSource;
}

export interface Plan {
  badge?: string;
  price?: string;
  description?: string;
  planPoints?: string[];
  cta?: string;
  ctaUrl?: string;
  footerDescription?: string;
}

export interface previewSectionItem {
  title?: string;
  icon?: SanityImageSource;
  content?: SanityImageSource;
}

export interface Feature {
  upperTitle?: string;
  title?: string;
  content?: string;
  cta?: string;
  ctaUrl?: string;
  bigImage?: SanityImageSource;
  smallImage?: SanityImageSource;
  videoURL?: string;
  videoIcon?: SanityImageSource;
}

export interface FeaturesBoardItem {
  title?: string;
  content?: string;
  icon?: SanityImageSource;
}

export interface Testimonial {
  headerImage?: SanityImageSource;
  name?: string;
  quote?: TypedObject;
  description?: TypedObject;
}

export interface NavigationSection {
  courses?: string;
  cta?: string;
  logo?: SanityImageSource;
  logoAlt?: string;
  topics?: string;
}

export interface TestimonialsSection {
  testimonials?: Testimonial[];
  title?: string;
}

export interface CTASection {
  cta?: string;
  description?: string;
  title?: string;
}

export interface FooterSection {
  contacts?: {
    email?: string;
    phones?: string[];
    socialMedia?: {
      name?: string;
      link?: string;
      icon?: SanityImageSource;
    }[];
  };
  logo?: SanityImageSource;
  joinUsCTA?: string;
}

export interface QuestionsAndAnswersSection {
  questionsAndAnswers?: {
    answer?: TypedObject;
    question?: string;
  }[];
  title?: string;
}

export interface AuthenticationContent {
  personalAreaTopLink?: string;
  toMyPersonalArea?: string;
  userMessages?: UserMessages;
  loginSignupInputs?: LoginSignupInputs;
  loginSection?: LoginSignupSection;
  signupSection?: LoginSignupSection;
  resetPasswordSection?: ResetPasswordSection;
  logoutModalSection?: LogoutModalSection;
}

export interface LoginSignupInputs {
  emailLabel?: string;
  emailPlaceholder?: string;
  passwordLabel?: string;
  passwordPlaceholder?: string;
  forgotPassword?: string;
  confirmPassword?: string;
  displayNameLabel?: string;
  displayNamePlaceholder?: string;
  promoCodeLabel?: string;
  promoCodePlaceholder?: string;
  loginCta?: string;
  signupCta?: string;
  signoutCta?: string;
  verifyEmail?: string;
  underConstruction?: string;
}

export interface LoginSignupSection {
  formSection?: FormSection;
}

export interface FormSection {
  title?: string;
  isUserSigned?: string;
  forgotPassword?: string;
}

export interface ResetPasswordSection {
  resetPasswordTitle: string;
  rememberPassword: string;
  loginCta: string;
  resetPasswordCta: string;
  sendResetEmailTitle: string;
  sendResetEmailCta: string;
  resetPasswordSuccessMessage: string;
  resetPasswordErrorMessage: string;
  sendResetEmailSuccessMessage: string;
  sendResetEmailErrorMessage: string;
}

export interface AuthUser {
  uid: string;
  email: string;
  emailVerified: boolean;
  isTeacher: boolean;
  isAdmin: boolean;
  isTestUser: boolean;
  displayName: string;
  promoCode?: string;
  participatingInGroups?: string[];
  courses?: string[];
}

export interface Group {
  gid?: string;
  teacher: GroupParticipant;
  participants: GroupParticipant[];
  groupName: string;
}

export type GroupParticipant = Pick<AuthUser, "uid" | "email" | "displayName">;

export interface UserMessages {
  successMessage: string;
  errorMessage: string;
}

export interface AdminSection {
  adminPageTitle: string;
  adminPageCta: string;
  usersTableTitle: string;
  usersSearchPlaceholder: string;
  tableHeadingFields: string[];
  courseLeadsTableHeadingFields: string[];
  teacherLeadsTableHeadingFields: string[];
  chatsTableHeadingFields: string[];
  noUsersInTheSystem: string;
  noCourseLeadsInTheSystem: string;
  contactedLeadSuccessfully: string;
  noTeacherLeadsInTheSystem: string;
  incognitoUser: string;
  backToChatsMainPageButton: string;
  addRemoveMessages: {
    addTeacher: string;
    removeTeacher: string;
    addTestUser: string;
    removeTestUser: string;
    cantComplete: string;
    enrollToCourse: string;
    removeFromCourse: string;
  };
  dashboardNavbarSection: AdminDashboardNavbarSection;
  courseLeadsTab: AdminSectionTabContent;
  teachingLeadsTab: AdminSectionTabContent;
  usersTab: AdminSectionTabContent;
  chatsTab: AdminSectionTabContent;
}

interface AdminSectionTabContent {
  title: string;
  searchPlaceholder: string;
}

export interface TeacherDashboardNavbarSection {
  students: string;
  groups: string;
  homework: string;
  chat: string;
}

export interface AdminDashboardNavbarSection {
  users: string;
  coursesLeads: string;
  joinUsLeads: string;
  chats: string;
}

export enum DashboardNavbarTypes {
  teacherDashboard = "teacherDashboard",
  adminDashboard = "adminDashboard",
}

export interface TeacherSection {
  studentTableTitle: string;
  studentSearchPlaceholder: string;
  tableHeadingFields: string[];
  noStudentsInTheSystem: string;
  incognitoUser: string;
  addRemoveMessages: {
    addStudent: string;
    removeStudent: string;
    cantComplete: string;
  };
  selectContextGroup: string;
  noGroupsToDisplay: string;
  newGroupFields: {
    createNewGroupCta: string;
    addStudentsToGroupHeading: string;
    groupName: string;
    groupNamePlaceholder: string;
    pickStudentsToAdd: string;
    noGroupNameErrorMessage: string;
    cantCreateGroupErrorMessage: string;
  };
  teacherGroupsSection: {
    newGroupNamePlaceholder: string;
    editGroupTitleCta: string;
    doneEditGroupTitleCta: string;
    removeGroupCta: string;
    addStudentsToGroupCta: string;
    noStudentsInGroupMessage: string;
    addStudentsToGroupModal: {
      addStudentsHeader: string;
      pickStudentsToAdd: string;
      studentSearchPlaceholder: string;
      addStudentsCta: string;
    };
    deleteGroupModal: {
      confirmDeleteMessage: string;
      deleteGroupCta: string;
      abortDeleteGroupCta: string;
      cantDeleteGroupErrorMessage: string;
    };
    cantAddStudentsToGroupErrorMessage: string;
  };
}

export interface ContextNotification {
  message?: string;
  type: string;
  isVisible: boolean;
}

export interface ChatHistory {
  cid?: string;
  history: ChatMessage[];
  participants: string[];
}

export interface ChatMessage {
  type: MessageType;
  timestamp: number;
  from: string;
  info:
    | PrivateMessagePayload
    | LessonsBalanceMessagePayload
    | HomeworkMessagePayload
    | InfoMessagePayload;
}

export enum MessageType {
  PrivateMessage = "private-message",
  HomeWork = "home-work",
  Balance = "balance",
  info = "info",
}

export interface MessageWithText {
  info:
    | PrivateMessagePayload
    | HomeworkMessagePayload
    | InfoMessagePayload
    | LessonsBalanceMessagePayload;
}

export interface MessageWithAttachedFile {
  info:
    | PrivateMessagePayload
    | HomeworkMessagePayload
    | LessonsBalanceMessagePayload;
}

export interface PrivateMessagePayload {
  text?: string;
  attached?: { fileName: string; fileLink: string }[];
}

export interface LessonsBalanceMessagePayload {
  text: string;
  attached?: { fileName: string; fileLink: string }[];
}

export interface HomeworkMessagePayload {
  text?: string;
  attached?: { fileName: string; fileLink: string }[];
}

export interface InfoMessagePayload {
  text: string;
  attached?: { fileName: string; fileLink: string }[];
}

export interface Contact {
  name: string;
  uid: string;
  cid: string;
  isAdmin: boolean;
  isTeacher: boolean;
  isTestUser: boolean;
  lastMessage: {
    from: string | null;
    sentAt: number;
    content: string;
  };
}

export interface MessageTypePicker {
  privateMessage?: string;
  homework?: string;
  balance?: string;
  info?: string;
}

export interface LogoutModalSection {
  confirmLogoutMessage?: string;
  logoutCta?: string;
  abortLogoutCta?: string;
}

export interface UnreadMessages {
  numberOfUnreadMessages: number;
  cid: string;
}

export interface CourseLead {
  email: string;
  originPath: string;
  timestamp: { seconds: number; nanoseconds: number };
  leadId: string;
}

export interface TeacherLead {
  email: string;
  timestamp: { seconds: number; nanoseconds: number };
  leadId: string;
}

export enum AppLanguage {
  Hebrew = "he",
  English = "en",
  Russian = "ru",
}
