import client from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

const SANITY_PROJECT_ID = "tq6od9hp";
const SANITY_DATASET = "production";
const SANITY_API_VERSION = "2021-10-21";

export const sanityClient = client({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET,
  apiVersion: SANITY_API_VERSION,
  useCdn: false,
});

const builder = imageUrlBuilder(sanityClient);

export function urlForSanityImage(source: SanityImageSource) {
  return builder.image(source);
}
