import { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";
import {
  LoginSignupInputs,
  MyPersonalArea as MyPersonalAreaType,
} from "../types";
import { contextSignInUserAtom } from "../state/atoms/user";
import StudentDashboard from "./dashboard/StudentDashboard";
import TeacherDashboard from "./dashboard/teacherDashboard/TeacherDashboard";
import DynamicBubblesContainer from "./DynamicBubblesContainer";

const MyPersonalArea: FunctionComponent<{
  loginSignupInputs?: LoginSignupInputs;
  personalAreaSection?: MyPersonalAreaType;
}> = ({ personalAreaSection }) => {
  const signedInUser = useRecoilValue(contextSignInUserAtom);

  if (!signedInUser) return <></>;

  return (
    <DynamicBubblesContainer>
      <div className="row">
        <div className="col-xl-12 col-lg-10 m-auto">
          {signedInUser?.isTeacher ? (
            <TeacherDashboard
              teacher={signedInUser}
              teacherSection={personalAreaSection?.teacherSection}
              dashboardNavbarSection={
                personalAreaSection?.dashboardNavbarSection
              }
              chatComponent={personalAreaSection?.chatComponent}
            />
          ) : (
            <StudentDashboard
              student={signedInUser}
              studentSection={personalAreaSection?.studentSection}
              chatComponent={personalAreaSection?.chatComponent}
            />
          )}
        </div>
      </div>
    </DynamicBubblesContainer>
  );
};

export default MyPersonalArea;
