import { selector } from "recoil";
import { isSubmitingMessageAtom, isUploadingFileAtom } from "../atoms/global";

export const isWaitingForMessageToBeSentSelector = selector<boolean>({
  key: "isWaitingForMessageToBeSentSelector",
  get: ({ get }) => {
    const isUploadingFile = get(isUploadingFileAtom);
    const isSubmitingMessage = get(isSubmitingMessageAtom);

    return isUploadingFile || isSubmitingMessage;
  },
});
