import { initializeApp } from "firebase/app";
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  NextOrObserver,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
  verifyPasswordResetCode,
} from "firebase/auth";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  Unsubscribe,
  where,
  updateDoc,
  increment,
  QueryConstraint,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import {
  AuthUser,
  ChatHistory,
  ChatMessage,
  CourseLead,
  Group,
  GroupParticipant,
  TeacherLead,
  UnreadMessages,
} from "../types";

const baseURL = "https://unschool.cool";

export const firebaseConfig = {
  apiKey: "AIzaSyCKdP3-5qVxxRYJ0wFLABAz7W4P4PCh3Sg",
  authDomain: "unschool-cool.firebaseapp.com",
  projectId: "unschool-cool",
  storageBucket: "unschool-cool.appspot.com",
  messagingSenderId: "292811726629",
  appId: "1:292811726629:web:a954c028f77b3eddda84cc",
  measurementId: "G-C2V9VG57QF",
};

// Utils

const removeUndefineds = (obj: any) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object") removeUndefineds(val);
    else if (val === null || val === undefined) delete obj[key];
  });
};

const setDocSafely = <T>(docRef: DocumentReference<T>, data: T) => {
  const sanitized = JSON.parse(JSON.stringify(data)) as T;
  removeUndefineds(sanitized);
  return setDoc(docRef, sanitized);
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage();

// Initialize Cloud Firestore and get a reference to the service
export const firestoreDB = getFirestore(app);
export const firebaseAuth = getAuth(app);
export const firebaseFunctions = getFunctions(app);

firebaseFunctions.region = "europe-west3";

if (process.env.NODE_ENV !== "production") {
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
}

// Firestore Functions

export const registerCourseLead = async (lead: {
  email: string;
  pathname: string;
}): Promise<void> => {
  const { email, pathname } = lead;
  await addDoc(collection(firestoreDB, "contacts"), {
    timestamp: serverTimestamp(),
    email,
    originPath: pathname,
    contactedBack: false,
  });
};

export const registerTeacherApplicantLead = async (lead: {
  email: string;
  pathname: string;
}): Promise<void> => {
  const { email, pathname } = lead;

  await addDoc(collection(firestoreDB, "teacher-applicants"), {
    timestamp: serverTimestamp(),
    email,
    originPath: pathname,
    contactedBack: false,
  });
};

export const upsertUser = async (user: AuthUser): Promise<void> => {
  const { uid } = user;
  const userRef = doc(firestoreDB, "users", uid);
  await setDocSafely(userRef, user);
};

export const updateUser = async (user: AuthUser): Promise<void> => {
  const { uid } = user;

  try {
    const userRef = doc(firestoreDB, "users", uid);
    await setDocSafely(userRef, user);
  } catch (err) {
    throw new Error("Can't update user");
  }
};

export const getUserById = async (uid: string): Promise<AuthUser | null> => {
  try {
    const userRef = doc(firestoreDB, "users", uid);
    const userSnap = await getDoc(userRef);
    let userData = null;

    if (userSnap.exists()) {
      userData = userSnap.data() as AuthUser;
    }

    return userData;
  } catch (err) {
    return null;
  }
};

export const getGroupById = async (gid: string): Promise<Group | null> => {
  try {
    const groupRef = doc(firestoreDB, "groups", gid);
    const groupSnap = await getDoc(groupRef);
    let groupData = null;

    if (groupSnap.exists()) {
      groupData = groupSnap.data() as Group;
    }

    return groupData;
  } catch (err) {
    return null;
  }
};

const getUsersByQuery = async (
  queries: QueryConstraint[]
): Promise<AuthUser[] | null> => {
  try {
    const combinedQueries = [orderBy("displayName"), ...queries];
    const q = query(collection(firestoreDB, "users"), ...combinedQueries);
    const querySnapshot = await getDocs(q);
    let users: AuthUser[] = [];
    querySnapshot.forEach((doc) => {
      const {
        uid,
        email,
        emailVerified,
        isTeacher,
        isAdmin,
        isTestUser,
        displayName,
        promoCode,
        participatingInGroups,
        courses,
      } = doc.data();
      users.push({
        uid,
        email,
        emailVerified,
        isTeacher,
        isAdmin,
        isTestUser,
        displayName,
        promoCode,
        participatingInGroups,
        courses,
      });
    });

    return users;
  } catch (err) {
    return null;
  }
};

interface GetUsersByQueryParams {
  hideTestUsers?: boolean;
}

export const getTeachers = async (
  params?: GetUsersByQueryParams
): Promise<AuthUser[] | null> =>
  getUsersByQuery(
    !!params?.hideTestUsers
      ? [where("isTeacher", "==", true), where("isTestUser", "!=", true)]
      : [where("isTeacher", "==", true)]
  );

export const getStudents = async (
  params?: GetUsersByQueryParams
): Promise<AuthUser[] | null> =>
  getUsersByQuery(
    !!params?.hideTestUsers
      ? [where("isTeacher", "!=", true), where("isTestUser", "!=", true)]
      : [where("isTeacher", "!=", true)]
  );

export const getUsers = async (
  params?: GetUsersByQueryParams
): Promise<AuthUser[] | null> =>
  getUsersByQuery(
    !!params?.hideTestUsers ? [where("isTestUser", "!=", true)] : []
  );

export const getTeacherGroups = async (teacherId: string) => {
  try {
    const q = query(
      collection(firestoreDB, "groups"),
      orderBy("groupName"),
      where("teacher.uid", "==", teacherId)
    );

    const querySnapshot = await getDocs(q);
    let groups: Group[] = [];
    querySnapshot.forEach((doc) => {
      const { teacher, participants, gid, groupName } = doc.data();
      groups.push({
        gid,
        teacher,
        participants: sortParticipants(participants),
        groupName,
      });
    });
    return groups;
  } catch (err) {
    throw new Error(`Couldn't get groups for teacherId: ${teacherId}, ${err}`);
  }
};

export const getChatHistory = async (
  participants: string[]
): Promise<ChatHistory | null> => {
  try {
    const q = query(
      collection(firestoreDB, "chat-history"),
      where("participants", "array-contains", participants[0])
    );
    const querySnapshot = await getDocs(q);

    let chatHistory: ChatHistory | null = null;
    querySnapshot.forEach((doc) => {
      const chatHistoryFromDB = doc.data();
      if (
        participants.every((participant) =>
          chatHistoryFromDB.participants.includes(participant)
        ) &&
        participants.length === chatHistoryFromDB.participants.length
      ) {
        chatHistory = chatHistoryFromDB as ChatHistory;
      }
    });

    return chatHistory;
  } catch (err) {
    throw new Error(`Couldn't get chat history, ${err}`);
  }
};

export const getStudentContacts = async (studentId: string) => {
  try {
    const q = query(
      collection(firestoreDB, "chat-history"),
      where("participants", "array-contains", studentId)
    );

    const querySnapshot = await getDocs(q);
    let contactIds: string[] = [];
    querySnapshot.forEach((doc) => {
      const { participants: participantIds = [], history = [] } = doc.data();
      participantIds.forEach(
        (participantId: string) =>
          history.length &&
          !contactIds.includes(participantId) &&
          contactIds.push(participantId)
      );
    });
    return contactIds;
  } catch (err) {
    throw new Error(
      `Couldn't get contacts for studentId: ${studentId}, ${err}`
    );
  }
};

export const getChatLastMessage = async (participants: string[]) => {
  const { history, cid } = (await getChatHistory(participants)) || {};

  return {
    lastMessage: history?.[(history?.length || 0) - 1] || null,
    cid,
  };
};

export const subToChatHistory = (
  cid: string,
  observer: (snapshot: DocumentSnapshot<DocumentData>) => void
): Unsubscribe => {
  return onSnapshot<DocumentData>(
    doc(firestoreDB, "chat-history", cid),
    observer
  );
};

export const uploadFile = async (
  data: {
    senderId: string;
    recieverId: string;
    file: File;
  }[]
) => {
  const downLoadUrls: { fileLink: string; fileName: string }[] = [];
  for (const { senderId, recieverId, file } of data) {
    const storageRef = ref(
      storage,
      `attachedFiles/${senderId}/${recieverId}/${file.name}-${Date.now()}`
    );

    const uploadResult = await uploadBytesResumable(storageRef, file);
    const fileLink = await getDownloadURL(uploadResult.ref);

    downLoadUrls.push({ fileLink, fileName: file.name });
  }
  return await Promise.all(downLoadUrls);
};

export const getChatHistoryById = async (cid: string) => {
  try {
    const chatHistoryRef = doc(firestoreDB, "chat-history", cid);
    const chatHistorySnap = await getDoc(chatHistoryRef);
    let chatHistoryData = null;

    if (chatHistorySnap.exists()) {
      chatHistoryData = chatHistorySnap.data() as ChatHistory;
    }

    return chatHistoryData;
  } catch (err) {
    return null;
  }
};

export const createUnreadMsgs = async (data: {
  chatParticipants: string[];
  cid: string;
}) => {
  try {
    const { chatParticipants, cid } = data;
    for (const uid of chatParticipants) {
      await setDoc(doc(firestoreDB, "unread-messages", uid, "chats", cid), {
        numberOfUnreadMessages: 0,
        cid,
      });
    }
  } catch (err) {
    throw new Error("Can't create unread messages");
  }
};

export const resetUnreadMsg = async (data: { uid: string; cid: string }) => {
  try {
    const { cid, uid } = data;
    const docRef = doc(firestoreDB, "unread-messages", uid, "chats", cid);

    await setDoc(docRef, {
      numberOfUnreadMessages: 0,
      cid,
    });
  } catch (err) {
    throw new Error("Can't reset unread message");
  }
};

export const incrementUnreadMsg = async (data: {
  uid: string;
  cid: string;
}) => {
  try {
    const { cid, uid } = data;
    const docRef = doc(firestoreDB, "unread-messages", uid, "chats", cid);
    await updateDoc(docRef, {
      numberOfUnreadMessages: increment(1),
      cid,
    });
  } catch (err) {
    throw new Error("Can't increment unread message");
  }
};

export const getUnreadMsgs = async (data: { uid: string }) => {
  try {
    const { uid } = data;
    const q = query(collection(firestoreDB, `unread-messages/${uid}/chats`));
    const querySnapshot = await getDocs(q);
    let unreadMessages: UnreadMessages[] = [];
    querySnapshot.forEach((doc) => {
      unreadMessages.push(doc.data() as UnreadMessages);
    });
    return unreadMessages;
  } catch (err) {
    throw new Error("Can't get unread messages");
  }
};

export const subToUnreadMsgs = (
  uid: string,
  observer: (unreadMessages: UnreadMessages[]) => void
): Unsubscribe => {
  const q = query(collection(firestoreDB, `unread-messages/${uid}/chats`));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    let unreadMessages: UnreadMessages[] = [];
    querySnapshot.forEach((doc) => {
      unreadMessages.push(doc.data() as UnreadMessages);
    });
    observer(unreadMessages);
  });
  return unsubscribe;
};

export const upsertChatHistory = async (
  chatHistory: ChatHistory
): Promise<string> => {
  try {
    const existingChatHistory = await getChatHistory(chatHistory.participants);

    if (existingChatHistory) return "";

    const sanitizedChatHistory = { ...chatHistory };
    removeUndefineds(sanitizedChatHistory);
    const chatHistoryRef = await addDoc(
      collection(firestoreDB, "chat-history"),
      sanitizedChatHistory
    );
    sanitizedChatHistory.cid = chatHistoryRef.id;
    await setDoc(chatHistoryRef, sanitizedChatHistory);
    return chatHistoryRef.id;
  } catch (err) {
    console.error(err);
    throw new Error("Can't upsert chat history");
  }
};

export const updateChatHistory = async (
  chatHistory: ChatHistory
): Promise<string | null | undefined> => {
  const { cid } = chatHistory;
  if (!cid) throw new Error("Chat history dose`nt exist");
  try {
    const sanitizedChatHistory = { ...chatHistory };
    removeUndefineds(sanitizedChatHistory);

    const dbChatHistory = doc(firestoreDB, "chat-history", cid);

    await setDocSafely(dbChatHistory, sanitizedChatHistory);

    return cid;
  } catch (error) {
    console.error(error);
    throw new Error("Can't update chat history");
  }
};

export const addMsgToChatHistory = async (data: {
  cid: string;
  message: ChatMessage;
  participants: string[];
}): Promise<ChatHistory> => {
  const { cid, message, participants } = data;

  try {
    if (!cid) {
      const chatHistoryToAdd: ChatHistory = {
        participants,
        history: [message],
      };
      await upsertChatHistory(chatHistoryToAdd);
      return chatHistoryToAdd;
    } else {
      const chatHistory = await getChatHistoryById(cid);
      if (!chatHistory) throw new Error(`Can't add new message`);
      chatHistory?.history.push(message);
      await updateChatHistory(chatHistory);
      return chatHistory;
    }
  } catch (err) {
    throw new Error(`Can't add new message ${err}`);
  }
};

// Functions

const updateUserDisplayNameFunction = httpsCallable(
  firebaseFunctions,
  "updateUserDisplayNameFunction"
);

const removeStudentFromGroupFunction = httpsCallable(
  firebaseFunctions,
  "removeStudentFromGroupFunction"
);

const toggleTeacherFunction = httpsCallable(
  firebaseFunctions,
  "toggleTeacherFunction"
);

const addTestUserFunction = httpsCallable(
  firebaseFunctions,
  "addTestUserFunction"
);

const removeTestUserFunction = httpsCallable(
  firebaseFunctions,
  "removeTestUserFunction"
);

const createGroupFunction = httpsCallable(
  firebaseFunctions,
  "createGroupFunction"
);

const removeGroupFunction = httpsCallable(
  firebaseFunctions,
  "removeGroupFunction"
);

const updateGroupFunction = httpsCallable(
  firebaseFunctions,
  "updateGroupFunction"
);

const toggleStudentEnrollmentInCourseFunction = httpsCallable(
  firebaseFunctions,
  "toggleStudentEnrollmentInCourseFunction"
);

export const updateUserDisplayName = async (data: {
  uid: string;
  newName: string;
}) => {
  try {
    const res = await updateUserDisplayNameFunction(data);
    return res.data as AuthUser;
  } catch (err) {
    throw err;
  }
};

export const removeStudentFromGroup = async (data: {
  studentId: string;
  groupId: string;
}) => {
  try {
    const res = await removeStudentFromGroupFunction(data);
    return res.data as Group;
  } catch (err) {
    throw err;
  }
};

export const toggleTeacher = async (data: {
  isAdd: boolean;
  userId: string;
}) => {
  try {
    const res = await toggleTeacherFunction(data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const toggleTestUser = async (data: {
  isAdd: boolean;
  userId: string;
}) => {
  try {
    let res;
    if (data.isAdd) {
      res = await addTestUserFunction(data);
    } else {
      res = await removeTestUserFunction(data);
    }
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const createGroup = async (data: { group: Group }) => {
  try {
    const res = await createGroupFunction(data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const removeGroup = async (data: { gid: string }) => {
  try {
    const res = await removeGroupFunction(data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateGroup = async (data: {
  groupToUpdate: Group;
  updatedProperty: string;
}) => {
  try {
    const res = await updateGroupFunction(data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

// Auth helpers

export const signUserUp = (
  email: string,
  password: string
): Promise<UserCredential> =>
  createUserWithEmailAndPassword(firebaseAuth, email, password);

export const signUserIn = (
  email: string,
  password: string
): Promise<UserCredential> =>
  signInWithEmailAndPassword(firebaseAuth, email, password);

export const signUserOut = async (): Promise<void> => {
  await signOut(firebaseAuth);
};

export const sendUserEmailVerification = (user: User): Promise<void> =>
  sendEmailVerification(user, {
    url: `${baseURL}/login?showEmailVerifiedModal=true`,
  });

export const sendUserResetPassword = (email: string): Promise<void> =>
  sendPasswordResetEmail(firebaseAuth, email);

export const onAuthStateChangedHook = (observer: NextOrObserver<User>) =>
  onAuthStateChanged(firebaseAuth, observer);

const sortParticipants = (
  participants: GroupParticipant[]
): GroupParticipant[] => {
  const sortedParticipants = participants.sort((participantA, participantB) => {
    if (participantA?.displayName < participantB.displayName) {
      return -1;
    }
    if (participantA?.displayName > participantB.displayName) {
      return 1;
    }
    return 0;
  });
  return sortedParticipants;
};

export const resetUserPassword = async (
  actionCode: string,
  newPassword: string
) => {
  try {
    await verifyPasswordResetCode(firebaseAuth, actionCode);
    await confirmPasswordReset(firebaseAuth, actionCode, newPassword);
  } catch (err) {
    throw err;
  }
};

export const toggleStudentEnrollmentInCourse = async (data: {
  isAdd: boolean;
  userId: string;
  courseId: string;
}) => {
  try {
    const res = await toggleStudentEnrollmentInCourseFunction(data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getCourseLeads = async (): Promise<CourseLead[] | null> => {
  try {
    const q = query(
      collection(firestoreDB, "contacts"),
      where("contactedBack", "!=", true)
    );
    const querySnapshot = await getDocs(q);
    let courseLeads: CourseLead[] = [];
    querySnapshot.forEach((doc) => {
      const { email, originPath, timestamp } = doc.data();
      courseLeads.push({
        email,
        originPath,
        timestamp,
        leadId: doc.id,
      });
    });
    return courseLeads;
  } catch (err) {
    return null;
  }
};

export const markCourseLeadAsSeen = async (leadId: string) => {
  try {
    const docRef = doc(firestoreDB, "contacts", leadId);

    await updateDoc(docRef, {
      contactedBack: true,
    });
  } catch (err) {
    throw new Error("Can't mark lead as contacted");
  }
};

export const getTeacherLeads = async (): Promise<TeacherLead[] | null> => {
  try {
    const q = query(
      collection(firestoreDB, "teacher-applicants"),
      where("contactedBack", "!=", true)
    );
    const querySnapshot = await getDocs(q);
    let teacherLead: TeacherLead[] = [];
    querySnapshot.forEach((doc) => {
      const { email, timestamp } = doc.data();
      teacherLead.push({
        email,
        timestamp,
        leadId: doc.id,
      });
    });
    return teacherLead;
  } catch (err) {
    return null;
  }
};

export const markTeacherLeadAsSeen = async (leadId: string) => {
  try {
    const docRef = doc(firestoreDB, "teacher-applicants", leadId);

    await updateDoc(docRef, {
      contactedBack: true,
    });
  } catch (err) {
    throw new Error("Can't mark lead as contacted");
  }
};
