import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { resetUserPassword, sendUserResetPassword } from "../services/firebase";
import { LoginSignupInputs, ResetPasswordSection } from "../types";
import Loader from "./Loader";
import Notification from "./Notification";
import Spinner from "./Spinner";

const ResetPassword: FunctionComponent<{
  loginSignupInputs?: LoginSignupInputs;
  resetPasswordSection?: ResetPasswordSection;
}> = ({ loginSignupInputs, resetPasswordSection }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [actionCode, setActionCode] = useState<string | null>(null);

  const [isErrored, setIsErrored] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [emailForPasswordReset, setEmailForPasswordReset] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const modeFromParams = searchParams.get("mode");
    const oobCodeFromParams = searchParams.get("oobCode");
    if (modeFromParams === "resetPassword" && oobCodeFromParams) {
      setActionCode(oobCodeFromParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (isSubmitted && !isErrored && actionCode) {
      const intervalId = setTimeout(() => {
        navigate("/login");
      }, 3000);

      return () => clearTimeout(intervalId);
    }
  }, [isSubmitted, isErrored, actionCode, navigate]);

  if (!loginSignupInputs || !resetPasswordSection) return <Loader />;
  const {
    emailLabel,
    emailPlaceholder,
    passwordLabel,
    passwordPlaceholder,
    confirmPassword: confirmPasswordLabel,
  } = loginSignupInputs;

  const {
    resetPasswordTitle,
    rememberPassword,
    loginCta,
    resetPasswordCta,
    sendResetEmailTitle,
    sendResetEmailCta,
    resetPasswordSuccessMessage,
    resetPasswordErrorMessage,
    sendResetEmailSuccessMessage,
    sendResetEmailErrorMessage,
  } = resetPasswordSection;

  const handleSendPasswordResetEmail = async (ev: FormEvent) => {
    ev.preventDefault();
    setIsErrored(false);

    try {
      if (!emailForPasswordReset) {
        setIsErrored(true);
        return;
      }

      setIsSubmitted(false);
      setIsSubmitting(true);

      await sendUserResetPassword(emailForPasswordReset);
    } catch (err) {
      setIsErrored(true);
    } finally {
      setIsSubmitted(true);
      setIsSubmitting(false);
    }
  };

  const handlePasswordReset = async (ev: FormEvent) => {
    ev.preventDefault();
    setIsErrored(false);
    try {
      if (!actionCode || !password || password !== confirmPassword) {
        setIsErrored(true);
        return;
      }

      setIsSubmitted(false);
      setIsSubmitting(true);
      await resetUserPassword(actionCode, password);
    } catch (err) {
      setIsErrored(true);
    } finally {
      setIsSubmitted(true);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="user-data-page clearfix d-lg-flex text-start">
      <div className="illustration-wrapper d-flex align-items-center justify-content-around flex-column">
        <div className="illustration-holder">
          <img src="images/assets/ils_08.svg" alt="" className="illustration" />
          <img
            src="images/assets/ils_08.1.svg"
            alt=""
            className="shapes shape-one"
          />
          <img
            src="images/assets/ils_08.2.svg"
            alt=""
            className="shapes shape-two"
          />
        </div>
      </div>
      <div className="form-wrapper">
        <div className="d-flex justify-content-between"></div>
        {actionCode ? (
          <form
            action="#"
            className="user-data-form mt-30 md-mt-40"
            onSubmit={handlePasswordReset}
          >
            <h2>{resetPasswordTitle}</h2>
            <p className="header-info pt-30">
              {rememberPassword} <Link to={"/login"}>{loginCta}</Link>
            </p>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <Spinner isVisible={isSubmitting} size="md" />
              </div>
              {isSubmitted && (
                <div className="col-12 mb-4">
                  <Notification
                    isVisible={isSubmitted}
                    message={
                      !isErrored
                        ? resetPasswordSuccessMessage
                        : resetPasswordErrorMessage
                    }
                    type={!isErrored ? "info" : "error"}
                  />
                </div>
              )}
              <div className="col-12">
                <div className="input-group-meta mb-50">
                  <label>{passwordLabel}</label>
                  <input
                    type="password"
                    placeholder={passwordPlaceholder}
                    className="pass_log_id"
                    autoComplete="new-password"
                    value={password}
                    onChange={(ev) =>
                      setPassword(ev.currentTarget.value.trim())
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="input-group-meta mb-50">
                  <label>{confirmPasswordLabel}</label>
                  <input
                    type="password"
                    autoComplete="new-password"
                    placeholder={passwordPlaceholder}
                    value={confirmPassword}
                    onChange={(ev) =>
                      setConfirmPassword(ev.currentTarget.value.trim())
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <button className="app-theme-btn mb-50">
                  {resetPasswordCta}
                </button>
              </div>
            </div>
          </form>
        ) : (
          <form
            action="#"
            className="user-data-form mt-30 md-mt-40"
            onSubmit={handleSendPasswordResetEmail}
          >
            <h2>{sendResetEmailTitle}</h2>
            <p className="header-info pt-30">
              {rememberPassword} <Link to={"/login"}>{loginCta}</Link>
            </p>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <Spinner isVisible={isSubmitting} size="md" />
              </div>
              {isSubmitted && (
                <div className="col-12 mb-4">
                  <Notification
                    isVisible={isSubmitted}
                    message={
                      !isErrored
                        ? sendResetEmailSuccessMessage
                        : sendResetEmailErrorMessage
                    }
                    type={!isErrored ? "info" : "error"}
                  />
                </div>
              )}
              <div className="col-12">
                <div className="input-group-meta">
                  <label>{emailLabel}</label>
                  <input
                    type="email"
                    placeholder={emailPlaceholder}
                    value={emailForPasswordReset}
                    autoComplete="email"
                    onChange={(ev) =>
                      setEmailForPasswordReset(ev.currentTarget.value)
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <button className="app-theme-btn mt-50 mb-50">
                  {sendResetEmailCta}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
