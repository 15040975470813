import { FunctionComponent } from "react";

const UnreadMessageNotification: FunctionComponent<{
  isShow: boolean;
}> = ({ isShow }) => {
  return (
    <div
      className="unread-messages-notification bg-danger mx-2 rounded-circle"
      style={{
        display: isShow ? "inline-block" : "none",
      }}
    ></div>
  );
};

export default UnreadMessageNotification;
