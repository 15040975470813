import { FunctionComponent, useEffect, useState, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Dropzone from "react-dropzone";
import {
  addMsgToChatHistory,
  getChatHistory,
  subToChatHistory,
  resetUnreadMsg,
  incrementUnreadMsg,
} from "../../../services/firebase";
import {
  isSubmitingMessageAtom,
  unreadMessagesAtom,
} from "../../../state/atoms/global";
import { isWaitingForMessageToBeSentSelector } from "../../../state/selectors/global";
import {
  ChatHistory,
  ChatMessage,
  MessageWithAttachedFile,
  MessageType,
  ChatMessagesComponent,
  ChatTextEditorComponent,
} from "../../../types";
import { useWindowDimensions } from "../../../utils/hooks";
import Notification from "../../Notification";
import Spinner from "../../Spinner";
import DynamicMsgCmp from "./dynamic-message/DynamicMsgCmp";
import TextEditor from "./TextEditor";
import {
  attachedFileNameAtom,
  attachedFilesAtom,
} from "../../../state/atoms/chat";

const Messages: FunctionComponent<{
  chosenUserChat: string;
  userId: string;
  messagesFields?: ChatMessagesComponent;
  textEditorFields?: ChatTextEditorComponent;
}> = ({ chosenUserChat, userId, messagesFields, textEditorFields }) => {
  const { isMobile } = useWindowDimensions();
  const [chatHistory, setChatHistory] = useState<ChatHistory | null>(null);
  const [isErrored, setIsErrored] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const setIsSubmitingMessage = useSetRecoilState(isSubmitingMessageAtom);
  const isWaitingForMessageToBeSent = useRecoilValue(
    isWaitingForMessageToBeSentSelector
  );
  const [unreadMessages, setUnreadMessages] =
    useRecoilState(unreadMessagesAtom);
  const setAttachedFileName = useSetRecoilState(attachedFileNameAtom);
  const setAttachedFiles = useSetRecoilState(attachedFilesAtom);
  const newestMessageRef = useRef<HTMLDivElement>(null);

  const getMessageDirection = (messageText: string): "rtl" | "ltr" => {
    const firstLetterCharCode = messageText?.charCodeAt(0);
    if (
      firstLetterCharCode &&
      firstLetterCharCode >= 0x590 &&
      firstLetterCharCode <= 0x6ff
    ) {
      return "rtl";
    }
    return "ltr";
  };

  useEffect(() => {
    if (!chosenUserChat) return;
    const getChatHistoryFromDB = async () => {
      setIsLoading(true);
      setChatHistory(await getChatHistory([chosenUserChat || "", userId]));
      setIsLoading(false);
    };
    getChatHistoryFromDB();
  }, [chosenUserChat, userId]);

  useEffect(() => {
    if (!chatHistory?.cid) return;
    const unsub = subToChatHistory(chatHistory?.cid, (doc) => {
      if (doc.exists()) {
        const updatedChatHistory = doc.data() as ChatHistory;
        setChatHistory(updatedChatHistory);
        if (updatedChatHistory.cid)
          resetUnreadMsg({
            uid: userId,
            cid: updatedChatHistory?.cid,
          });
        const filteredUnreadMessages = unreadMessages.filter(
          ({ cid }) => cid !== updatedChatHistory.cid
        );
        setUnreadMessages(filteredUnreadMessages);
      }
    });

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatHistory?.cid]);

  useEffect(() => {
    if (newestMessageRef.current) {
      newestMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  });

  const addMsg = async (
    msg: string,
    msgType: MessageType,
    attachedFiles?: {
      fileName: string;
      fileLink: string;
    }[]
  ) => {
    setIsErrored(false);
    setIsSubmitingMessage(true);
    const msgToAdd: ChatMessage = {
      type: msgType,
      timestamp: Date.now(),
      from: userId,
      info: {
        text: msg,
      },
    };
    if (attachedFiles)
      (msgToAdd as MessageWithAttachedFile).info.attached = attachedFiles;

    try {
      if (chatHistory) {
        const { cid, participants } = chatHistory;
        await addMsgToChatHistory({
          cid: cid || "",
          message: msgToAdd,
          participants,
        });
        if (cid) incrementUnreadMsg({ uid: chosenUserChat, cid });
      } else {
        const newChatHistory = await addMsgToChatHistory({
          cid: "",
          message: msgToAdd,
          participants: [chosenUserChat || "", userId],
        });
        setChatHistory(newChatHistory);
        if (newChatHistory?.cid)
          incrementUnreadMsg({
            uid: chosenUserChat,
            cid: newChatHistory?.cid,
          });
      }
    } catch (err) {
      setIsErrored(true);
    } finally {
      setIsSubmitingMessage(false);
    }
  };

  if (isLoading) {
    return (
      <div className="col d-flex flex-column h-100 justify-content-between">
        <div className="justify-content-center">
          <Spinner isVisible={true} size={"md"} />
        </div>
      </div>
    );
  }

  return (
    <Dropzone
      noClick
      onDrop={(acceptedFiles) => {
        setAttachedFileName(
          acceptedFiles[acceptedFiles.length - 1].name ||
            textEditorFields?.cantUploadFileTypeMessage ||
            "Cannot upload file..."
        );
        setAttachedFiles(acceptedFiles);
      }}
    >
      {({ getRootProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={`col d-flex flex-column h-100 position-relative ${
            !isMobile ? "justify-content-between" : ""
          } `}
        >
          <div className={`${isDragActive ? "drop-screen" : ""}`}></div>
          <div className="overflow-auto flex-grow-1">
            <div className="d-flex flex-column justify-content-end px-2">
              {chatHistory?.history ? (
                chatHistory?.history.map((message, index) => (
                  <DynamicMsgCmp
                    key={`message-${index}`}
                    message={message}
                    newestMessageRef={newestMessageRef}
                    isLastMessage={chatHistory.history.length - 1 === index}
                    userId={userId}
                    lessonsLeftMessage={messagesFields?.lessonsLeftMessage}
                    direction={getMessageDirection(message.info.text || "")}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="position-relative">
            {isWaitingForMessageToBeSent && (
              <div
                className="position-absolute bg-opacity-07 pt-3"
                style={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
              >
                <Spinner isVisible={true} size="md" />
              </div>
            )}
            <TextEditor
              addMsg={addMsg}
              textEditorFields={textEditorFields}
              reciever={chosenUserChat}
            />
          </div>
          {isErrored && (
            <Notification
              isVisible={isErrored}
              type="error"
              message={
                messagesFields?.messageNotSentError || "Message was not sent"
              }
            />
          )}
        </div>
      )}
    </Dropzone>
  );
};

export default Messages;
