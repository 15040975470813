import { FunctionComponent } from "react";

const Language: FunctionComponent = () => {
  return (
    <img
      src="/images/icon/language/globe-icon.svg"
      alt=""
      className="d-inline mr-2"
      height="24px"
    />
  );
};

export default Language;
