import { FunctionComponent, useState } from "react";
import { useRecoilValue } from "recoil";

import Contacts from "./Contacts";
import Messages from "./Messages";

import userCircleIcon from "../../../assets/images/user-circle-icon.svg";
import goBackIcon from "../../../assets/images/go-back.svg";
import { ChatComponent } from "../../../types";
import { contextSignInUserAtom } from "../../../state/atoms/user";
import { useWindowDimensions } from "../../../utils/hooks";

const Chat: FunctionComponent<{
  contactIds: string[];
  userId: string;
  chatComponent?: ChatComponent;
}> = ({ contactIds, userId, chatComponent = {} }) => {
  const [chosenContact, setChosenContact] = useState<{
    uid: string;
    displayName: string;
  } | null>(null);
  const [isListShown, setIsListShown] = useState(true);
  const { isMobile } = useWindowDimensions();

  const signedInUser = useRecoilValue(contextSignInUserAtom);

  if (!signedInUser) return <></>;

  const { isTeacher } = signedInUser;

  return (
    <div
      className="position-relative d-flex flex-column"
      style={{ height: `calc(100vh - ${isTeacher ? 320 : 265}px)` }}
    >
      {!isListShown && isMobile && (
        <div className="row align-items-center py-2 justify-content-between px-3">
          <div className="d-flex align-items-center gap-5">
            <img src={userCircleIcon} alt="" />
            <p className="user-name p-0">{chosenContact?.displayName}</p>
          </div>
          <img
            src={goBackIcon}
            alt=""
            className="cursor-pointer mr-2"
            onClick={() => setIsListShown(true)}
          />
        </div>
      )}
      <div
        className="row flex-grow-1"
        style={{ height: isMobile ? "calc(100% - 70px)" : "100%" }}
      >
        <Contacts
          contactIds={contactIds}
          userId={userId}
          chosenContact={chosenContact}
          setChosenContact={setChosenContact}
          isShown={isListShown}
          setIsShown={setIsListShown}
          contactFields={chatComponent.contacts || {}}
        />
        {contactIds.length ? (
          <Messages
            chosenUserChat={chosenContact?.uid || ""}
            userId={userId}
            messagesFields={chatComponent.messages}
            textEditorFields={chatComponent.textEditor}
          />
        ) : (
          <p className="m-auto">
            {chatComponent.messages?.waitForMessageFromTeacher ||
              "Wait for a teahcer to send you a message"}
          </p>
        )}
      </div>
    </div>
  );
};

export default Chat;
