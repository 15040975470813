/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { urlForSanityImage } from "../services/sanity";
import { TestimonialsSection, Topic as TopicType } from "../types";
import Loader from "./Loader";
import Testimonials from "./Testimonials";
// import ContactUs from "./topic/ContactUs";
import Teacher from "./topic/Teacher";

const Topic: FunctionComponent<{
  topics?: TopicType[];
  testimonialsSection?: TestimonialsSection;
  cta?: string;
  onCtaClick: ({
    isOpen,
    calendlyURL,
  }: {
    isOpen: boolean;
    calendlyURL: string;
  }) => void;
}> = ({ topics, testimonialsSection, cta, onCtaClick }) => {
  let { topicId } = useParams();

  if (!topics || !topicId) return <Loader />;

  const topic = topics.find(({ url }) => url?.endsWith(topicId!));

  if (!topic) return <Loader />;

  const {
    logo,
    teacher,
    testimonials,
    calendlyUrl,
    cta: topicCta,
    plansSection,
    // contacts,
  } = topic;

  return (
    <section className="topic-main-container">
      <div className="hero-banner-seven lg-container">
        <div className="container text-center">
          <div className="illustration-container">
            <img src="images/assets/ils_20.svg" alt="" />
          </div>
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-12">
              {/* <h1 className="hero-heading p-0">
                <span>{title}</span>
              </h1> */}
              {/* <p className="hero-sub-heading">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p> */}
              <img
                src={urlForSanityImage(logo || "")
                  .width(1000)
                  .url()}
                alt=""
                className="m-auto"
              />
              {cta && (
                <a
                  href="#"
                  onClick={() =>
                    onCtaClick({ isOpen: true, calendlyURL: calendlyUrl || "" })
                  }
                  className="app-theme-btn mt-50"
                >
                  {cta}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      {teacher && (
        <Teacher
          teacher={teacher}
          cta={topicCta}
          onCtaClick={onCtaClick}
          calendlyUrl={calendlyUrl || ""}
        />
      )}

      {plansSection?.plans?.length && (
        <div className="pricing-section-six mt-200 md-mt-100" id="pricing">
          <div className="container">
            <div className="row">
              <div className="col-xl-10  m-auto">
                <div className="title-style-seven text-center">
                  <h2>{plansSection.title}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="pricing-table-area-six">
            <div className="tab-content">
              <div className="tab-pane active" id="month">
                <div className="row justify-content-center">
                  {plansSection.plans?.map(
                    (
                      {
                        badge,
                        cta,
                        description,
                        footerDescription,
                        planPoints,
                        price,
                      },
                      idx
                    ) => (
                      <div className="col-lg-4 col-sm-6">
                        <div
                          className={`pr-table-wrapper ${
                            idx === 1 ? "active" : ""
                          }`}
                        >
                          <div
                            className="pack-name"
                            style={{ background: "#FFECEC" }}
                          >
                            {badge}
                          </div>
                          <div className="price">{price}</div>
                          <div className="pack-details">{description}</div>
                          <ul className="pr-feature">
                            {planPoints?.map((point) => <li>{point}</li>)}
                          </ul>
                          <button
                            className="trial-button w-100"
                            onClick={() =>
                              onCtaClick({
                                isOpen: true,
                                calendlyURL: calendlyUrl || "",
                              })
                            }
                          >
                            {cta}
                          </button>
                          <div className="trial-text">{footerDescription}</div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {testimonials && (
        <Testimonials
          testimonialsSection={{ ...testimonialsSection, testimonials }}
        />
      )}
      {/* {contacts && (
        <section className="topic-contacts-container">
          <ContactUs contacts={contacts} />
        </section>
      )} */}
    </section>
  );
};

export default Topic;
