import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { registerCourseLead } from "../services/firebase";
import { PortableText } from "@portabletext/react";
import { Course as CourseType } from "../types";
import Loader from "./Loader";
import Notification from "./Notification";
import { urlForSanityImage } from "../services/sanity";
import { scrollToTop } from "../utils";
import { contextSignInUserAtom } from "../state/atoms/user";
import { isRtlAtom } from "../state/atoms/global";

const CourseSubscriptionForm: FunctionComponent<{
  course: CourseType;
}> = ({ course }) => {
  const { courseId } = useParams();
  const { pathname } = useLocation();

  const [email, setEmail] = useState("");
  const [lastRegisteredEmail, setLastRegisteredEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [currPreviewTab, setCurrPreviewTab] = useState(0);
  const [currTestimonial, setCurrTestimonial] = useState(0);
  const isRtl = useRecoilValue(isRtlAtom);

  const topInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setEmail("");
    setIsSubmitted(false);
    setLastRegisteredEmail("");
  }, [courseId]);

  const focusOnInput = () => {
    scrollToTop();
    topInputRef.current?.focus();
  };

  const onSaveContact = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    try {
      setIsSubmitting(true);
      await registerCourseLead({ email, pathname });
      setLastRegisteredEmail(email);
      setIsSubmitted(true);
    } catch (e) {
      setIsErrored(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const {
    cta,
    courseLogo,
    description,
    title,
    subtitle,
    emailPlaceholder,
    successMessage,
    failureMessage,
    topImage,
    previewSectionItems,
    featureOne,
    featureTwo,
    featuresBoard,
    plansSection,
    testimonialSection,
    bottomCta,
  } = course;

  const isFeatureOnePopulated =
    featureOne?.content &&
    featureOne.cta &&
    featureOne.ctaUrl &&
    featureOne.bigImage &&
    featureOne.title &&
    featureOne.upperTitle;

  const isFeatureTwoPopulated =
    featureTwo?.content &&
    featureTwo.cta &&
    featureTwo.bigImage &&
    featureTwo.title;

  const isSaveContactDisabled =
    isSubmitting || lastRegisteredEmail === email || !email;

  return (
    <>
      <div className="hero-banner-seven lg-container pb-4">
        {cta ? (
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-6 d-flex flex-column align-items-center">
                {courseLogo && (
                  <img
                    alt={title}
                    src={urlForSanityImage(courseLogo)
                      .width(160)
                      .height(160)
                      .url()}
                  />
                )}
                <h1 className="d-none d-md-block hero-heading p-0 mb-2">
                  {title && (
                    <span style={{ fontSize: "5rem", lineHeight: "100%" }}>
                      {title}
                    </span>
                  )}
                  <br />
                  {subtitle && (
                    <div style={{ fontSize: "3.5rem", lineHeight: "100%" }}>
                      {subtitle}
                    </div>
                  )}
                </h1>
                <h1 className="d-block d-md-none hero-heading p-0 mb-2">
                  {title && (
                    <span style={{ fontSize: "3rem", lineHeight: "100%" }}>
                      {title}
                    </span>
                  )}
                  <br />
                  {subtitle && (
                    <div style={{ fontSize: "2.5rem", lineHeight: "100%" }}>
                      {subtitle}
                    </div>
                  )}
                </h1>
                {description && (
                  <div>
                    <PortableText value={description} />
                  </div>
                )}
                <form
                  className="mt-4 w-100"
                  onSubmit={(ev) => onSaveContact(ev)}
                >
                  <input
                    dir="ltr"
                    type="email"
                    name="email"
                    placeholder={emailPlaceholder}
                    value={email}
                    onChange={(ev) => {
                      setEmail(ev.target.value);
                      setIsSubmitted(false);
                    }}
                    ref={topInputRef}
                  />
                  <button className="p-0 px-4" disabled={isSaveContactDisabled}>
                    {cta}
                  </button>
                  <div className="mt-2">
                    <Notification
                      isVisible={isSubmitted || isErrored}
                      message={isSubmitted ? successMessage : failureMessage}
                      type={isSubmitted ? "info" : "error"}
                    />
                  </div>
                </form>
              </div>
              <div className="d-none d-lg-block col-6 illustration-container position-static">
                {topImage && (
                  <img
                    src={urlForSanityImage(topImage).url()}
                    alt="student-animation"
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {previewSectionItems?.length && (
        <div className="fancy-feature-fifteen mt-120 md-mt-80" id="product">
          <div className="bg-wrapper">
            <div className="container">
              <div className="sldier-wrapper">
                <div id="screenSlider" className="carousel slide">
                  <div className="row">
                    <div className="col-lg-8 m-auto">
                      <ol className="carousel-indicators justify-content-between">
                        {previewSectionItems.map(({ icon, title }, idx) => (
                          <li
                            className={idx === currPreviewTab ? "active" : ""}
                            onClick={() => setCurrPreviewTab(idx)}
                          >
                            <div className="d-flex flex-column align-items-center">
                              {icon && (
                                <img
                                  src={urlForSanityImage(icon).width(50).url()}
                                  alt=""
                                  className="w-50px"
                                />
                              )}
                              <p>{title}</p>
                            </div>
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>
                  <div
                    className="carousel-inner mt-60 sm-mt-40"
                    style={{ minHeight: "300px" }}
                  >
                    {previewSectionItems.map(({ content }, idx) => (
                      <div
                        className={`carousel-item  d-block ${
                          idx === currPreviewTab ? "active" : ""
                        }`}
                      >
                        {content && (
                          <img
                            src={urlForSanityImage(content).url()}
                            alt=""
                            className="m-auto w-75"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <img
              src="/images/shape/168.svg"
              alt=""
              className="shapes shape-one"
            />
            <img
              src="/images/shape/169.svg"
              alt=""
              className="shapes shape-two"
            />
          </div>
        </div>
      )}

      <div
        className="fancy-feature-sixteen mt-120 md-mt-80 mb-120"
        id="product"
      >
        <div className="container">
          {isFeatureOnePopulated && (
            <div className="block-style-eighteen pt-50 md-mt-80 mb-120">
              <div className="row align-items-center">
                <div className="col-lg-7 order-lg-first">
                  <div className="screen-holder-two">
                    <img
                      style={{ width: "700px" }}
                      src={urlForSanityImage(featureOne.bigImage!).url()}
                      alt=""
                    />
                    {featureOne.smallImage && (
                      <img
                        src={urlForSanityImage(featureOne.smallImage).url()}
                        alt=""
                        className="shapes screen-one"
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-5 order-lg-last">
                  <div
                    className={`text-wrapper ${
                      isRtl ? "text-align-right" : ""
                    }`}
                  >
                    <h6>{featureOne.upperTitle}</h6>
                    <h3 className="title">{featureOne.title}</h3>
                    <p>{featureOne.content}</p>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href={featureOne.ctaUrl}
                      className="app-theme-btn d-flex align-items-center learn-more"
                      target="_blank"
                      rel="noreferrer"
                      style={{ width: "fit-content" }}
                    >
                      <span>{featureOne.cta}</span>
                      <img src="/images/icon/93.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className="fancy-text-block-twentyTwo lg-container mt-250 md-mt-120"
          id="whyUs"
        >
          <div className="container">
            {isFeatureTwoPopulated && (
              <div className="row align-items-center">
                <div className="col-xl-5 col-lg-6">
                  <div
                    className={`text-wrapper ${
                      isRtl ? "text-align-right" : ""
                    }`}
                  >
                    {featureTwo.videoURL && (
                      <a
                        className="fancybox mb-20 md-mb-10"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={featureTwo.videoURL}
                      >
                        <img
                          src={
                            featureTwo.videoIcon
                              ? urlForSanityImage(featureTwo.videoIcon).url()
                              : "/images/icon/71.svg"
                          }
                          alt=""
                          className="icon"
                        />
                      </a>
                    )}
                    <div className="title-style-seven">
                      <h2>{featureTwo.title}</h2>
                    </div>
                    <p>{featureTwo.content}</p>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" className="theme-btn-eight">
                      {featureTwo.cta}
                    </a>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 col-md-8">
                  <div className="illustration-holder">
                    {featureTwo.bigImage && (
                      <img
                        style={{ maxHeight: "700px", float: "right" }}
                        src={urlForSanityImage(featureTwo.bigImage).url()}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {featuresBoard?.length && (
        <div
          className="fancy-feature-seventeen mt-150 md-mt-90 mb-120"
          id="product"
        >
          <div className="bg-wrapper">
            <div className="container">
              <div className="row">
                {featuresBoard.map(({ content, icon, title }) => (
                  <div className="col-lg-4 col-sm-6">
                    <div className="block-meta">
                      <div className="icon d-flex align-items-end">
                        {icon && (
                          <img src={urlForSanityImage(icon).url()} alt="" />
                        )}
                      </div>
                      <h4>{title}</h4>
                      <p>{content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {plansSection?.plans?.length && (
        <div className="pricing-section-six mt-200 md-mt-100" id="pricing">
          <div className="container">
            <div className="row">
              <div className="col-xl-10  m-auto">
                <div className="title-style-seven text-center">
                  <h2>{plansSection.title}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="pricing-table-area-six">
            <div className="tab-content">
              <div className="tab-pane active" id="month">
                <div className="row justify-content-center">
                  {plansSection.plans?.map(
                    (
                      {
                        badge,
                        cta,
                        description,
                        footerDescription,
                        planPoints,
                        price,
                        ctaUrl,
                      },
                      idx
                    ) => (
                      <div className="col-lg-4 col-sm-6">
                        <div
                          className={`pr-table-wrapper ${
                            idx === 1 ? "active" : ""
                          }`}
                        >
                          <div
                            className="pack-name"
                            style={{ background: "#FFECEC" }}
                          >
                            {badge}
                          </div>
                          <div className="price">{price}</div>
                          <div className="pack-details">{description}</div>
                          <ul className="pr-feature">
                            {planPoints?.map((point, index) => (
                              <li key={`plan-point-${index}`}>{point}</li>
                            ))}
                          </ul>
                          <button className="trial-button w-100">
                            <a target="_blank" rel="noreferrer" href={ctaUrl}>
                              {cta}
                            </a>
                          </button>
                          <div className="trial-text">{footerDescription}</div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {testimonialSection?.testimonials?.length && (
        <div
          className="client-feedback-slider-six mt-250 md-mt-120"
          id="feedback"
        >
          <div className="inner-container">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {testimonialSection.icon && (
                    <img
                      src={urlForSanityImage(testimonialSection.icon).url()}
                      alt=""
                      className="m-auto"
                    />
                  )}
                  <div className="title-style-seven text-center mt-20">
                    <h2>{testimonialSection.title}</h2>
                    {testimonialSection.subtitle}
                  </div>
                </div>
              </div>
            </div>
            <div className="client-feedback-slider-three">
              <div className="row">
                <div className="col-xl-7 col-lg-9 m-auto" dir="ltr">
                  <ul className="slider-arrow d-flex align-items-center justify-content-center">
                    <li
                      className="prevT"
                      onClick={() =>
                        setCurrTestimonial(
                          currTestimonial - 1 < 0
                            ? testimonialSection!.testimonials!.length - 1
                            : currTestimonial - 1
                        )
                      }
                    >
                      <i className="flaticon-right-arrow-1" />
                    </li>
                    <li
                      className="nextT"
                      onClick={() =>
                        setCurrTestimonial(
                          currTestimonial + 1 >=
                            testimonialSection!.testimonials!.length
                            ? 0
                            : currTestimonial + 1
                        )
                      }
                    >
                      <i className="flaticon-right-arrow-1" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="clientSliderSix container"
              style={{ minHeight: "300px" }}
            >
              {testimonialSection.testimonials?.map(
                ({ quote, name, description }, idx) => (
                  <div
                    className={`item m-0 ${
                      idx !== currTestimonial ? "d-none" : ""
                    }`}
                  >
                    <div className="feedback-wrapper">
                      {quote && <PortableText value={quote} />}
                      <div className="d-flex align-items-center">
                        <h6 className="name">
                          {name}{" "}
                          <span>
                            {description && (
                              <PortableText value={description} />
                            )}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}

      {bottomCta && (
        <div
          className="fancy-short-banner-eight mt-120 md-mt-80"
          style={{ paddingBottom: "200px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-11 m-auto">
                <div className="title-style-seven text-center mb-4">
                  <h2>{bottomCta.title}</h2>
                  <p>{bottomCta.subtitle}</p>
                </div>
              </div>
            </div>
            <button
              onClick={() => focusOnInput()}
              className="theme-btn-eight m-auto"
            >
              <a target="_blank" rel="noreferrer" href={bottomCta.ctaUrl}>
                {bottomCta.cta}
              </a>
            </button>
          </div>
          <img
            src="/images/shape/171.svg"
            alt=""
            className="shapes shape-one"
          />
          <img
            src="/images/shape/172.svg"
            alt=""
            className="shapes shape-two"
          />
        </div>
      )}
    </>
  );
};

const CoursePlayer: FunctionComponent<{
  course: CourseType;
}> = ({ course }) => {
  const [isCollapsed, setIsCollapsed] = useState<{ [key: string]: boolean }>(
    {}
  );

  const toggleCollapsed = (index: number) => {
    setIsCollapsed({
      ...isCollapsed,
      [index]: !isCollapsed[index],
    });
  };

  const { title, embeddedContent } = course;

  useEffect(() => {
    // Open last lesson
    const lastLessonIndex = (embeddedContent?.length || 0) - 1;
    if (lastLessonIndex >= 0) {
      setIsCollapsed({ [lastLessonIndex]: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embeddedContent]);

  return (
    <div className="hero-banner-seven lg-container px-3 d-flex flex-column">
      <h1 className="hero-heading p-0 mb-2 align-self-center">
        {title && <span>{title}</span>}
      </h1>
      {embeddedContent?.length &&
        embeddedContent.map(
          ({ title, embeddedActivity, opening, closing }, index) => {
            const content = embeddedActivity[0]?.children[0].text;
            return (
              <div key={title} className="card mb-3">
                <div
                  className="card-header cursor-pointer d-flex justify-content-between"
                  onClick={() => toggleCollapsed(index)}
                >
                  <span className="text-dark">{title}</span>
                  {isCollapsed[index] ? (
                    <i className="fa fa-regular fa-arrow-up align-self-center" />
                  ) : (
                    <i className="fa fa-regular fa-arrow-down align-self-center" />
                  )}
                </div>
                <div className={`collapse ${isCollapsed[index] ? "show" : ""}`}>
                  <div className="d-flex flex-column p-3">
                    {opening && <PortableText value={opening} />}
                    {content && (
                      <div
                        className="card-body w-100"
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    )}
                    {closing && <PortableText value={closing} />}
                  </div>
                </div>
              </div>
            );
          }
        )}
    </div>
  );
};

const Course: FunctionComponent<{
  courses?: CourseType[];
}> = ({ courses }) => {
  const { courseId } = useParams();
  const signedInUser = useRecoilValue(contextSignInUserAtom);

  const course = courses?.find(({ url }) => url?.endsWith(courseId!));
  if (!course) return <Loader />;

  const isUserEnrolledToCourse = signedInUser?.courses?.some(
    (userCourseId) => userCourseId === courseId
  );

  return (
    <div>
      {isUserEnrolledToCourse ? (
        <CoursePlayer course={course} />
      ) : (
        <CourseSubscriptionForm course={course} />
      )}
    </div>
  );
};

export default Course;
