import { FunctionComponent, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import SearchIcon from "../../../assets/images/search.svg";
import {
  getChatLastMessage,
  createUnreadMsgs,
  getUserById,
  upsertChatHistory,
} from "../../../services/firebase";
import { contactsAtom } from "../../../state/atoms/chat";
import {
  Contact,
  MessageType,
  LessonsBalanceMessagePayload,
  MessageWithText,
  ChatContactsComponent,
} from "../../../types";
import { useWindowDimensions } from "../../../utils/hooks";
import Spinner from "../../Spinner";
import UserContactDisplay from "./UserContactDisplay";

const getSortedContacts = (contacts: Contact[]) => {
  contacts = [...contacts];
  return contacts.sort((contactA, contactB) => {
    if (!!contactA.lastMessage.sentAt || !!contactB.lastMessage.sentAt) {
      return (
        (contactB.lastMessage.sentAt || 0) - (contactA.lastMessage.sentAt || 0)
      );
    } else {
      return contactA.name.localeCompare(contactB.name);
    }
  });
};

const Contacts: FunctionComponent<{
  contactIds: string[];
  userId: string;
  chosenContact: { uid: string; displayName: string } | null;
  setChosenContact: React.Dispatch<
    React.SetStateAction<{ uid: string; displayName: string } | null>
  >;
  isShown: boolean;
  setIsShown: (value: boolean) => void;
  contactFields: ChatContactsComponent;
}> = ({
  contactIds,
  userId,
  setChosenContact,
  chosenContact,
  isShown,
  setIsShown,
  contactFields,
}) => {
  const { isMobile } = useWindowDimensions();
  const [contactsList, setContactsList] = useRecoilState(contactsAtom);
  const [contactsListToDisplay, setContactsListToDisplay] = useState<
    Contact[] | null
  >(null);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { uid } = useParams();

  useEffect(() => {
    const createContactsList = async () => {
      setIsLoading(true);
      if (!contactIds) return;
      const contactsListDirty = await Promise.all(
        contactIds.map(async (contactId) => {
          const contactUser = await getUserById(contactId);

          if (!contactUser) return;

          const chatLastMessage = await getChatLastMessage([
            contactUser?.uid,
            userId,
          ]);

          if (!chatLastMessage?.cid) {
            const newChatHistory = {
              history: [],
              participants: [contactId, userId],
            };
            const newCid = await upsertChatHistory(newChatHistory);
            chatLastMessage.cid = newCid;

            await createUnreadMsgs({
              chatParticipants: newChatHistory.participants,
              cid: newCid,
            });
          }

          return {
            name: contactUser.displayName,
            uid: contactUser?.uid,
            cid: chatLastMessage.cid,
            isAdmin: contactUser.isAdmin,
            isTeacher: contactUser.isTeacher,
            isTestUser: contactUser.isTestUser,
            lastMessage: {
              from: chatLastMessage?.lastMessage?.from || null,
              sentAt: chatLastMessage?.lastMessage?.timestamp || null,
              content: chatLastMessage
                ? chatLastMessage?.lastMessage?.type === MessageType.Balance
                  ? (
                      chatLastMessage?.lastMessage
                        .info as LessonsBalanceMessagePayload
                    ).text
                  : (chatLastMessage?.lastMessage as MessageWithText)?.info.text
                : null,
            },
          };
        })
      );

      const contactList = contactsListDirty.filter(
        (contact) => !!contact
      ) as Contact[];

      setContactsList(contactList);

      let lastPersonInChat;
      let lastPersonInChatToSet;

      if (uid) {
        lastPersonInChat = contactList.find((contact) => {
          return contact.uid === uid;
        });
      }
      if (lastPersonInChat) {
        lastPersonInChatToSet = {
          uid: lastPersonInChat.uid,
          displayName: lastPersonInChat.name,
        };
      } else
        lastPersonInChatToSet = {
          uid: contactList[0]?.uid,
          displayName: contactList[0]?.name,
        };

      setChosenContact(lastPersonInChatToSet);
      setIsLoading(false);
    };
    createContactsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactIds, userId]);

  useEffect(() => {
    if (!contactsList) return;
    const sortedContacts = getSortedContacts(contactsList);
    setContactsListToDisplay(sortedContacts);
    if (!uid)
      setChosenContact({
        uid: sortedContacts[0]?.uid,
        displayName: sortedContacts[0]?.name,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsList]);

  useEffect(() => {
    if (!contactsList) return;
    if (search)
      setContactsListToDisplay(
        getSortedContacts(
          contactsList.filter((contact) =>
            contact.name.toLowerCase().includes(search.toLowerCase())
          )
        )
      );
    else setContactsListToDisplay(getSortedContacts(contactsList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-25">
        <Spinner isVisible={true} size={"md"} />
      </div>
    );
  }

  return isShown || !isMobile ? (
    <div className={`contacts-container h-100 mw-100 bg-white col-3 px-0`}>
      <div className="border-lightpurple border-radius-20 text-dark-gray h-40 position-relative d-flex align-items-center justify-content-between px-4 mx-2 my-3">
        <input
          className="border-0 w-100"
          type="text"
          placeholder={contactFields.searchPlaceholder || "Search..."}
          onChange={(e) => setSearch(e.currentTarget.value)}
          value={search}
        />
        <img src={SearchIcon} alt="" />
      </div>
      <div className="contacts-list-container">
        {contactsListToDisplay && (
          <ul>
            {contactsListToDisplay.map((contact, index) => {
              return (
                <UserContactDisplay
                  contact={contact}
                  chosenContactId={chosenContact?.uid || null}
                  setChosenContact={setChosenContact}
                  setIsContactsShown={setIsShown}
                  userId={userId}
                  key={contact.uid}
                />
              );
            })}
          </ul>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Contacts;
