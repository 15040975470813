import React, { FunctionComponent } from "react";

const UserName: FunctionComponent<{
  isAdmin?: boolean;
  isTeacher?: boolean;
  displayName: string;
}> = ({ isAdmin = false, isTeacher = false, displayName }) => {
  return (
    <>
      {isAdmin && "⚙️"} {isTeacher && "👨‍🏫"} {displayName || "-"}
    </>
  );
};

export default UserName;
