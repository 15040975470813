import { FunctionComponent } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { contextSignInUserAtom } from "../state/atoms/user";

const ProtectedRoutes: FunctionComponent<{}> = () => {
  const signedInUser = useRecoilValue(contextSignInUserAtom);
  return signedInUser ? <Outlet /> : <Navigate to="/login" />;
};
export default ProtectedRoutes;
