import { atom } from "recoil";
import { Contact } from "../../types";

export const contactsAtom = atom<Contact[]>({
  key: "contactsAtom",
  default: [],
});

export const attachedFileNameAtom = atom<string | null>({
  key: "attachedFileNameAtom",
  default: null,
});

export const attachedFilesAtom = atom<File[]>({
  key: "attachedFilesAtom",
  default: [],
});
