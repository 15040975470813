import { atom } from "recoil";

export const isFetchingDataAtom = atom<boolean>({
  key: "isFetchingDataAtom",
  default: false,
});

export const showModalAtom = atom<boolean>({
  key: "showModalAtom",
  default: false,
});
