/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent } from "react";
import { urlForSanityImage } from "../../services/sanity";
import { PortableText } from "@portabletext/react";

import { Teacher as TeacherType } from "../../types";

const Teacher: FunctionComponent<{
  teacher?: TeacherType;
  cta?: string;
  calendlyUrl: string;
  onCtaClick: ({
    isOpen,
    calendlyURL,
  }: {
    isOpen: boolean;
    calendlyURL: string;
  }) => void;
}> = ({ teacher, cta, onCtaClick, calendlyUrl }) => {
  if (!teacher) return <></>;

  const { about, name, image } = teacher;

  return (
    <div className="fancy-feature-nine p-5">
      <div className="bg-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-7 col-sm-10 text-start d-flex flex-column align-items-start ">
              <div className="title-style-five">
                <h2 className="mb-30">
                  <span>{name}</span>
                </h2>
              </div>
              <div className="sub-heading px-0">
                {about && <PortableText value={about} />}
              </div>
              {cta && (
                <a
                  href="#"
                  onClick={() =>
                    onCtaClick({ isOpen: true, calendlyURL: calendlyUrl || "" })
                  }
                  className="app-theme-btn mt-50 mb-10"
                >
                  {cta}
                </a>
              )}
            </div>
            <div className="col-12 col-lg-5 mt-30 mt-lg-0 text-center">
              <img
                src={urlForSanityImage(image || "")
                  .width(450)
                  .url()}
                alt="teacher"
                className="height-fit-content m-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teacher;
