/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { InlineWidget } from "react-calendly";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";

import { sanityClient, urlForSanityImage } from "./services/sanity";
import { PortableText } from "@portabletext/react";
import { useLocalStorage } from "./utils/hooks";
import { AppLanguage, HomePageContent } from "./types";
import { contextSignInUserAtom } from "./state/atoms/user";

import Modal from "./components/Modal";
import Loader from "./components/Loader";
import Topic from "./components/Topic";
import Course from "./components/Course";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Signup from "./components/Signup";
import {
  getUnreadMsgs,
  getUserById,
  onAuthStateChangedHook,
  signUserOut,
  subToUnreadMsgs,
  updateUser,
} from "./services/firebase";
import MyPersonalArea from "./components/MyPersonalArea";
import OuterRedirect from "./components/OuterRedirect";
import ProtectedRoutes from "./components/ProtectedRoutes";
import ProtectedRoutesAdmin from "./components/ProtectedRoutesAdmin";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import { showModalAtom } from "./state/atoms/ui";
import { scrollToTop } from "./utils";
import { isFetchingDataAtom } from "./state/atoms/ui";
import { isRtlAtom, unreadMessagesAtom } from "./state/atoms/global";
import ResetPassword from "./components/ResetPassword";
import UnreadMessageNotification from "./components/dashboard/chat/dynamic-message/UnreadMessageNotification";
import { JoinUs } from "./components/JoinUs";
import Language from "./components/language/Language";
import AskUsWidget from "./components/ask-us-widget/AskUsWidget";
import Testimonials from "./components/Testimonials";

const DEFAULT_LANGUAGE =
  window.navigator.language === AppLanguage.Hebrew
    ? AppLanguage.Hebrew
    : AppLanguage.Russian;
const LANGUAGES = {
  [AppLanguage.Russian]: "Русский",
  [AppLanguage.English]: "English",
  [AppLanguage.Hebrew]: "עברית",
};

const WrapperApp: FunctionComponent = () => (
  <RecoilRoot>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </RecoilRoot>
);

const App: FunctionComponent<{}> = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [loading, isLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useLocalStorage(
    "selectedLanguage",
    DEFAULT_LANGUAGE
  );
  const [homePageContent, setHomePageContent] = useState<HomePageContent[]>([]);
  const [isRtl, setIsRtl] = useRecoilState(isRtlAtom);

  const [showNavbar, setShowNavbar] = useState(false);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [showModal, setShowModal] = useRecoilState(showModalAtom);
  const [unreadMessages, setUnreadMessages] =
    useRecoilState(unreadMessagesAtom);

  const [calendlyURL, setCalendlyURL] = useState("");

  const isFetchingData = useRecoilValue(isFetchingDataAtom);
  const [isWaitingForAuth, setIsWaitingForAuth] = useState(false);
  const [signedInUser, setSignedInUser] = useRecoilState(contextSignInUserAtom);

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    setIsRtl(selectedLanguage === AppLanguage.Hebrew);
  }, [setIsRtl, selectedLanguage]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const query = '*[_type == "homePageContent"]';
      const params = {};
      const results: HomePageContent[] = await sanityClient.fetch(
        query,
        params
      );
      setHomePageContent(results);
    };
    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onAuthStateChangedHook(async (authUser) => {
      try {
        setIsWaitingForAuth(true);
        if (!authUser) {
          setSignedInUser(null);
          return;
        }
        const {
          uid,
          email: authEmail,
          emailVerified: authEmailVerified,
        } = authUser;

        if (!authEmail) {
          return;
        }
        const dbUser = await getUserById(uid);
        const {
          emailVerified: dbEmailVerified,
          isTeacher = false,
          isAdmin = false,
          isTestUser = false,
          displayName = "",
          participatingInGroups,
          promoCode,
          courses,
        } = dbUser || {};

        setSignedInUser({
          uid,
          email: authEmail,
          emailVerified: authEmailVerified || false,
          isTeacher,
          isAdmin,
          isTestUser,
          displayName,
          participatingInGroups,
          promoCode,
          courses,
        });

        if (!dbEmailVerified && authEmailVerified) {
          await updateUser({
            uid,
            email: authEmail,
            emailVerified: authEmailVerified,
            isTeacher,
            isAdmin,
            isTestUser,
            displayName,
          });
        }
        const newUnreadMessages = await getUnreadMsgs({ uid });
        setUnreadMessages(newUnreadMessages);
      } catch (err) {
        setSignedInUser(null);
      } finally {
        setIsWaitingForAuth(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSignedInUser]);

  useEffect(() => {
    if (!signedInUser?.uid) return;
    const { uid } = signedInUser;

    const unsub = subToUnreadMsgs(uid, (updatedUnreadMessages) => {
      setUnreadMessages(updatedUnreadMessages);
    });

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedInUser]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      isLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const selectedContent =
    homePageContent.find(({ language }) => language === selectedLanguage) ||
    homePageContent.find(({ language }) => language === DEFAULT_LANGUAGE) ||
    null;

  useEffect(() => {
    if (selectedContent && pathname.startsWith("/topics")) {
      setCalendlyURL(
        selectedContent.topics?.find(({ url }) => url?.startsWith(pathname))
          ?.calendlyUrl || ""
      );
    } else {
      setCalendlyURL("");
    }
  }, [pathname, showModal, selectedContent]);

  if (!selectedContent || loading) {
    return <Loader />;
  }

  const {
    navigationSection,
    heroSection,
    bigNumbersSection,
    whoWeAreSection,
    stepsToStartSection,
    questionsAndAnswersSection,
    testimonialsSection,
    ctaSection,
    footerSection,
    topics,
    courses,
    authenticationContent,
    personalAreaSection,
    adminSection,
    joinUsSection,
    askUsWidget,
  } = selectedContent;

  const wrapFirstWordWithSpan = (text?: string) => {
    if (!text) {
      return "";
    }
    const words = text.split(" ");
    const firstWord = words[0];
    const restOfTheWords = words.slice(1);

    return (
      <>
        <span>{firstWord}</span> {restOfTheWords.join(" ")}
      </>
    );
  };
  if (isWaitingForAuth) return <Loader />;
  return (
    <>
      {(isFetchingData || isWaitingForAuth) && <Loader />}
      <Helmet
        htmlAttributes={{
          dir: isRtl ? "rtl" : "ltr",
        }}
      />
      {signedInUser && (
        <div
          className={`user-session-indication ${
            signedInUser?.isAdmin
              ? "bg-danger"
              : signedInUser?.isTeacher
              ? "bg-success"
              : "bg-purple"
          }`}
        ></div>
      )}
      <div className={`main-page-wrapper ${isRtl && "font-varela-round"} p0`}>
        <div
          className={`theme-main-menu sticky-menu theme-menu-four ${
            pathname.includes("my-personal-area") ? "bg-white" : "bg-none"
          }`}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="logo py-1">
              {navigationSection?.logo && (
                <Link to="/">
                  <img
                    src={urlForSanityImage(navigationSection.logo)
                      .width(200)
                      .url()}
                    alt=""
                  />
                </Link>
              )}
            </div>
            <nav id="mega-menu-holder" className="navbar navbar-expand-xl">
              <div className="nav-container">
                <button
                  className="navbar-toggler"
                  onClick={() => setShowNavbar(!showNavbar)}
                >
                  <span />
                </button>
                <div
                  className={`navbar-collapse collapse ${
                    showNavbar ? "show" : ""
                  }`}
                  id="navbarSupportedContent"
                >
                  <div
                    className={`d-xl-flex align-items-center ${
                      isRtl ? "gap-20" : "gap-30"
                    }`}
                  >
                    <ul className="navbar-nav gap-30">
                      <li className="nav-item dropdown">
                        <a
                          href="#"
                          className={`nav-link m0 ${
                            topics?.length ? "dropdown-toggle" : ""
                          }`}
                          data-toggle="dropdown"
                        >
                          {navigationSection?.topics}
                        </a>
                        {topics?.length && (
                          <ul
                            className={`dropdown-menu ${
                              isRtl ? "dropdown-menu-rtl" : ""
                            }`}
                          >
                            {topics
                              ?.sort((a, b) =>
                                (a.title || "").localeCompare(b.title || "")
                              )
                              .map((topic, index) => {
                                const { url, title } = topic;

                                return (
                                  url && (
                                    <li key={`navigation-topics-${index}`}>
                                      <Link
                                        to={url}
                                        onClick={() => setShowNavbar(false)}
                                        className="dropdown-item"
                                      >
                                        {title}
                                      </Link>
                                    </li>
                                  )
                                );
                              })}
                          </ul>
                        )}
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          href="#"
                          className={`nav-link m0 ${
                            courses?.length ? "dropdown-toggle" : ""
                          }`}
                          data-toggle="dropdown"
                        >
                          {navigationSection?.courses}
                        </a>
                        {courses?.length && (
                          <ul
                            className={`dropdown-menu ${
                              isRtl ? "dropdown-menu-rtl" : ""
                            }`}
                          >
                            {courses
                              ?.sort((a, b) =>
                                (a.title || "").localeCompare(b.title || "")
                              )
                              .map(
                                ({ url, title }, index) =>
                                  url && (
                                    <li key={`navigation-courses-${index}`}>
                                      <Link
                                        to={url}
                                        onClick={() => setShowNavbar(false)}
                                        className="dropdown-item"
                                      >
                                        {title}
                                      </Link>
                                    </li>
                                  )
                              )}
                          </ul>
                        )}
                      </li>
                      {homePageContent?.length > 1 && (
                        <li className="nav-item dropdown">
                          <a
                            href="#"
                            className={`nav-link m0 ${
                              homePageContent.length ? "dropdown-toggle" : ""
                            }`}
                            data-toggle="dropdown"
                          >
                            <div
                              className="d-flex align-items-center"
                              dir="ltr"
                            >
                              <Language />
                            </div>
                          </a>
                          <ul
                            className={`dropdown-menu ${
                              isRtl
                                ? "language-dropdown-menu-rtl"
                                : "language-dropdown-menu-ltr"
                            }`}
                          >
                            {homePageContent
                              ?.sort((a, b) =>
                                (a.language || "").localeCompare(
                                  b.language || ""
                                )
                              )
                              .map(({ language }, index) => (
                                <li
                                  key={`navigation-courses-${index}`}
                                  onClick={() => {
                                    setSelectedLanguage(
                                      language || DEFAULT_LANGUAGE
                                    );
                                    setShowNavbar(false);
                                  }}
                                >
                                  <a className="dropdown-item cursor-pointer m0">
                                    {LANGUAGES[language || DEFAULT_LANGUAGE]}
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </li>
                      )}
                      <li className="nav-item dropdown">
                        <a
                          href="#"
                          className="nav-link dropdown-toggle m0"
                          data-toggle="dropdown"
                        >
                          {authenticationContent?.personalAreaTopLink}
                          <UnreadMessageNotification
                            isShow={
                              !!unreadMessages.find(
                                ({ numberOfUnreadMessages }) =>
                                  numberOfUnreadMessages > 0
                              )
                            }
                          />
                        </a>
                        <ul
                          className={`dropdown-menu ${
                            // The elements gets the opposite class for the selected language to keep the list inside the screen and prevent overflow.
                            isRtl ? "" : "dropdown-menu-rtl"
                          }`}
                        >
                          <li key="login-routes-personal">
                            <Link
                              to={signedInUser ? "/my-personal-area" : "/login"}
                              onClick={() => setShowNavbar(false)}
                              className="dropdown-item"
                            >
                              {authenticationContent?.toMyPersonalArea}
                              <UnreadMessageNotification
                                isShow={
                                  !!unreadMessages.find(
                                    ({ numberOfUnreadMessages }) =>
                                      numberOfUnreadMessages > 0
                                  )
                                }
                              />
                            </Link>
                          </li>
                          {signedInUser?.isAdmin && (
                            <li key="admin">
                              <Link
                                to="/admin-panel"
                                className="dropdown-item"
                                onClick={() => {
                                  setShowNavbar(false);
                                }}
                              >
                                {adminSection?.adminPageCta}
                              </Link>
                            </li>
                          )}
                          {signedInUser ? (
                            <li key="logout">
                              <a
                                className="dropdown-item cursor-pointer"
                                onClick={() => {
                                  setShowNavbar(false);
                                  setShowSignOutModal(true);
                                }}
                              >
                                {
                                  authenticationContent?.loginSignupInputs
                                    ?.signoutCta
                                }
                              </a>
                            </li>
                          ) : (
                            <li key="login">
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  setShowNavbar(false);
                                }}
                                to="/login"
                              >
                                {
                                  authenticationContent?.loginSignupInputs
                                    ?.loginCta
                                }
                              </Link>
                            </li>
                          )}
                        </ul>
                      </li>
                    </ul>
                    {!signedInUser && (
                      <ul
                        className={`right-button-group d-flex align-items-center justify-content-center m-3 m-lg-0`}
                      >
                        <li>
                          <a
                            href="#"
                            onClick={() => setShowModal(true)}
                            className="app-theme-btn"
                          >
                            {navigationSection?.cta}
                          </a>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <Routes>
          <Route
            path="/courses/:courseId"
            element={
              <>
                <Course courses={courses} />
                {footerSection && (
                  <Footer
                    footerSection={footerSection}
                    questionsAndAnswersSection={questionsAndAnswersSection}
                    ctaSection={ctaSection}
                    navigationSection={navigationSection}
                    topics={topics}
                    courses={courses}
                  />
                )}
              </>
            }
          />
          <Route
            path="/topics/:topicId"
            element={
              <>
                <Topic
                  topics={topics}
                  cta={ctaSection?.cta}
                  testimonialsSection={testimonialsSection}
                  onCtaClick={({ isOpen, calendlyURL }) => {
                    setCalendlyURL(calendlyURL);
                    setShowModal(isOpen);
                  }}
                />
                {footerSection && (
                  <Footer
                    footerSection={footerSection}
                    questionsAndAnswersSection={questionsAndAnswersSection}
                    ctaSection={ctaSection}
                    navigationSection={navigationSection}
                    topics={topics}
                    courses={courses}
                  />
                )}
              </>
            }
          />
          <Route
            path="/join-the-team"
            element={
              <>
                <JoinUs joinUsSection={joinUsSection} />
                {footerSection && (
                  <Footer
                    footerSection={footerSection}
                    questionsAndAnswersSection={questionsAndAnswersSection}
                    ctaSection={ctaSection}
                    navigationSection={navigationSection}
                    topics={topics}
                    courses={courses}
                  />
                )}
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Login
                  loginSection={authenticationContent?.loginSection}
                  loginSignupInputs={authenticationContent?.loginSignupInputs}
                  userMessages={authenticationContent?.userMessages}
                />
              </>
            }
          />
          <Route
            path="/reset-password"
            element={
              <>
                <ResetPassword
                  loginSignupInputs={authenticationContent?.loginSignupInputs}
                  resetPasswordSection={
                    authenticationContent?.resetPasswordSection
                  }
                />
              </>
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <Signup
                  signupSection={authenticationContent?.signupSection}
                  loginSignupInputs={authenticationContent?.loginSignupInputs}
                  userMessages={authenticationContent?.userMessages}
                />
              </>
            }
          />
          <Route path="/redirect" element={<OuterRedirect />} />
          <Route element={<ProtectedRoutes />}>
            <Route
              path="/my-personal-area/*"
              element={
                <MyPersonalArea
                  loginSignupInputs={authenticationContent?.loginSignupInputs}
                  personalAreaSection={personalAreaSection}
                />
              }
            />
          </Route>
          <Route element={<ProtectedRoutesAdmin />}>
            <Route
              path="/admin-panel/*"
              element={<AdminDashboard adminSection={adminSection} />}
            />
          </Route>
          <Route
            path="/"
            element={
              <>
                {heroSection && (
                  <div className="hero-banner-four">
                    <img
                      src="/images/shape/100.svg"
                      alt=""
                      className="shapes shape-four"
                    />
                    <img
                      src="/images/shape/101.svg"
                      alt=""
                      className="shapes shape-five"
                    />
                    <img
                      src="/images/shape/102.svg"
                      alt=""
                      className="shapes shape-six"
                    />
                    <img
                      src="/images/shape/103.svg"
                      alt=""
                      className="shapes shape-seven"
                    />
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6 order-lg-last">
                          <div className="illustration-holder">
                            {heroSection.heroFigures?.heroOne?.image && (
                              <img
                                src={urlForSanityImage(
                                  heroSection.heroFigures?.heroOne?.image || ""
                                )
                                  .width(600)
                                  .url()}
                                alt=""
                                className="illustration"
                              />
                            )}
                            {heroSection.heroFigures?.figureOne?.image && (
                              <Link
                                to={
                                  heroSection.heroFigures?.figureOne?.link ||
                                  "#"
                                }
                              >
                                <img
                                  src={urlForSanityImage(
                                    heroSection.heroFigures?.figureOne?.image ||
                                      ""
                                  )
                                    .width(150)
                                    .url()}
                                  alt=""
                                  className="shapes shape-one"
                                  style={{
                                    width: "100%",
                                    maxWidth: "150px",
                                    maxHeight: "150px",
                                  }}
                                />
                              </Link>
                            )}
                            {heroSection.heroFigures?.figureTwo?.image && (
                              <Link
                                to={
                                  heroSection.heroFigures?.figureTwo?.link ||
                                  "#"
                                }
                              >
                                <img
                                  src={urlForSanityImage(
                                    heroSection.heroFigures?.figureTwo?.image ||
                                      ""
                                  )
                                    .width(150)
                                    .url()}
                                  alt=""
                                  className="shapes shape-two"
                                  style={{
                                    width: "100%",
                                    maxWidth: "150px",
                                    maxHeight: "150px",
                                  }}
                                />
                              </Link>
                            )}
                            {heroSection.heroFigures?.figureThree?.image && (
                              <Link
                                to={
                                  heroSection.heroFigures?.figureThree?.link ||
                                  "#"
                                }
                              >
                                <img
                                  src={urlForSanityImage(
                                    heroSection.heroFigures?.figureThree
                                      ?.image || ""
                                  )
                                    .width(150)
                                    .url()}
                                  alt=""
                                  className="shapes shape-three"
                                  style={{
                                    width: "100%",
                                    maxWidth: "150px",
                                    maxHeight: "150px",
                                  }}
                                />
                              </Link>
                            )}
                            <p className="sub-text">{heroSection.subtitle2}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 order-lg-first">
                          <div className="text-wrapper text-start">
                            <h1>{wrapFirstWordWithSpan(heroSection.title)}</h1>
                            <p className="sub-text">{heroSection.subtitle}</p>
                            <a
                              href="#"
                              onClick={() => setShowModal(true)}
                              className="app-theme-btn"
                            >
                              {heroSection.cta}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {bigNumbersSection && (
                      <div className="fancy-feature-eight mt-160 md-mt-100">
                        <div className="container">
                          <div className="bg-wrapper">
                            <div className="row justify-content-center">
                              {bigNumbersSection.bigNumbers?.map(
                                ({ icon, number, description }, index) => (
                                  <div
                                    key={`big-number-${index}`}
                                    className="col-lg-4 col-md-6"
                                  >
                                    <div className="block-style-thirteen">
                                      {icon && (
                                        <div className="icon">
                                          <img
                                            src={urlForSanityImage(icon)
                                              .width(120)
                                              .url()}
                                            style={{
                                              maxWidth: "120px",
                                              maxHeight: "120px",
                                            }}
                                            alt=""
                                          />
                                        </div>
                                      )}
                                      <div
                                        className={`title ${
                                          isRtl
                                            ? "font-varela-round"
                                            : "font-rubik"
                                        }`}
                                      ></div>
                                      <h3 dir="ltr" className="mb-3">
                                        {number}
                                      </h3>
                                      <p>{description}</p>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {whoWeAreSection && (
                  <div className="fancy-text-block-nineteen mt-250 mb-200 md-mt-150 md-mb-150">
                    <div className="container">
                      <div className="row align-items-center">
                        {whoWeAreSection.coverImage && (
                          <div className="col-lg-7">
                            <img
                              src={urlForSanityImage(whoWeAreSection.coverImage)
                                .width(600)
                                .url()}
                              alt=""
                              className="m-auto"
                            />
                          </div>
                        )}
                        <div className="col-lg-5">
                          <div className="text-wrapper">
                            <div className="title-style-five">
                              <h2 className="text-start">
                                {whoWeAreSection.title}
                              </h2>
                            </div>
                            <div
                              className={`${
                                isRtl ? "font-varela-round" : "font-rubik"
                              } text-start`}
                            >
                              {whoWeAreSection.description && (
                                <PortableText
                                  value={whoWeAreSection.description}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {stepsToStartSection && (
                  <div className="fancy-feature-nine pb-4">
                    <img
                      src="/images/shape/107.svg"
                      alt=""
                      className="shapes shape-one"
                    />
                    <img
                      src="/images/shape/108.svg"
                      alt=""
                      className="shapes shape-two"
                    />
                    <img
                      src="/images/shape/109.svg"
                      alt=""
                      className="shapes shape-three"
                    />
                    <img
                      src="/images/shape/110.svg"
                      alt=""
                      className="shapes shape-four"
                    />
                    <img
                      src="/images/shape/111.svg"
                      alt=""
                      className="shapes shape-five"
                    />
                    <img
                      src="/images/shape/112.svg"
                      alt=""
                      className="shapes shape-six"
                    />
                    <div className="container">
                      <div className="title-style-five text-center mb-60 md-mb-30">
                        <h6>{stepsToStartSection.title}</h6>
                        <h2>{stepsToStartSection.description}</h2>
                      </div>
                      {stepsToStartSection.steps && (
                        <div className="row justify-content-center">
                          {stepsToStartSection.steps.map(
                            ({ icon, title, description }, index) => (
                              <div
                                key={`steps-to-start-step-${index}`}
                                className="col-lg-4 col-md-6"
                              >
                                <div
                                  className={`block-style-fourteen ${
                                    index % 2
                                      ? "arrow-shape" +
                                        (isRtl ? " arrow-shape-rtl" : "")
                                      : ""
                                  }`}
                                >
                                  {icon && (
                                    <div className="illustration">
                                      <img
                                        src={urlForSanityImage(icon)
                                          .width(320)
                                          .url()}
                                        style={{ aspectRatio: "16/11" }}
                                        alt=""
                                        className="m-auto"
                                      />
                                    </div>
                                  )}
                                  <div className="title">
                                    {description && (
                                      <PortableText value={description} />
                                    )}
                                  </div>
                                  <p
                                    className={`${
                                      isRtl ? "font-varela-round" : "font-rubik"
                                    }`}
                                  >
                                    {title}
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                    {heroSection?.cta && (
                      <div
                        className="w-100 text-center"
                        style={{ marginTop: "80px" }}
                      >
                        <a
                          href="#"
                          onClick={() => setShowModal(true)}
                          className="app-theme-btn"
                        >
                          {heroSection.cta}
                        </a>
                      </div>
                    )}
                  </div>
                )}
                {testimonialsSection && (
                  <Testimonials
                    testimonialsSection={{ ...testimonialsSection }}
                  />
                )}
                {footerSection && (
                  <Footer
                    footerSection={footerSection}
                    questionsAndAnswersSection={questionsAndAnswersSection}
                    ctaSection={ctaSection}
                    navigationSection={navigationSection}
                    topics={topics}
                    courses={courses}
                  />
                )}
              </>
            }
          />
        </Routes>
        <button className="scroll-top" onClick={scrollToTop}>
          <i className="fa fa-angle-up" aria-hidden="true" />
        </button>
        <Modal
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        >
          <div className="d-flex w-100 h-100 align-items-center">
            {calendlyURL ? (
              <InlineWidget
                url={calendlyURL}
                styles={{ width: "100%", height: "100%" }}
              />
            ) : (
              <div className="row m-0 w-100">
                {topics?.map((topic, index) => (
                  <div
                    key={`calendly-topics-option-${index}`}
                    className="col-12 col-md-6 my-2 px-2"
                  >
                    <button
                      className="app-theme-btn-bright w-100 py-4"
                      onClick={() => setCalendlyURL(topic.calendlyUrl || "")}
                    >
                      {topic.title}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal>
        <Modal
          isOpen={showSignOutModal}
          onClose={() => {
            setShowSignOutModal(false);
          }}
          customClass="confirmation-modal"
          hideCloseButton
          showBackground
        >
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <h4>
              {authenticationContent?.logoutModalSection
                ?.confirmLogoutMessage || "Are you sure you want to logout?"}
            </h4>
            <div className="d-flex w-100 pt-15 gap-10">
              <button
                className="btn btn-danger w-50"
                onClick={() => {
                  setShowSignOutModal(false);
                }}
              >
                {authenticationContent?.logoutModalSection?.abortLogoutCta ||
                  "No"}
              </button>
              <button
                className="btn btn-success w-50"
                onClick={async () => {
                  setShowSignOutModal(false);
                  await signUserOut();
                  navigate("/");
                }}
              >
                {authenticationContent?.logoutModalSection?.logoutCta || "Yes"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <AskUsWidget content={askUsWidget} />
    </>
  );
};

export default WrapperApp;
