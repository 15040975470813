import { FunctionComponent, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { getCourseLeads, markCourseLeadAsSeen } from "../../services/firebase";
import { contextNotificationAtom } from "../../state/atoms/notification";
import { AdminSection, CourseLead } from "../../types";
import Spinner from "../Spinner";
import SearchBar from "../common/SearchBar";

const CoursesLeads: FunctionComponent<{
  adminSection?: AdminSection;
}> = ({ adminSection }) => {
  const setNotification = useSetRecoilState(contextNotificationAtom);
  const [leads, setLeads] = useState<CourseLead[] | null>(null);
  const [isMarkingLeadAsSeen, setIsMarkingLeadAsSeen] = useState<string | null>(
    null
  );
  const [leadsToDisplay, setLeadsToDisplay] = useState(leads);

  useEffect(() => {
    getCourseLeads().then((leadsFromDb) => setLeads(leadsFromDb));
  }, []);

  if (!adminSection) return <></>;
  const { courseLeadsTableHeadingFields, noCourseLeadsInTheSystem } =
    adminSection;
  if (!courseLeadsTableHeadingFields) return <></>;

  const colSpans = [1, 4, 3, 2, 2];

  const onMarkLeadAsSeen = async (leadId: string) => {
    try {
      setIsMarkingLeadAsSeen(leadId);
      await markCourseLeadAsSeen(leadId);
      setNotification({
        message:
          adminSection.contactedLeadSuccessfully ||
          "Contacted lead successfully",
        type: "info",
        isVisible: true,
      });
    } catch (err) {
      setNotification({
        message: "Can't complete action",
        type: "error",
        isVisible: true,
      });
    } finally {
      setIsMarkingLeadAsSeen(null);
      const filteredLeads: CourseLead[] =
        leads?.filter((lead) => lead.leadId !== leadId) || [];
      setLeads(filteredLeads);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-20 mb-20">
        <h3 className="d-none d-lg-block text-start flex-grow-1">
          {adminSection.courseLeadsTab.title}
        </h3>
        <SearchBar
          data={leads}
          placeholder={adminSection.courseLeadsTab.searchPlaceholder}
          searchKey="email"
          disabled={!leads?.length}
          setFilteredData={setLeadsToDisplay}
        />
      </div>
      {leadsToDisplay ? (
        <table className="table dashboard-table mb-4">
          <thead>
            <tr className="d-none d-md-table-row">
              {courseLeadsTableHeadingFields.map((field, idx) => (
                <th
                  colSpan={colSpans[idx]}
                  scope="col"
                  key={`${field}-${idx}`}
                  className={`d-none d-xl-table-cell text-start`}
                >
                  {field}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {leadsToDisplay.map(
              ({ email, originPath, timestamp, leadId }, index) => {
                const formattedTime = new Date(
                  timestamp.seconds * 1000
                ).toLocaleDateString();
                const isLeadBeingMarkAsSeen = !!(
                  isMarkingLeadAsSeen && isMarkingLeadAsSeen === leadId
                );
                return (
                  <tr key={index}>
                    <td colSpan={colSpans[0]} className="text-start">
                      {index + 1}
                    </td>
                    <td
                      colSpan={colSpans[1]}
                      dir="ltr"
                      title={email}
                      className="text-truncate"
                    >
                      {email}
                    </td>
                    <td colSpan={colSpans[2]} title={originPath.substring(9)}>
                      {originPath.substring(9)}
                    </td>
                    <td colSpan={colSpans[3]} title={formattedTime}>
                      {formattedTime}
                    </td>
                    <td className="cursor-pointer" colSpan={colSpans[4]}>
                      <button
                        disabled={isLeadBeingMarkAsSeen}
                        onClick={() => {
                          onMarkLeadAsSeen(leadId);
                        }}
                        className="btn btn-sm w-25px h-30px p0"
                      >
                        {isLeadBeingMarkAsSeen ? (
                          <Spinner isVisible size="sm" />
                        ) : (
                          <i className="fa fa-solid fa-check fa-lg" />
                        )}
                      </button>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      ) : (
        <h3>
          {noCourseLeadsInTheSystem || "There are no leads in the system"}
        </h3>
      )}
    </>
  );
};

export default CoursesLeads;
