import { FunctionComponent } from "react";
import ReactModal from "react-modal";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  customClass?: string;
  hideCloseButton?: boolean;
  showBackground?: boolean;
}

const Modal: FunctionComponent<ModalProps> = ({
  isOpen,
  onClose,
  children,
  customClass,
  hideCloseButton,
  showBackground = false,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={`react-modal ${customClass} ${
        showBackground ? "bg-white" : ""
      }`}
      overlayClassName="react-modal-overlay"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={onClose}
    >
      {children}
      {!hideCloseButton && (
        <button
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            top: "16px",
            right: "16px",
            borderRadius: "50%",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
          }}
          className="p-2 btn-light"
          onClick={onClose}
        >
          <img src="/images/icon/close.svg" alt="close" />
        </button>
      )}
    </ReactModal>
  );
};

export default Modal;
