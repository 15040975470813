import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { signUserIn } from "../services/firebase";
import { contextSignInUserAtom } from "../state/atoms/user";
import { LoginSignupInputs, LoginSignupSection, UserMessages } from "../types";
import Loader from "./Loader";
import Notification from "./Notification";
import Spinner from "./Spinner";

const Login: FunctionComponent<{
  loginSection?: LoginSignupSection;
  loginSignupInputs?: LoginSignupInputs;
  userMessages?: UserMessages;
}> = ({ loginSection, loginSignupInputs, userMessages }) => {
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const signedInUser = useRecoilValue(contextSignInUserAtom);

  const navigate = useNavigate();

  useEffect(() => {
    if (signedInUser) navigate("/my-personal-area");
  }, [signedInUser, navigate]);

  if (!loginSection || !loginSignupInputs) return <Loader />;

  const {
    emailLabel,
    emailPlaceholder,
    passwordLabel,
    passwordPlaceholder,
    loginCta,
    signupCta,
  } = loginSignupInputs;

  const { formSection } = loginSection;

  if (!formSection) return <Loader />;

  const { isUserSigned, title, forgotPassword } = formSection;

  const handleSignIn = async (ev: FormEvent) => {
    ev.preventDefault();
    if (!signInEmail || !signInPassword) {
      setIsErrored(true);
      return;
    }

    try {
      setIsSubmitted(false);
      setIsSubmitting(true);

      const { user } = await signUserIn(signInEmail.trim(), signInPassword);
      if (!user) {
        setIsErrored(true);
        return;
      }
      const { uid, email } = user;
      if (!uid || !email) {
        setIsErrored(true);
        return;
      }
    } catch (err) {
      setIsErrored(true);
    } finally {
      setIsSubmitted(true);
      setIsSubmitting(false);
    }
  };
  return (
    <div className="user-data-page clearfix d-lg-flex text-start">
      <div className="illustration-wrapper d-flex align-items-center justify-content-around flex-column">
        <div className="illustration-holder">
          <img src="images/assets/ils_08.svg" alt="" className="illustration" />
          <img
            src="images/assets/ils_08.1.svg"
            alt=""
            className="shapes shape-one"
          />
          <img
            src="images/assets/ils_08.2.svg"
            alt=""
            className="shapes shape-two"
          />
        </div>
      </div>
      <div className="form-wrapper">
        <div className="d-flex justify-content-between"></div>
        <form
          action="#"
          className="user-data-form mt-30 md-mt-40"
          onSubmit={handleSignIn}
        >
          <h2>{title}</h2>
          <p className="header-info pt-30">
            {isUserSigned} <Link to="/signup">{signupCta}</Link>
          </p>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Spinner isVisible={isSubmitting} size="md" />
            </div>
            {isSubmitted && (
              <div className="col-12 mb-4">
                <Notification
                  isVisible={isSubmitted}
                  message={
                    !isErrored
                      ? userMessages?.successMessage
                      : userMessages?.errorMessage
                  }
                  type={!isErrored ? "info" : "error"}
                />
              </div>
            )}
            <div className="col-12">
              <div className="input-group-meta mb-80 sm-mb-70">
                <label>{emailLabel}</label>
                <input
                  type="email"
                  placeholder={emailPlaceholder}
                  autoComplete="email"
                  onChange={(ev) => setSignInEmail(ev.currentTarget.value)}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="input-group-meta mb-15">
                <label>{passwordLabel}</label>
                <input
                  type="password"
                  placeholder={passwordPlaceholder}
                  className="pass_log_id"
                  autoComplete="current-password"
                  onChange={(ev) => setSignInPassword(ev.currentTarget.value)}
                />
              </div>
              <p className="cursor-pointer">
                <Link to="/reset-password">{forgotPassword}</Link>
              </p>
            </div>
            <div className="col-12">
              <button className="app-theme-btn mt-50 mb-50">{loginCta}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;
