import { FunctionComponent, useState } from "react";
import { TypedObject } from "@portabletext/types";
import { PortableText } from "@portabletext/react";

const QuestionAndAnswer: FunctionComponent<{
  question?: string;
  answer?: TypedObject;
}> = ({ question, answer }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="card">
      <div className="card-header" id="headingOne1">
        <h5 className="mb-0">
          <button className="btn btn-link" onClick={() => setShow(!show)}>
            {question}
          </button>
        </h5>
      </div>
      <div id="collapseOne1" className={`collapse ${show ? "show" : ""}`}>
        <div className="card-body text-start">
          {answer && <PortableText value={answer} />}
        </div>
      </div>
    </div>
  );
};
export default QuestionAndAnswer;
