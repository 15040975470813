import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUsers } from "../../services/firebase";
import UserName from "../UserName";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { isFetchingDataAtom } from "../../state/atoms/ui";
import { contextSignInUserAtom } from "../../state/atoms/user";
import { AuthUser } from "../../types";
import { AdminSection } from "../../types";
import SearchBar from "../common/SearchBar";
import Chat from "./chat/Chat";

const TeacherChats: FunctionComponent<{
  adminSection?: AdminSection;
}> = ({ adminSection }) => {
  const navigate = useNavigate();
  const { teacherId: selectedTeacherId } = useParams();
  const setIsFetchingData = useSetRecoilState(isFetchingDataAtom);
  const [teachers, setTeachers] = useState<AuthUser[] | null>(null);
  const [studentIds, setStudentsIds] = useState<string[]>([]);
  const signedInUser = useRecoilValue(contextSignInUserAtom);
  const [teachersToDisplay, setTeachersToDisplay] = useState<AuthUser[] | null>(
    teachers
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsFetchingData(true);

      const usersFromDb = await getUsers({
        hideTestUsers: false,
      });
      const teachersFromDb =
        usersFromDb?.filter(
          (user) => user.isTeacher && user.uid !== signedInUser?.uid
        ) || [];
      setTeachers(teachersFromDb);

      const studentsFromDb =
        usersFromDb?.filter((user) => !user.isTeacher) || [];
      setStudentsIds(studentsFromDb.map((student) => student.uid));

      setIsFetchingData(false);
    };
    fetchData();
  }, [setIsFetchingData, signedInUser]);

  if (!adminSection) return <></>;

  return (
    <>
      {!selectedTeacherId ? (
        <div className="d-flex justify-content-between align-items-center mt-20 mb-20">
          <h3 className="d-none d-lg-block text-start flex-grow-1">
            {adminSection.chatsTab?.title || "Chats"}
          </h3>
          <SearchBar
            data={teachers}
            placeholder={
              adminSection.chatsTab?.searchPlaceholder ||
              "search teacher's chats"
            }
            searchKey="displayName"
            disabled={!teachers?.length}
            setFilteredData={setTeachersToDisplay}
          />
        </div>
      ) : (
        <div className="flex flex-column">
          <button
            onClick={(event) => {
              event.preventDefault();
              navigate(`/admin-panel/chats`);
            }}
            className="btn btn-primary mb-3"
          >
            {adminSection.backToChatsMainPageButton || "Back to chats"}
          </button>
          <div className="d-flex justify-content-start mb-3">
            <Chat userId={selectedTeacherId} contactIds={studentIds} />
          </div>
        </div>
      )}

      {!selectedTeacherId && teachersToDisplay && (
        <table className="table dashboard-table mb-4">
          <thead>
            <tr className="d-none d-md-table-row">
              {adminSection.chatsTableHeadingFields?.map((field, idx) => (
                <th className="text-end" key={`${field}-${idx}`}>
                  {field}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {teachersToDisplay.map(
              ({ uid, displayName, isTeacher, isTestUser, isAdmin }, index) => {
                return (
                  <tr
                    key={uid}
                    className="teacher-row"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(uid);
                    }}
                  >
                    <td className="text-end">{index + 1}</td>
                    <td
                      className={`text-start ${
                        isTestUser ? "opacity-03" : ""
                      } text-truncate`}
                    >
                      <UserName
                        isAdmin={isAdmin}
                        isTeacher={isTeacher}
                        displayName={displayName}
                      />
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TeacherChats;
