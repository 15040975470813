import { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  removeStudentFromGroup,
  updateGroup,
  updateUserDisplayName,
} from "../../../services/firebase";
import {
  contextGroupAtom,
  contextGroupsAtom,
} from "../../../state/atoms/group";
import { contextNotificationAtom } from "../../../state/atoms/notification";
import {
  contextStudentsAtom,
  paginatedFilteredStudentsAtom,
  studentsPerPageAtom,
  studentsToDisplayAtom,
} from "../../../state/atoms/student";
import { Group, TeacherSection } from "../../../types";
import Spinner from "../../Spinner";
import UserName from "../../UserName";
import PaginationComponent from "../PaginationComponent";

const Students: FunctionComponent<{
  teacherUid: string;
  teacherSection?: TeacherSection;
}> = ({ teacherUid, teacherSection }) => {
  const [group, setGroup] = useRecoilState(contextGroupAtom);
  const [groups, setGroups] = useRecoilState(contextGroupsAtom);
  const students = useRecoilValue(contextStudentsAtom);
  const setStudentsPerPage = useSetRecoilState(studentsPerPageAtom);
  const [studentsToDisplay, setStudentsToDisplay] = useRecoilState(
    studentsToDisplayAtom
  );
  const setPaginatedFilteredStudents = useSetRecoilState(
    paginatedFilteredStudentsAtom
  );
  const setNotification = useSetRecoilState(contextNotificationAtom);
  const [searchStudent, setSearchStudent] = useState<string | "">("");
  const [isSavingName, setIsSavingName] = useState(false);

  const [studentIdBeingAdded, setStudentIdBeingAdded] = useState<string | null>(
    null
  );
  const [studentToEdit, setStudentToEdit] = useState<{
    newName: string;
    uid: string;
  } | null>(null);

  useEffect(() => {
    setStudentsPerPage(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPaginatedFilteredStudents(students);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teacherUid, students]);

  useEffect(() => {
    if (searchStudent.length > 0) {
      const filteredStudents = students?.filter((student) =>
        student.displayName?.toLowerCase().includes(searchStudent.toLowerCase())
      );

      if (filteredStudents?.length)
        setPaginatedFilteredStudents(filteredStudents);
      else {
        setStudentsToDisplay(null);
        setPaginatedFilteredStudents(null);
      }
    } else setPaginatedFilteredStudents(students);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStudent, students]);

  const onToggleStudentInGroup = async ({
    isAdd,
    uid,
    email,
    displayName,
  }: {
    isAdd: boolean;
    uid: string;
    email: string;
    displayName: string;
  }) => {
    setStudentIdBeingAdded(uid);

    try {
      if (!group?.gid) return;
      const { addStudent, removeStudent } =
        teacherSection?.addRemoveMessages || {};
      const groupToUpdate: Group = { ...group };
      if (isAdd) {
        groupToUpdate.participants = [
          { uid, email, displayName },
          ...groupToUpdate.participants,
        ];
        await updateGroup({
          groupToUpdate,
          updatedProperty: "participants",
        });
      } else {
        groupToUpdate.participants = groupToUpdate.participants.filter(
          (participant) => participant.uid !== uid
        );
        await removeStudentFromGroup({
          studentId: uid,
          groupId: group?.gid,
        });
      }

      setGroup({ ...groupToUpdate });

      const newGroups = groups?.map((group) =>
        group.gid === groupToUpdate.gid ? { ...groupToUpdate } : group
      );
      if (!newGroups) return;
      setGroups(newGroups);

      setNotification({
        message: !isAdd
          ? removeStudent || "Removed student Successfully"
          : addStudent || "Added student Successfully",
        type: "info",
        isVisible: true,
      });
    } catch (err) {
      const { cantComplete } = teacherSection?.addRemoveMessages || {};
      setNotification({
        message: cantComplete || "Can't complete action",
        type: "error",
        isVisible: true,
      });
    } finally {
      setStudentIdBeingAdded(null);
    }
  };

  const onSaveStudentName = async () => {
    try {
      if (!studentsToDisplay || !studentToEdit) return;
      setIsSavingName(true);
      const updatedUser = await updateUserDisplayName(studentToEdit);
      setStudentsToDisplay(
        studentsToDisplay.map((user) =>
          user.uid === updatedUser.uid ? updatedUser : user
        )
      );
    } catch (err) {
      setNotification({
        message: "Can't complete action",
        type: "error",
        isVisible: true,
      });
    } finally {
      setStudentToEdit(null);
      setIsSavingName(false);
    }
  };

  const getStudentIndex = () => {
    if (studentsToDisplay) {
      const studentIdx = students?.findIndex(
        (student) => student.uid === studentsToDisplay[0].uid
      );
      return studentIdx || 0;
    }
    return 0;
  };
  if (!teacherSection) return <></>;
  const {
    studentTableTitle,
    studentSearchPlaceholder,
    tableHeadingFields,
    noStudentsInTheSystem,
    incognitoUser,
  } = teacherSection;

  const colSpans: number[] = [1, 4, 5, 2];

  return (
    <section className="teacher-dashboard-container">
      <div className="d-flex justify-content-between align-items-center mt-20 mb-20">
        <h3 className="d-none d-lg-block text-start flex-grow-1">
          {studentTableTitle}
        </h3>
        <div className="student-search-container d-inline-flex align-items-center position-relative w-50">
          <input
            className="student-search-input"
            type="text"
            onInput={(ev) => {
              setSearchStudent(ev.currentTarget.value);
            }}
            placeholder={studentSearchPlaceholder}
          />
          <i className="fa fa-search student-search-svg" />
        </div>
      </div>
      {students ? (
        <>
          <table className="table dashboard-table students">
            <thead>
              <tr>
                {tableHeadingFields.map((field, index) => (
                  <th
                    colSpan={colSpans[index]}
                    key={`${field}-${index}`}
                    className="text-start d-none d-xl-table-cell"
                  >
                    {field}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {studentsToDisplay?.map(
                ({ uid, displayName, email, promoCode, isTestUser }, index) => {
                  const isProcessing =
                    !!studentIdBeingAdded && studentIdBeingAdded !== uid;
                  const isBeingAdded =
                    studentIdBeingAdded && studentIdBeingAdded === uid;

                  const isEditing = studentToEdit?.uid === uid;
                  const isSaving = isEditing && isSavingName;
                  const icon = isEditing ? (
                    isSaving ? (
                      <Spinner isVisible size="sm" />
                    ) : (
                      "✔️"
                    )
                  ) : (
                    "✏️"
                  );

                  return (
                    <tr
                      key={uid}
                      className={`${
                        group?.participants.some(
                          (miniUser) => miniUser.uid === uid
                        )
                          ? "table-success"
                          : ""
                      } `}
                    >
                      <td colSpan={colSpans[0]}>
                        {getStudentIndex() + (index + 1)}
                      </td>
                      <td
                        colSpan={colSpans[1]}
                        className={`${
                          isTestUser ? "opacity-03" : ""
                        } text-truncate`}
                        title={displayName || incognitoUser}
                      >
                        <button
                          className="font-md d-inline me-2"
                          disabled={isSavingName}
                          onClick={() => {
                            if (!studentToEdit) {
                              setStudentToEdit({
                                uid: uid,
                                newName: displayName,
                              });
                            } else {
                              onSaveStudentName();
                            }
                          }}
                        >
                          {icon}
                        </button>
                        {studentToEdit?.uid === uid ? (
                          <input
                            type="text"
                            className="w-75"
                            value={studentToEdit.newName}
                            onChange={(ev) => {
                              setStudentToEdit({
                                uid: uid,
                                newName: ev.target.value,
                              });
                            }}
                          />
                        ) : (
                          <UserName
                            isAdmin={false}
                            isTeacher={false}
                            displayName={displayName || incognitoUser}
                          />
                        )}
                      </td>
                      <td
                        colSpan={colSpans[2]}
                        className="text-truncate"
                        dir="ltr"
                        title={email}
                      >
                        {email}
                      </td>
                      <td colSpan={colSpans[3]} className="text-start">
                        {promoCode ? (
                          promoCode
                        ) : (
                          <i className="fa fa-solid fa-minus fa-sm" />
                        )}
                      </td>
                      {false && (
                        <td className="text-start">
                          {group?.participants.some(
                            (participant) => participant.uid === uid
                          ) ? (
                            <button
                              disabled={isProcessing}
                              onClick={() =>
                                onToggleStudentInGroup({
                                  isAdd: false,
                                  uid,
                                  displayName,
                                  email,
                                })
                              }
                              className="btn d-flex flex-row justify-content-center w-50px m-auto"
                            >
                              {isBeingAdded ? (
                                <Spinner isVisible={true} size="sm" />
                              ) : (
                                <>
                                  <i className="fa fa-solid fa-users fa-lg mx-2" />
                                  <i className="fa fa-solid fa-minus fa-lg" />
                                </>
                              )}
                            </button>
                          ) : (
                            <button
                              disabled={isProcessing}
                              onClick={() =>
                                onToggleStudentInGroup({
                                  isAdd: true,
                                  uid,
                                  displayName,
                                  email,
                                })
                              }
                              className="btn d-flex flex-row justify-content-center w-50px m-auto"
                            >
                              {isBeingAdded ? (
                                <Spinner isVisible={true} size="sm" />
                              ) : (
                                <>
                                  <i className="fa fa-solid fa-users fa-lg mx-2" />
                                  <i className="fa fa-solid fa-plus fa-lg" />
                                </>
                              )}
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </>
      ) : (
        <div>
          {noStudentsInTheSystem || "There are no students in the system"}
        </div>
      )}
      <PaginationComponent />
    </section>
  );
};

export default Students;
