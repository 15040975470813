import { FunctionComponent, useState, useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  paginatedFilteredStudentsAtom,
  studentsPerPageAtom,
  studentsToDisplayAtom,
} from "../../state/atoms/student";
const PaginationComponent: FunctionComponent<{}> = () => {
  const studentsPerPage = useRecoilValue(studentsPerPageAtom);
  const paginatedFilteredStudents = useRecoilValue(
    paginatedFilteredStudentsAtom
  );
  const setStudentsToDisplay = useSetRecoilState(studentsToDisplayAtom);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const indexOfLastItem = currentPage * studentsPerPage;
    const indexOfFirstItem = indexOfLastItem - studentsPerPage;
    const currentItems = paginatedFilteredStudents?.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    if (currentItems?.length) setStudentsToDisplay(currentItems);
    else if (paginatedFilteredStudents?.length === 1)
      setStudentsToDisplay(paginatedFilteredStudents);
  }, [
    currentPage,
    studentsPerPage,
    setStudentsToDisplay,
    paginatedFilteredStudents,
  ]);

  const pageNumbers: number[] = useMemo(() => {
    const pages = [];
    if (paginatedFilteredStudents)
      for (
        let i = 1;
        i <= Math.ceil(paginatedFilteredStudents.length / studentsPerPage);
        i++
      ) {
        pages.push(i);
      }
    return pages;
  }, [paginatedFilteredStudents, studentsPerPage]);

  const onSetPage = (diff: number) => {
    const selectedPageIndex = currentPage + diff;
    if (selectedPageIndex < 1 || selectedPageIndex > pageNumbers.length) return;
    setCurrentPage(selectedPageIndex);
  };
  return (
    <div
      className="pagintaion-container d-flex gap-5"
      style={{ visibility: pageNumbers.length > 1 ? "visible" : "hidden" }}
    >
      <span className="cursor-pointer" onClick={() => onSetPage(-1)}>
        &#x3c;
      </span>
      <span className="d-flex flex-grow-1">
        {pageNumbers.map((pageNumber) => {
          return (
            <span
              className={`page-number cursor-pointer text-center flex-row-reverse flex-grow-1 ${
                currentPage === pageNumber
                  ? "selected font-weight-bold rounded"
                  : ""
              }`}
              key={`page-${pageNumber}`}
              onClick={() => {
                setCurrentPage(pageNumber);
              }}
            >
              {pageNumber}
            </span>
          );
        })}
      </span>
      <span className="cursor-pointer" onClick={() => onSetPage(1)}>
        &#x3e;
      </span>
    </div>
  );
};

export default PaginationComponent;
