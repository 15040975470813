export const scrollToTop = () => window.scrollTo({ top: 0 });

export const downloadFile = (fileLink: string, fileName: string) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = (event) => {
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = fileName || "file";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
  };
  xhr.open("GET", fileLink);
  xhr.send();
};
