/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getStudentContacts } from "../../services/firebase";
import { isFetchingDataAtom } from "../../state/atoms/ui";
import { AuthUser, ChatComponent } from "../../types";
import Chat from "./chat/Chat";

const StudentDashboard: FunctionComponent<{
  student: AuthUser;
  studentSection?: {
    teacherTableTitle?: string;
    tableHeadingFields?: string[];
    incognitoUser?: string;
    noTeachersMessage?: string;
  };
  chatComponent?: ChatComponent;
}> = ({ student, studentSection, chatComponent }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [contactIds, setContactIds] = useState<string[]>([]);
  const setIsFetchingData = useSetRecoilState(isFetchingDataAtom);

  const { participatingInGroups } = student;

  useEffect(() => {
    const fetchData = async () => {
      setIsFetchingData(true);

      const fetchedContactIds = await getStudentContacts(student.uid);

      if (fetchedContactIds) {
        setContactIds(fetchedContactIds.filter((id) => id !== student.uid));
      }

      setIsFetchingData(false);
    };

    fetchData();
  }, [participatingInGroups]);

  useEffect(() => {
    if (!pathname.startsWith("/my-personal-area/chat"))
      navigate("/my-personal-area/chat");
  }, [pathname]);

  if (!studentSection) return <></>;

  return (
    <Routes>
      <Route
        path="chat"
        element={
          <div className="mt-70 p-4 rounded shadow-lg mb-3">
            <Chat
              contactIds={contactIds}
              userId={student.uid}
              chatComponent={chatComponent}
            />
          </div>
        }
      />
      <Route
        path="chat/:uid"
        element={
          <div className="mt-70 p-4 rounded shadow-lg mb-3">
            <Chat
              contactIds={contactIds}
              userId={student.uid}
              chatComponent={chatComponent}
            />
          </div>
        }
      />
    </Routes>
  );
};

export default StudentDashboard;
