import { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  createGroup,
  getStudents,
  removeGroup,
  removeStudentFromGroup,
  updateGroup,
} from "../../../services/firebase";
import {
  contextGroupAtom,
  contextGroupsAtom,
} from "../../../state/atoms/group";
import { contextNotificationAtom } from "../../../state/atoms/notification";
import {
  contextStudentsAtom,
  paginatedFilteredStudentsAtom,
  studentsPerPageAtom,
  studentsToDisplayAtom,
} from "../../../state/atoms/student";
import { contextSignInUserAtom } from "../../../state/atoms/user";
import {
  AuthUser,
  Group,
  GroupParticipant,
  TeacherSection,
} from "../../../types";
import Modal from "../../Modal";
import Spinner from "../../Spinner";
import PaginationComponent from "../PaginationComponent";

const Groups: FunctionComponent<{
  teacherSection?: TeacherSection;
}> = ({ teacherSection }) => {
  const teacher = useRecoilValue(contextSignInUserAtom);
  const students = useRecoilValue(contextStudentsAtom);
  const setStudents = useSetRecoilState(contextStudentsAtom);
  const [groups, setGroups] = useRecoilState(contextGroupsAtom);
  const [group, setGroup] = useRecoilState(contextGroupAtom);
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditGroupName, setIsEditGroupName] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [searchStudent, setSearchStudent] = useState<string>("");
  const [newGroupName, setNewGroupName] = useState<string>(
    group?.groupName || ""
  );
  const [studentsToAdd, setStudentsToAdd] = useState<GroupParticipant[] | null>(
    null
  );

  useEffect(() => {
    setNewGroupName(group?.groupName || "");
  }, [group]);

  const [nonParticipatingStudents, setNonParticipatingStudents] = useState<
    AuthUser[] | null
  >(null);

  const setStudentsPerPage = useSetRecoilState(studentsPerPageAtom);
  const [studentsToDisplay, setStudentsToDisplay] = useRecoilState(
    studentsToDisplayAtom
  );
  const [paginatedFilteredStudents, setPaginatedFilteredStudents] =
    useRecoilState(paginatedFilteredStudentsAtom);
  const setNotification = useSetRecoilState(contextNotificationAtom);

  const [studentIdBeingAdded, setStudentIdBeingAdded] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (showAddStudentsModal) {
      setPaginatedFilteredStudents(nonParticipatingStudents);
      setStudentsToDisplay(nonParticipatingStudents);
    } else if (showCreateGroupModal) {
      setPaginatedFilteredStudents(students);
      setStudentsToDisplay(students);
    }
    setStudentsPerPage(6);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showAddStudentsModal,
    showCreateGroupModal,
    nonParticipatingStudents,
    students,
  ]);

  useEffect(() => {
    const fetchStudents = async () => {
      const studentsFromDb = await getStudents({
        hideTestUsers: teacher?.isAdmin,
      });
      setStudents(studentsFromDb);

      if (group?.gid) {
        const { gid } = group;
        let filteredNonGroupStudents = studentsFromDb?.filter((student) => {
          return !student.participatingInGroups?.includes(gid);
        });
        if (!filteredNonGroupStudents?.length) filteredNonGroupStudents = [];
        setNonParticipatingStudents(filteredNonGroupStudents);
        setPaginatedFilteredStudents(
          filteredNonGroupStudents?.length ? filteredNonGroupStudents : null
        );
        setStudentsToDisplay(
          filteredNonGroupStudents?.length ? filteredNonGroupStudents : null
        );
      }
    };

    fetchStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group?.participants, group]);

  useEffect(() => {
    if (searchStudent.length) {
      let newFilteredStudents = paginatedFilteredStudents?.filter((student) =>
        student.displayName?.toLowerCase().includes(searchStudent.toLowerCase())
      );
      if (newFilteredStudents?.length)
        setStudentsToDisplay(newFilteredStudents);
      else {
        setStudentsToDisplay(null);
      }
    } else {
      setStudentsToDisplay(paginatedFilteredStudents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStudent, group, paginatedFilteredStudents]);

  const onRemoveGroup = async (gid?: string) => {
    try {
      if (gid) {
        setIsSubmitting(true);
        await removeGroup({ gid });
        if (groups) {
          const lastGroupIdx = groups.findIndex((group) => gid === group.gid);
          const updatedGroups = groups.filter((group) => gid !== group.gid);
          setGroups(updatedGroups);
          setGroup(updatedGroups[!lastGroupIdx ? 0 : lastGroupIdx - 1]);
          setIsSubmitting(false);
          setShowDeleteGroupModal(false);
        }
      }
    } catch (err) {
      setIsSubmitting(false);
      setShowDeleteGroupModal(false);
      const errMsg =
        teacherSection?.teacherGroupsSection.deleteGroupModal
          .cantDeleteGroupErrorMessage;
      setShowDeleteGroupModal(false);
      setNotification({
        message: errMsg || "Can't complete action",
        type: "error",
        isVisible: true,
      });
    }
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const student = JSON.parse(ev.currentTarget.value);
    const { uid, email, displayName } = student;
    if (studentsToAdd?.length) {
      let newStudents = [...studentsToAdd, { uid, email, displayName }];
      if (!ev.currentTarget.checked)
        newStudents = newStudents.filter((student) => student.uid !== uid);
      setStudentsToAdd(newStudents);
    } else {
      setStudentsToAdd([{ uid, email, displayName }]);
    }
  };

  const onUpdateGroup = async (updatedProperty: string) => {
    try {
      if (!group) return;
      setIsSubmitting(true);
      const groupToUpdate: Group = { ...group };
      if (updatedProperty === "participants") {
        if (!studentsToAdd) return;

        groupToUpdate.participants = [
          ...studentsToAdd,
          ...groupToUpdate.participants,
        ];
        setStudentsToAdd(null);
      }
      if (updatedProperty === "groupName") {
        if (!newGroupName) {
          setIsSubmitting(false);
          return;
        }
        groupToUpdate.groupName = newGroupName;
        setNewGroupName("");
      }

      await updateGroup({
        groupToUpdate,
        updatedProperty,
      });
      setIsSubmitting(false);
      setGroup({ ...groupToUpdate });
      const newGroups = groups?.map((group) =>
        group.gid === groupToUpdate.gid ? { ...groupToUpdate } : group
      );
      setShowAddStudentsModal(false);
      if (!newGroups) return;
      setGroups(newGroups);
    } catch (err) {
      setIsSubmitting(false);
      setShowAddStudentsModal(false);
      setNotification({
        message:
          teacherSection?.teacherGroupsSection
            .cantAddStudentsToGroupErrorMessage || "Can't complete action",
        type: "error",
        isVisible: true,
      });
    }
  };

  const handleTitleEdit = (ev: React.FormEvent<HTMLInputElement>) => {
    if (!newGroupName && group) setNewGroupName(group?.groupName);
    setNewGroupName(ev.currentTarget.value);
  };

  const onRemoveStudentInGroup = async ({
    studentId,
  }: {
    studentId: string;
  }) => {
    setStudentIdBeingAdded(studentId);
    try {
      if (!group?.gid) return;

      const updatedGroup = await removeStudentFromGroup({
        studentId,
        groupId: group?.gid,
      });

      const newGroups = groups?.map((group) =>
        group.gid === updatedGroup.gid ? { ...updatedGroup } : group
      );
      if (!newGroups) return;
      setGroups(newGroups);
      setGroup(updatedGroup);
      setNotification({
        message:
          teacherSection?.addRemoveMessages.removeStudent || "Student added",
        type: "info",
        isVisible: true,
      });
    } catch (err) {
      setNotification({
        message:
          teacherSection?.addRemoveMessages.cantComplete ||
          "Can't complete action",
        type: "error",
        isVisible: true,
      });
    } finally {
      setStudentIdBeingAdded(null);
    }
  };

  const onCreateGroup = async () => {
    if (!newGroupName || !teacher) return;
    try {
      setIsSubmitting(true);
      const { displayName, email, uid } = teacher;
      const group: Group = {
        teacher: { uid, email, displayName },
        participants: studentsToAdd ? studentsToAdd : [],
        groupName: newGroupName,
      };
      const newGroup = (await createGroup({
        group,
      })) as Group;
      let newGroups: Group[];

      const studentsFromDb = await getStudents({
        hideTestUsers: teacher.isAdmin,
      });
      setStudents(studentsFromDb);

      if (groups) {
        setPaginatedFilteredStudents(studentsFromDb);

        newGroups = [newGroup, ...groups];
        setGroups(newGroups);
        setGroup({ ...newGroup });
        setNewGroupName("");
        setStudentsToAdd(null);
        setShowCreateGroupModal(false);
        setIsSubmitting(false);
      }
    } catch (err) {
      setNewGroupName("");
      setStudentsToAdd(null);
      setShowCreateGroupModal(false);
      setIsSubmitting(false);
      setNotification({
        message:
          teacherSection?.newGroupFields.cantCreateGroupErrorMessage ||
          "Can't complete action",
        type: "error",
        isVisible: true,
      });
    }
  };

  if (!teacherSection?.teacherGroupsSection) return <> </>;

  const {
    teacherGroupsSection: {
      newGroupNamePlaceholder,
      editGroupTitleCta,
      doneEditGroupTitleCta,
      removeGroupCta,
      addStudentsToGroupCta,
      noStudentsInGroupMessage,
      addStudentsToGroupModal: {
        addStudentsCta,
        addStudentsHeader,
        pickStudentsToAdd,
        studentSearchPlaceholder,
      },
      deleteGroupModal: {
        confirmDeleteMessage,
        abortDeleteGroupCta,
        deleteGroupCta,
      },
    },
    newGroupFields: {
      addStudentsToGroupHeading,
      groupName,
      groupNamePlaceholder,
      noGroupNameErrorMessage,
      createNewGroupCta,
    },
    tableHeadingFields,
  } = teacherSection;

  return (
    <section className="teacher-dashboard-groups-container">
      <>
        <div className="d-flex flex-wrap justify-content-between align-items-center text-start gap-5 mt-20 mb-20">
          <button
            className="btn btn-success d-block"
            type="button"
            onClick={() => {
              setShowCreateGroupModal(true);
            }}
          >
            {createNewGroupCta}
          </button>

          {group && (
            <div className="d-flex flex-wrap gap-10">
              {isEditGroupName ? (
                <div className="d-flex gap-10">
                  <input
                    className="text-start w-100"
                    type="text"
                    value={newGroupName}
                    placeholder={newGroupNamePlaceholder}
                    onInput={(ev) => handleTitleEdit(ev)}
                  />
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      onUpdateGroup("groupName");
                      setIsEditGroupName(false);
                    }}
                  >
                    {doneEditGroupTitleCta}
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-primary text-nowrap"
                  onClick={() => {
                    setIsEditGroupName(true);
                  }}
                >
                  {editGroupTitleCta}
                </button>
              )}
              <button
                className="btn btn-danger d-flex align-self-start text-nowrap"
                type="button"
                onClick={() => {
                  setShowDeleteGroupModal(true);
                }}
              >
                {removeGroupCta}
              </button>
              <button
                type="button"
                className="btn btn-success text-nowrap"
                onClick={() => {
                  setShowAddStudentsModal(true);
                }}
              >
                {addStudentsToGroupCta}
              </button>
            </div>
          )}
        </div>

        {group && (
          <table className="table dashboard-table">
            <tbody>
              <tr className="d-none d-md-table-row">
                {tableHeadingFields &&
                  tableHeadingFields.map(
                    (field, index) =>
                      index !== 3 && (
                        <th
                          scope="col"
                          key={`${field}-${index}`}
                          className={`d-none d-xl-table-cell ${
                            index < 2 ? "text-start" : "text-center"
                          }`}
                        >
                          {field}
                        </th>
                      )
                  )}
              </tr>
              {group.participants[0] ? (
                group.participants.map(({ uid, displayName, email }, index) => {
                  const isProcessing =
                    !!studentIdBeingAdded && studentIdBeingAdded !== uid;
                  const isBeingAdded =
                    studentIdBeingAdded && studentIdBeingAdded === uid;
                  return (
                    <tr key={`${uid}-${index}`}>
                      <td>{index + 1}</td>
                      <td className="text-truncate" title={displayName}>
                        {displayName}
                      </td>
                      <td className="text-truncate" dir="ltr" title={email}>
                        {email}
                      </td>
                      <td className="text-center">
                        <button
                          disabled={isProcessing}
                          onClick={() =>
                            onRemoveStudentInGroup({
                              studentId: uid,
                            })
                          }
                          className="btn btn-sm"
                        >
                          {isBeingAdded ? (
                            <Spinner isVisible={true} size="sm" />
                          ) : (
                            <>
                              <i className="fa fa-solid fa-users fa-lg mx-2" />
                              <i className="fa fa-solid fa-minus fa-lg" />
                            </>
                          )}
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>{noStudentsInGroupMessage}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </>

      <Modal
        isOpen={showDeleteGroupModal}
        onClose={() => {
          setShowDeleteGroupModal(false);
        }}
        customClass="confirmation-modal"
        hideCloseButton
      >
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
          <h4>{confirmDeleteMessage}</h4>
          <div className="d-flex w-100 pt-15 gap-10">
            <button
              className="btn btn-danger w-50"
              onClick={() => {
                setShowDeleteGroupModal(false);
              }}
            >
              {abortDeleteGroupCta}
            </button>
            <button
              className="btn btn-success w-50"
              onClick={() => {
                onRemoveGroup(group?.gid);
              }}
            >
              {isSubmitting ? (
                <Spinner size="md" isVisible={isSubmitting} />
              ) : (
                deleteGroupCta
              )}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showCreateGroupModal || showAddStudentsModal}
        onClose={() => {
          showCreateGroupModal
            ? setShowCreateGroupModal(false)
            : setShowAddStudentsModal(false);
          setStudentsToAdd(null);
          setNewGroupName("");
        }}
        customClass="teacher-dashboard-modal p-3"
        hideCloseButton
      >
        <div className="create-group-container d-flex align-items-start flex-column text-start w-100">
          {showCreateGroupModal ? (
            <h4>{addStudentsToGroupHeading}</h4>
          ) : (
            <h4>{addStudentsHeader}</h4>
          )}

          {showCreateGroupModal && (
            <>
              <label className="mt-20 w-100 text-dark d-flex gap-10">
                {groupName}
                <input
                  className="group-name-input"
                  type="text"
                  placeholder={groupNamePlaceholder}
                  value={newGroupName}
                  onInput={(ev) => {
                    setNewGroupName(ev.currentTarget.value);
                  }}
                />
              </label>
              <p
                className="text-danger text-small"
                style={{
                  visibility: newGroupName ? "hidden" : "visible",
                }}
              >
                {noGroupNameErrorMessage}
              </p>
            </>
          )}

          <div className="w-100 mt-20">
            <p className="text-dark">{pickStudentsToAdd}</p>
            <div className="text-start w-100 rounded border border-secondary px-1 pt-2">
              <div className="student-search-container modal d-inline-flex align-items-center position-relative">
                <input
                  className="student-search-input"
                  type="text"
                  onInput={(ev) => {
                    setSearchStudent(ev.currentTarget.value);
                  }}
                  placeholder={studentSearchPlaceholder}
                />
                <i className="fa fa-search student-search-svg" />
              </div>
              <div className="student-pick-container">
                {studentsToDisplay?.map((student, index) => {
                  return (
                    <label
                      key={`${student.uid}-${index}`}
                      className="d-flex justify-content-between px-2 h-30px"
                    >
                      <span>{student.displayName}</span>
                      <input
                        type="checkbox"
                        checked={
                          studentsToAdd?.find(
                            (newStudent) => newStudent.uid === student.uid
                          )
                            ? true
                            : false
                        }
                        onChange={(ev) => handleChange(ev)}
                        value={JSON.stringify(student)}
                      />
                    </label>
                  );
                })}
              </div>
              <PaginationComponent />
            </div>
          </div>
          {!isSubmitting ? (
            <button
              className={`btn btn-success align-self-end ${
                showAddStudentsModal ? "mt-20 w-100" : "mt-1"
              }`}
              type="button"
              onClick={() => {
                showCreateGroupModal
                  ? onCreateGroup()
                  : onUpdateGroup("participants");
              }}
            >
              {showCreateGroupModal ? createNewGroupCta : addStudentsCta}
            </button>
          ) : (
            <div className="d-flex justify-content-center w-100 mt-5">
              <Spinner size="md" isVisible={isSubmitting} />
            </div>
          )}
        </div>
      </Modal>
    </section>
  );
};

export default Groups;
