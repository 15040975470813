/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { urlForSanityImage } from "../services/sanity";
import { isRtlAtom } from "../state/atoms/global";
import { showModalAtom } from "../state/atoms/ui";
import {
  Course,
  CTASection,
  FooterSection,
  NavigationSection,
  QuestionsAndAnswersSection,
  Topic,
} from "../types";
import QuestionAndAnswer from "./QuestionAndAnswer";

const Footer: FunctionComponent<{
  footerSection: FooterSection;
  questionsAndAnswersSection?: QuestionsAndAnswersSection;
  ctaSection?: CTASection;
  navigationSection?: NavigationSection;
  topics?: Topic[];
  courses?: Course[];
}> = ({
  footerSection,
  questionsAndAnswersSection,
  ctaSection,
  navigationSection,
  topics,
  courses,
}) => {
  const isRtl = useRecoilValue(isRtlAtom);
  const setShowModal = useSetRecoilState(showModalAtom);

  return (
    <>
      {questionsAndAnswersSection &&
        questionsAndAnswersSection.questionsAndAnswers?.length && (
          <div className="faq-section-four">
            <img
              src="/images/shape/120.svg"
              alt=""
              className="shapes shape-one"
            />
            <img
              src="/images/shape/121.svg"
              alt=""
              className="shapes shape-two"
            />
            <div className="container">
              <div className="title-style-five text-center mb-80 md-mb-60">
                <h2>
                  <span>{questionsAndAnswersSection.title}</span>
                </h2>
              </div>
              <div className="row">
                <div className="col-xl-9 col-lg-10 m-auto">
                  <div
                    id="accordionThree"
                    className={`accordion-style-four ${isRtl ? "rtl" : ""}`}
                  >
                    {questionsAndAnswersSection.questionsAndAnswers?.map(
                      ({ question, answer }, index) => (
                        <QuestionAndAnswer
                          key={`faq-question-${index}`}
                          question={question}
                          answer={answer}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      {ctaSection && (
        <div className="fancy-short-banner-five pt-150 pb-150 md-pt-100 md-pb-100">
          <div className="container">
            <div className="title-style-five text-center">
              <h2>
                <span>{ctaSection.title}</span>
              </h2>
            </div>
            <div className="row">
              <div className="col-xl-7 col-lg-8 col-md-9 m-auto">
                <p
                  className={`${
                    isRtl ? "font-varela-round" : "font-rubik"
                  } sub-heading`}
                >
                  {ctaSection.description}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center m-auto">
                <a
                  href="#"
                  onClick={() => setShowModal(true)}
                  className="app-theme-btn"
                >
                  {ctaSection.cta}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <footer className="theme-footer-four text-start">
        <div className="top-footer">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-md-4 col-12 footer-about-widget">
                {footerSection.logo && (
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={urlForSanityImage(footerSection.logo)
                          .width(150)
                          .url()}
                        alt=""
                      />
                    </Link>
                  </div>
                )}
                {footerSection.contacts && (
                  <ul
                    className={`mt-10 ${
                      isRtl
                        ? "font-varela-round text-right"
                        : "font-rubik text-left"
                    }`}
                    dir="ltr"
                  >
                    <li>
                      <a href={`mailto:${footerSection.contacts.email}`}>
                        {footerSection.contacts.email}
                      </a>
                    </li>
                    {footerSection.contacts.phones?.map((phone, index) => (
                      <li key={`footer-contacts-phones-${index}`}>
                        <a href={`tel:${phone}`}>{phone}</a>
                      </li>
                    ))}
                    <div className="mt-4">
                      {footerSection.contacts.socialMedia?.map(
                        ({ icon, link, name }) => {
                          if (!name || !link) return <></>;
                          return (
                            <li
                              className={`mb-2 d-flex gap-5 align-items-center ${
                                isRtl
                                  ? "justify-content-end"
                                  : "justify-content-start"
                              } gap-5`}
                            >
                              <a
                                className={`mb-0 lh-1 ${
                                  isRtl ? "" : "order-1"
                                }`}
                                href={link}
                              >
                                {name}
                              </a>
                              {icon && (
                                <img
                                  src={urlForSanityImage(icon)
                                    .format("png")
                                    .width(32)
                                    .url()}
                                  alt=""
                                />
                              )}
                            </li>
                          );
                        }
                      )}
                    </div>
                  </ul>
                )}
              </div>
              <div className="col-md-4 col-12 footer-list mt-30">
                <h5 className="footer-title">{navigationSection?.topics}</h5>
                <ul>
                  {topics?.map(
                    ({ title, url }, index) =>
                      url &&
                      title && (
                        <li key={`footer-topics-${index}`}>
                          <Link to={url}>{title}</Link>
                        </li>
                      )
                  )}
                </ul>
              </div>
              <div className="col-md-4 col-12 footer-list mt-30">
                <h5 className="footer-title">{navigationSection?.courses}</h5>
                <ul>
                  {courses?.map(
                    ({ title, url }, index) =>
                      url &&
                      title && (
                        <li key={`footer-courses-${index}`}>
                          <Link to={url}>{title}</Link>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Link to={"/join-the-team"}>
            <button className="app-secondary-btn">
              {footerSection.joinUsCTA || "Join the team"}
            </button>
          </Link>
          <div className="bottom-footer-content mt-100">
            <p>Copyright @2022 Unschool</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
