import { FunctionComponent, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  getUsers,
  toggleTeacher,
  toggleTestUser,
  toggleStudentEnrollmentInCourse,
} from "../../services/firebase";
import { contextNotificationAtom } from "../../state/atoms/notification";
import { AdminSection, AuthUser } from "../../types";
import Spinner from "../Spinner";
import UserName from "../UserName";
import SearchBar from "../common/SearchBar";

// TODO: Read course IDs from the CMS
const DEFAULT_COURSE_ID = "masterminds";

const AdminUsersPanel: FunctionComponent<{
  adminSection?: AdminSection;
}> = ({ adminSection }) => {
  const [users, setUsers] = useState<AuthUser[] | null>(null);
  const setNotification = useSetRecoilState(contextNotificationAtom);

  const [teacherIdBeingAdded, setTeacherIdBeingAdded] = useState<string | null>(
    null
  );
  const [testUserIdBeingAdded, setTestUserIdBeingAdded] = useState<
    string | null
  >(null);
  const [userIdEnrollingToCourse, setUserIdEnrollingToCourse] = useState<
    string | null
  >(null);

  const [usersToDisplay, setUsersToDisplay] = useState(users);

  useEffect(() => {
    getUsers({
      hideTestUsers: false,
    }).then((usersFromDb) => setUsers(usersFromDb));
  }, []);

  if (!adminSection) return <></>;
  const { incognitoUser, tableHeadingFields, noUsersInTheSystem } =
    adminSection;

  const onToggleTeacher = async ({
    isAdd,
    userId,
  }: {
    isAdd: boolean;
    userId: string;
  }) => {
    setTeacherIdBeingAdded(userId);
    try {
      const updatedUser = (await toggleTeacher({
        isAdd,
        userId,
      })) as AuthUser;

      const users = await getUsers({
        hideTestUsers: false,
      });
      setUsers(users);

      const { addTeacher, removeTeacher } =
        adminSection?.addRemoveMessages || {};
      setNotification({
        message: !updatedUser.isTeacher
          ? removeTeacher || "Removed teacher successfully"
          : addTeacher || "Added teacher successfully",
        type: "info",
        isVisible: true,
      });
    } catch (err) {
      const { cantComplete } = adminSection?.addRemoveMessages || {};
      setNotification({
        message: cantComplete || "Can't complete action",
        type: "error",
        isVisible: true,
      });
    } finally {
      setTeacherIdBeingAdded(null);
    }
  };

  const onToggleTestUser = async ({
    isAdd,
    userId,
  }: {
    isAdd: boolean;
    userId: string;
  }) => {
    setTestUserIdBeingAdded(userId);
    try {
      const updatedUser = (await toggleTestUser({
        isAdd,
        userId,
      })) as AuthUser;

      const newUsers = users?.map((user) =>
        user.uid === updatedUser.uid ? updatedUser : user
      );
      if (newUsers?.length) setUsers(newUsers);
      const { addTestUser, removeTestUser } =
        adminSection?.addRemoveMessages || {};
      setNotification({
        message: updatedUser.isTestUser
          ? addTestUser || "Added test user successfully"
          : removeTestUser || "Removed test user successfully",
        type: "info",
        isVisible: true,
      });
    } catch (err) {
      const { cantComplete } = adminSection?.addRemoveMessages || {};
      setNotification({
        message: cantComplete || "Can't complete action",
        type: "error",
        isVisible: true,
      });
    } finally {
      setTestUserIdBeingAdded(null);
    }
  };

  const onToggleCourseEnrollment = async ({
    isAdd,
    userId,
    courseId,
  }: {
    isAdd: boolean;
    userId: string;
    courseId: string;
  }) => {
    setUserIdEnrollingToCourse(userId);
    try {
      await toggleStudentEnrollmentInCourse({
        isAdd,
        userId,
        courseId: courseId,
      });

      const users = await getUsers({
        hideTestUsers: false,
      });
      setUsers(users);

      const { enrollToCourse, removeFromCourse } =
        adminSection?.addRemoveMessages || {};
      setNotification({
        message: isAdd
          ? enrollToCourse || `Enrolled to ${courseId} successfully`
          : removeFromCourse || `Removed from ${courseId} successfully`,
        type: "info",
        isVisible: true,
      });
    } catch (err) {
      const { cantComplete } = adminSection?.addRemoveMessages || {};
      setNotification({
        message: cantComplete || "Can't complete action",
        type: "error",
        isVisible: true,
      });
    } finally {
      setUserIdEnrollingToCourse(null);
    }
  };

  const colSpans = [1, 3, 4, 1, 1, 2];

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-20 mb-20">
        <h3 className="d-none d-lg-block text-start flex-grow-1">
          {adminSection.usersTab.title}
        </h3>
        <SearchBar
          data={users}
          placeholder={adminSection.usersTab.searchPlaceholder}
          searchKey="displayName"
          disabled={!users?.length}
          setFilteredData={setUsersToDisplay}
        />
      </div>
      {usersToDisplay ? (
        <table className="table dashboard-table mb-4">
          <thead>
            <tr className="d-none d-md-table-row">
              {tableHeadingFields.map((field, idx) => (
                <th
                  colSpan={colSpans[idx]}
                  scope="col"
                  key={`${field}-${idx}`}
                  className={`d-none d-xl-table-cell text-start`}
                >
                  {field}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {usersToDisplay.map(
              (
                {
                  uid,
                  displayName,
                  email,
                  isTeacher,
                  isTestUser,
                  isAdmin,
                  courses,
                },
                index
              ) => {
                const isProcessing = !!(
                  teacherIdBeingAdded ||
                  testUserIdBeingAdded ||
                  userIdEnrollingToCourse
                );
                const isBeingAddedAsTeacher = !!(
                  teacherIdBeingAdded && teacherIdBeingAdded === uid
                );
                const isBeingAddedAsTestUser = !!(
                  testUserIdBeingAdded && testUserIdBeingAdded === uid
                );
                const isEnrollingToCourse = !!(
                  userIdEnrollingToCourse && userIdEnrollingToCourse === uid
                );

                return (
                  <tr
                    key={uid}
                    className={`${isTeacher ? "table-success" : ""}`}
                  >
                    <td colSpan={colSpans[0]} className="text-start">
                      {index + 1}
                    </td>
                    <td
                      colSpan={colSpans[1]}
                      className={`text-start ${
                        isTestUser ? "opacity-03" : ""
                      } text-truncate`}
                      title={displayName}
                    >
                      <UserName
                        isAdmin={isAdmin}
                        isTeacher={isTeacher}
                        displayName={displayName || incognitoUser}
                      />
                    </td>
                    <td
                      colSpan={colSpans[2]}
                      dir="ltr"
                      title={email}
                      className="text-truncate"
                    >
                      {email}
                    </td>
                    <td colSpan={colSpans[3]}>
                      {isTeacher ? (
                        <button
                          disabled={isProcessing}
                          onClick={() =>
                            onToggleTeacher({
                              isAdd: false,
                              userId: uid,
                            })
                          }
                          className="btn btn-sm w-25px h-30px p0"
                        >
                          {isBeingAddedAsTeacher ? (
                            <Spinner isVisible size="sm" />
                          ) : (
                            <i className="fa fa-solid fa-minus fa-lg" />
                          )}
                        </button>
                      ) : (
                        <button
                          disabled={isProcessing}
                          onClick={() =>
                            onToggleTeacher({
                              isAdd: true,
                              userId: uid,
                            })
                          }
                          className="btn btn-sm w-25px h-30px p0"
                        >
                          {isBeingAddedAsTeacher ? (
                            <Spinner isVisible size="sm" />
                          ) : (
                            <i className="fa fa-solid fa-plus fa-lg" />
                          )}
                        </button>
                      )}
                    </td>
                    <td colSpan={colSpans[4]}>
                      {isTestUser ? (
                        <button
                          disabled={isProcessing}
                          onClick={() =>
                            onToggleTestUser({
                              isAdd: false,
                              userId: uid,
                            })
                          }
                          className="btn btn-sm w-25px h-30px p0"
                        >
                          {isBeingAddedAsTestUser ? (
                            <Spinner isVisible size="sm" />
                          ) : (
                            <i className="fa fa-solid fa-minus fa-lg" />
                          )}
                        </button>
                      ) : (
                        <button
                          disabled={isProcessing}
                          onClick={() =>
                            onToggleTestUser({
                              isAdd: true,
                              userId: uid,
                            })
                          }
                          className="btn btn-sm  w-25px h-30px p0"
                        >
                          {isBeingAddedAsTestUser ? (
                            <Spinner isVisible size="sm" />
                          ) : (
                            <i className="fa fa-solid fa-plus fa-lg" />
                          )}
                        </button>
                      )}
                    </td>
                    <td colSpan={colSpans[5]}>
                      {courses?.some((title) => title === DEFAULT_COURSE_ID) ? (
                        <button
                          disabled={isProcessing}
                          onClick={() =>
                            onToggleCourseEnrollment({
                              isAdd: false,
                              userId: uid,
                              courseId: DEFAULT_COURSE_ID,
                            })
                          }
                          className="btn btn-sm w-25px h-30px p0"
                        >
                          {userIdEnrollingToCourse ? (
                            <Spinner isVisible size="sm" />
                          ) : (
                            <i className="fa fa-solid fa-minus fa-lg" />
                          )}
                        </button>
                      ) : (
                        <button
                          disabled={isProcessing}
                          onClick={() =>
                            onToggleCourseEnrollment({
                              isAdd: true,
                              userId: uid,
                              courseId: DEFAULT_COURSE_ID,
                            })
                          }
                          className="btn btn-sm  w-25px h-30px p0"
                        >
                          {isEnrollingToCourse ? (
                            <Spinner isVisible size="sm" />
                          ) : (
                            <i className="fa fa-solid fa-plus fa-lg" />
                          )}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      ) : (
        <h3>{noUsersInTheSystem || "There are no users in the system"}</h3>
      )}
    </>
  );
};

export default AdminUsersPanel;
