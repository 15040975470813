import { FunctionComponent, useState, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { uploadFile } from "../../../services/firebase";
import {
  attachedFileNameAtom,
  attachedFilesAtom,
} from "../../../state/atoms/chat";
import { isUploadingFileAtom } from "../../../state/atoms/global";
import { contextSignInUserAtom } from "../../../state/atoms/user";
import { ChatTextEditorComponent, MessageType } from "../../../types";

const TextEditor: FunctionComponent<{
  reciever: string;
  addMsg: (
    msg: string,
    msgType: MessageType,
    attachedFile?: {
      fileName: string;
      fileLink: string;
    }[]
  ) => void;
  textEditorFields?: ChatTextEditorComponent;
}> = ({ addMsg, textEditorFields, reciever }) => {
  const [newMsg, setNewMsg] = useState<string>("");
  const [attachedFileName, setAttachedFileName] =
    useRecoilState(attachedFileNameAtom);
  const [attachedFiles, setAttachedFiles] = useRecoilState(attachedFilesAtom);
  const [currentMessageType, setCurrentMessageType] = useState<MessageType>(
    MessageType.PrivateMessage
  );
  const setIsUploadingFile = useSetRecoilState(isUploadingFileAtom);
  const user = useRecoilValue(contextSignInUserAtom);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    if (!newMsg && !attachedFiles.length) return;

    try {
      if (attachedFiles) {
        if (!user?.uid) return;
        setIsUploadingFile(true);
        const data = attachedFiles.map((file) => {
          return {
            senderId: user?.uid,
            recieverId: reciever,
            file,
          };
        });
        const downLoadFiles = await uploadFile(data);
        addMsg(newMsg, currentMessageType, downLoadFiles);
        setNewMsg("");
        setAttachedFiles([]);
        setAttachedFileName(null);
        setIsUploadingFile(false);
        if (fileInputRef.current) fileInputRef.current.value = "";
        return;
      }

      addMsg(newMsg.trim(), currentMessageType);
      setNewMsg("");
    } catch (err) {
      setIsUploadingFile(false);
    }
  };

  return (
    <div className="mt-2 border-top border-muted">
      <form className="d-flex gap-10 py-2" onSubmit={onSubmit}>
        <textarea
          className="w-100 border-0"
          placeholder={
            textEditorFields?.messagePlaceholder || "Type a message..."
          }
          style={{ maxHeight: "36px", resize: "none" }}
          onChange={(e) => setNewMsg(e.currentTarget.value)}
          value={newMsg}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (e.shiftKey) {
                const start = e.target.selectionStart;
                const end = e.target.selectionEnd;
                await setNewMsg(
                  (prevState) =>
                    prevState.substring(0, start) +
                    "\n" +
                    prevState.substring(end)
                );
                e.target.selectionStart = e.target.selectionEnd = start + 1;
                e.target.blur();
                e.target.focus();
              } else onSubmit();
            }
          }}
        />
        <button className="w-75px h-40 btn btn-light px-1" type="submit">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            enableBackground="new 0 0 16 16"
          >
            <path
              d="M0,9l4,1.5L6,16l2.861-3.82L14,14l2-14L0,9z M7.169,11.44l-0.916,2.485l-1.086-3.118l8.402-7.631L7.169,11.44z"
              fill="currentColor"
            />
          </svg>
        </button>
        <label
          className="w-75px h-40 btn btn-light px-1 cursor-pointer"
          title={
            attachedFileName ||
            textEditorFields?.chooseFile ||
            "Choose a file..."
          }
        >
          <input
            type="file"
            multiple={true}
            className="upload-input d-none"
            ref={fileInputRef}
            onChange={(e) => {
              if (e.target.files) {
                const files = Array.from(e.target.files);
                setAttachedFileName(
                  files[files.length - 1].name ||
                    textEditorFields?.cantUploadFileTypeMessage ||
                    "Cannot upload file..."
                );
                setAttachedFiles(files);
              }
            }}
          />
          <span className="text-nowrap">
            {attachedFileName ? (
              attachedFileName.slice(0, 6) + "..."
            ) : (
              <svg
                width="16"
                height="16"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.89534 10.644L9.62701 2.9123C10.4158 2.12348 11.4857 1.68033 12.6013 1.68033C13.7168 1.68033 14.7867 2.12348 15.5755 2.9123C16.3643 3.70111 16.8075 4.77098 16.8075 5.88654C16.8075 7.00209 16.3643 8.07196 15.5755 8.86077L6.65278 17.7818C6.3355 18.0882 5.91055 18.2578 5.46947 18.254C5.02839 18.2501 4.60645 18.0732 4.29455 17.7613C3.98264 17.4494 3.80572 17.0275 3.80189 16.5864C3.79806 16.1453 3.96762 15.7204 4.27406 15.4031L13.1951 6.48037C13.3483 6.32173 13.4331 6.10926 13.4312 5.88872C13.4293 5.66818 13.3408 5.45721 13.1848 5.30126C13.0289 5.14531 12.8179 5.05685 12.5974 5.05493C12.3768 5.05301 12.1644 5.1378 12.0057 5.29101L3.0847 14.2154C2.76335 14.5258 2.50703 14.897 2.3307 15.3075C2.15437 15.718 2.06156 16.1595 2.05768 16.6062C2.05379 17.053 2.13892 17.496 2.30809 17.9095C2.47726 18.323 2.72709 18.6986 3.04299 19.0146C3.3589 19.3305 3.73455 19.5803 4.14804 19.7495C4.56153 19.9186 5.00457 20.0038 5.45131 19.9999C5.89805 19.996 6.33954 19.9032 6.75002 19.7268C7.16051 19.5505 7.53177 19.2942 7.84213 18.9729L16.7649 10.0518C17.8691 8.94756 18.4895 7.44987 18.4895 5.88822C18.4895 4.32657 17.8691 2.82887 16.7649 1.72462C15.6606 0.620364 14.1629 0 12.6013 0C11.0396 0 9.54191 0.620364 8.43765 1.72462L0.704297 9.45461C0.62396 9.5322 0.559881 9.62502 0.515798 9.72764C0.471715 9.83026 0.448512 9.94063 0.447542 10.0523C0.446571 10.164 0.467853 10.2748 0.510146 10.3781C0.552438 10.4815 0.614895 10.5754 0.693871 10.6544C0.772847 10.7334 0.866761 10.7958 0.970133 10.8381C1.07351 10.8804 1.18426 10.9017 1.29595 10.9007C1.40763 10.8998 1.51801 10.8766 1.62063 10.8325C1.72325 10.7884 1.81606 10.7243 1.89366 10.644H1.89534Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </span>
        </label>
        {user?.isTeacher && (
          <select
            className="text-start cursor-pointer btn btn-light"
            onChange={(ev) => {
              setCurrentMessageType(ev.target.value as MessageType);
            }}
          >
            <option
              className="dropdown-item"
              value={MessageType.PrivateMessage}
            >
              {textEditorFields?.messageTypePicker?.privateMessage ||
                "Private message"}
            </option>

            <option className="dropdown-item" value={MessageType.HomeWork}>
              {textEditorFields?.messageTypePicker?.homework || "Homework"}
            </option>

            <option className="dropdown-item" value={MessageType.Balance}>
              {textEditorFields?.messageTypePicker?.balance || "Balance"}
            </option>

            <option className="dropdown-item" value={MessageType.info}>
              {textEditorFields?.messageTypePicker?.info || "Info"}
            </option>
          </select>
        )}
      </form>
    </div>
  );
};

export default TextEditor;
