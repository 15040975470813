import { atom } from "recoil";
import { Group } from "../../types";

export const contextGroupAtom = atom<Group | null>({
  key: "contextGroupAtom",
  default: null,
});

export const contextGroupsAtom = atom<Group[] | null>({
  key: "contextGroupsAtom",
  default: null,
});
