import { FunctionComponent, ReactElement } from "react";

const DynamicBubblesContainer: FunctionComponent<{
  children: ReactElement;
}> = ({ children }) => {
  return (
    <div className="container-xl pt-50">
      <div className="fancy-hero-one pb-0">
        <div className="bubble-one" />
        <div className="bubble-two" />
        <div className="bubble-three" />
        <div className="bubble-four" />
        <div className="bubble-five" />
        <div className="bubble-six" />
        <div className="container">{children}</div>
      </div>
    </div>
  );
};

export default DynamicBubblesContainer;
