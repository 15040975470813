import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { unreadMessagesAtom } from "../../state/atoms/global";
import { DashboardNavbarTypes } from "../../types";
import UnreadMessageNotification from "./chat/dynamic-message/UnreadMessageNotification";

const DashboardNavbar: FunctionComponent<{
  dashboardData: {
    sectionName: string;
    img: React.SVGProps<SVGSVGElement>;
    path: string;
  }[];
  navbarType: DashboardNavbarTypes;
}> = ({ dashboardData, navbarType }) => {
  const unreadMessages = useRecoilValue(unreadMessagesAtom);

  return (
    <nav className="dashboard-navbar d-flex text-start mb-20">
      {dashboardData.map(({ img, sectionName, path }, idx) => {
        return (
          <NavLink
            to={path}
            key={idx}
            className="p-2 d-flex align-items-center justify-content-center flex-grow-1 cursor-pointer no-hover-color"
          >
            <>
              {img}
              <span className="dashboard-navbar-section flex-grow-1">
                {sectionName}
              </span>
              {idx === 2 &&
                navbarType === DashboardNavbarTypes.teacherDashboard && (
                  <UnreadMessageNotification
                    isShow={
                      !!unreadMessages.find(
                        ({ numberOfUnreadMessages }) =>
                          numberOfUnreadMessages > 0
                      )
                    }
                  />
                )}
            </>
          </NavLink>
        );
      })}
    </nav>
  );
};

export default DashboardNavbar;
